/**
 * 多来源转单
 * Created by liyaoh on 2018/5/22.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import EllipsisToTooltip from './ellipsisToTooltip';
import CreateMultiTransferTable from './CreateMultiTransferTable';
import { isTimeType, getLangCode, changeTime, formatAcuracy, commafy, getSysFieldid, ViewModel, sum, linkTo, formatNumber, formatDatetime } from '@platform/api'
import { Table as NCTable } from '@platform/base'
import {
    thounsandTransfer,
    formatDot,
    linkage,
    sortColumn,
    updateTransferDataAfterSort,
    updateColumnAfterSetting,
    getExpandTableHeight,
    checkboxSwitchValue,
    getcolsSettingParam,
} from './methods';

import { lib } from '@platform/table-core';
import classnames from 'classnames';
require('./index.less');

const { total, hoverContent } = lib;

const ComplexTable = hoverContent(total(NCTable.SortTable));

const defaultColor = ['#111111', '#555555', '#111', '#555'];

const hasObjProperty = function (obj) {
    return !!(obj && Object.values(obj).length);
};

//创建转单 params:单来源转单参数
export function createMultiTransferTable({
    showAll = false,
    allHeadId, // 全部页签的转单主表ID
    allBodyId, // 全部页签的转单子表ID
    allFullTableId, // 全部页签的转单主子拉平ID
    dataSource, // 缓存ID
    headPkIds, // 点击生成按钮的时候需要保存的所有页签主表IDs 集合
    bodyPkIds, // 点击生成按钮的时候需要保存的所有页签子表IDs 集合
    containerSelector, // 转单的容器元素，用来计算转单的宽
    componentInitFinished, //componentInitFinished 转单初始化完后的回调函数
    transferBtnText, // 生成下游转单的按钮的title
    showSeletedBtn = true, //转单显示查看已选按钮
    showGenerateBtn = true, //显示 生成下游转单的按钮
    onTransferBtnClick, // 点击生成按钮的回调函数
    onCheckedChange, // 切换复选框选中状态的回调函数
    onSelectedBtnClick, //查看已选列表按钮的点击回调函数
    onChangeViewClick, // 切换视图的回调函数
    showChangeViewBtn = true, //已选列表页面 切换主子表/拉平表 的按钮显示控制 Boolean
    showAllList, // 点击全部列表的回调函数
    onTabClick, // 页签切换的回调函数
    selectArea, // 底部灰条自定义区域
    selectedHeaderRender, // 已选列表头部自定义区域
    onClearAll, // 全消回调函数
    onSelectedItemRemove, //已选列表清除回调
    tabActiveIndex, // 设置显示params中定义的第几个tab （默认为'0'，params参数第一个参数的下标）类型Number / String 类型
    hideTabs, // 设置隐藏params中定义的哪几个tab （默认为全不隐藏） 类型Arry 如： ['0', '2'] 则隐藏params中第1个和第三个tab
    totalKey = [], // 自定义总计字段
    totalTitle = [], // 自定义总计名
    isDrag = false, // 自定义总计名
    sortType = {
        mode: 'single', //'multiple'(多列排序) 或'single'(单列排序)(如果前端排序的时候想多列排序，对象里就穿这个字段就可以了),
        backSource: false, //false是前端排序，值为true为后端排序,默认false
        sortFun: (sortInfoObj, sortData) => { //sortInfoObj:{field:是模板的dataIndex,order:"ascend"升序/"descend"降序} , sortData:[]排序后的数据
            updateTransferDataAfterSort(sortData, allHeadId, this, sortInfoObj);
        },
    },
    cancelCustomRightMenu = false,
    onExpandedChange, //主子表点击加号的回调 function
    customSortRule, //自定义排序规则
    fieldid,
    currentPageId,
    isShowBodyAmount = true,
    autoHeight = true,
    bodyExpandAllInRow = false,
},
    params, //Array 多个页签的转单参数 具体参数可以参考create.js里createTransferTable的参数
) {
    if (dataSource) {
        this.transferTableDataSource = dataSource;
    }
    if (!this.state.transferTable.hasOwnProperty('allSelected')) {
        this.state.transferTable['allSelected'] = {
            activeTab: tabActiveIndex || '0', //当前高亮tab
            selectedShow: false, //显示已选列表
            masterAmount: 0, //主表已选数量
            bodyAmount: 0, //子表已选数量
            masterCheckedId: [], //存放已选主表id，用于复选框半选
            selIds: {}, //存放其他页签的主子表id
            showFullTable: false, //已选列表中是否显示主子拉平
            isEllipsis: true,
            bodyExpandAllInRow, // 折行全部展开
            selectedData: {
                selAll: false,
                masterSelData: {},
                childSelData: {},
            },
        };
        if (tabActiveIndex) {
            onTabClick && onTabClick(tabActiveIndex);
        }
    }

    this.state.transferTable['allSelected']['params'] = Array.isArray(params) ?
        params.map(item => ({ bodyTableId: item.bodyTableId, headTableId: item.headTableId, fullTableId: item.fullTableId, fieldId: item.fieldId }))
        : [];

    const _this = this;
    let bodyColumns = allBodyId && this.state.meta[allBodyId] ? this.state.meta[allBodyId].items.map((val, index) => {
        sortColumn(val, sortType, customSortRule);
        // 清理required 属性  不然会导致表头错行
        if (val && val.required) {
            delete val.required;
        }
        return val;
    }) : [];

    let headColumns = allHeadId && this.state.meta[allHeadId] ? this.state.meta[allHeadId].items.map((val, index) => {
        sortColumn(val, sortType, customSortRule);
        // 清理required 属性  不然会导致表头错行
        if (val && val.required) {
            delete val.required;
        }
        return val;
    }) : [];

    let fullColumns = allFullTableId && this.state.meta[allFullTableId] ? this.state.meta[allFullTableId].items.map((val, index) => {
        sortColumn(val, sortType, customSortRule);
        // 清理required 属性  不然会导致表头错行
        if (val && val.required) {
            delete val.required;
        }
        return val;
    }) : []; //主子拉平模板

    let { transferTable } = this.state;

    let traversalStatus = this.myState.status.traversalStatus; // 是否遍历模板的状态

    // 根据表单类型从模板中判断表格是否是有合计的表格

    //判断合计行是否显示
    const traversalColumns = columns => {
        for (let items of columns) {
            if (items.istotal) {
                this.myState.status.totalStatusOfTransferTable = true;
                this.myState.status.traversalStatus = true;
            } else if (items.data) {
                for (let item of items.data) {
                    if (item.istotal) {
                        this.myState.status.totalStatusOfTransferTable = true;
                        this.myState.status.traversalStatus = true;
                    }
                }
            }
            if (this.myState.status.traversalStatus) {
                break;
            }
        }
        traversalColumn.call(this, columns[0].data, 'head');
        traversalColumn.call(this, columns[1].data, 'full');
        traversalColumn.call(this, columns[2].data, 'body');
    };

    //设置主表、子表、拉平表的合计行是否显示
    const traversalColumn = (column, key) => {
        if (column) {
            for (let items of column) {
                if (items.istotal) {
                    this.myState.status[`${key}TotalStatusOfTransferTable`] = true;
                    this.myState.status[`${key}traversalStatus`] = true;
                }
            }
        }
    };

    //查看是否遍历了合计状态
    if (!traversalStatus) {
        let columnsArr = [
            { data: headColumns, title: 'columns' },
            { data: fullColumns, title: 'columns' },
            {
                data: bodyColumns,
                title: 'columns',
            },
        ];
        traversalColumns.bind(this, columnsArr)();
    }
    this.onTabClick = onTabClick;
    this.onClearAll = onClearAll;
    this.onSelectedItemRemove = onSelectedItemRemove; //已选列表清除回调
    // 设置默认隐藏后的剩余params
    let cutParams = [];
    let paramsLength = params.length;
    if (hideTabs) {
        for (let i = 0; i < paramsLength; i++) {
            if (hideTabs.includes(i)) {
                cutParams.push(i);
            }
        }
    } else {
        cutParams = params;
    }

    const switchValue = (value, json) => {
        if (value) {
            return (json && json['page-transferTable-0020']) || '是';
        } else {
            return (json && json['page-transferTable-0021']) || '否';
        }
    };

    // 处理模板
    const createNewCol = (column, json, columnType) => {
        let result = [];
        let pageId = currentPageId || (this.state && this.state.meta.pageid);
        if (!this.state.meta[allHeadId] && !column) {
            // return false 会导致报错
            return [];
        }
        let columnData = column || this.state.meta[allHeadId].items;
        columnData.map(item => {
            let width = item.width;
            if (!width) {
                width = 120;
            }
            let render = null;
            if (item.itemtype !== 'customer' && item.visible && !item.render) {
                render = (text, record, index) => {
                    if (record[item.attrcode]) {
                        let display = record[item.attrcode].display;
                        let value = record[item.attrcode].value;
                        let scale = record[item.attrcode].scale;
                        let dom = '';
                        if (display || display === 0) {
                            dom = display;
                        } else {
                            dom = value;
                        }

                        if (isTimeType(item.itemtype)) {
                            if (item.itemtype == 'datetimepicker') {
                                dom = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                                dom = formatDatetime(dom, 'datetime');
                            } else if (item.itemtype == 'timepicker') {
                                dom = value;
                                dom = formatDatetime(dom, 'time');
                            } else {
                                dom = changeTime(value);
                                dom = formatDatetime(dom, 'date');
                            }
                        }

                        // 多语处理
                        if (item.itemtype === "residtxt") {
                            let langCode = getLangCode();
                            let loginLang = (item.languageMeta || []).filter(i => i.languageCode == langCode);
                            if (loginLang.length > 0) {
                                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                                if (
                                    record[item.attrcode + loginLang[0].index] &&
                                    record[item.attrcode + loginLang[0].index].value
                                ) {
                                    dom = record[item.attrcode + loginLang[0].index].value;
                                }
                            }
                        }

                        if (item.itemtype == 'switch') {
                            dom = switchValue(value, json);
                        } else if (item.itemtype == 'checkbox_switch') {
                            dom = checkboxSwitchValue(value, false, json);
                        }
                        if (item.itemtype == 'number') {
                            let recordScale = scale || (item.scale ? item.scale : '-1');
                            dom = formatDot(dom, recordScale);
                            dom = formatAcuracy(dom, recordScale);
                            dom = formatNumber(dom);
                        }
                        return (
                            <span className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : ''}`}>{
                                dom ? (
                                    <EllipsisToTooltip overlay={dom}>
                                        {item.hyperlinkflag && dom ? (
                                            <a
                                                href="javascript:;"
                                                className="hyper-link"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    let moduleId = columnType === 'child' ? allBodyId : columnType === 'full' ? allFullTableId : allHeadId;
                                                    linkTo(pageId, moduleId, item.attrcode, value || dom);
                                                }}
                                            >
                                                {dom}
                                            </a>
                                        ) : (<span>{dom}&nbsp;</span>)}
                                    </EllipsisToTooltip>
                                ) : <span>{dom}&nbsp;</span>}
                            </span>
                        );

                    }
                };
            } else {
                if (item.itemtype === 'customer') {
                    render = item.render;
                } else {
                    render = (text, record, index) => {
                        return record[item.attrcode] ? (
                            <EllipsisToTooltip
                                className="tooltip-word-color"
                                overlay={item.render.call(
                                    null,
                                    text,
                                    record,
                                    index,
                                )}
                                noEllipsis
                            >
                                <div className={classnames(
                                    'col-customer-render-unit',
                                    {
                                        'single-line-and-ellipsis': this.state.transferTable['allSelected']['isEllipsis'],
                                        'three-line-and-ellipsis': !this.state.transferTable['allSelected']['isEllipsis'],
                                        'mult-line-and-ellipsis': !this.state.transferTable['allSelected']['isEllipsis'] && this.state.transferTable['allSelected']['bodyExpandAllInRow'],
                                    })
                                }>
                                    {item.render.call(null, text, record, index)}
                                </div>
                            </EllipsisToTooltip>
                        ) : record[item.attrcode];
                    };
                }
            }

            if (item.visible) {
                result.push({
                    ...item,
                    width,
                    render,
                    key: item.attrcode,
                    dataIndex: item.dataIndex || item.attrcode,
                    title: <span
                        className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : 'text-td-span-wrapper'}  ${item.className || ''}`}
                        style={{ color: defaultColor.includes(item.color) ? '' : item.color }}
                    > {item.label}
                    </span>,
                });
            }
        });
        console.log('子表模板数据', result);
        return result;
    };

    // 保留合计的最长小数位精度 nowVal: 当前要加的值， totalVal： 之前合计好的值
    const getTheLongestPrecision = (nowVal, totalVal) => {
        if (nowVal === '' || nowVal === null || nowVal === 'null') {
            return totalVal;
        }
        let nowValArr = String(nowVal).split('.');
        let totalValArr = String(totalVal).split('.');
        let nowValLen = nowValArr.length >= 2 ? String(nowValArr[1]).length : 0;
        let totalValLen = totalValArr.length >= 2 ? String(totalValArr[1]).length : 0;
        // let addVal = Number.parseFloat(nowVal) + Number.parseFloat(totalVal);
        nowVal = thounsandTransfer(nowVal, 'number');
        totalVal = thounsandTransfer(totalVal, 'number');
        let addVal = accAdd(Number.parseFloat(nowVal), Number.parseFloat(Number(totalVal)));
        let addValArr = String(addVal).split('.');
        let addValLen = addValArr.length >= 2 ? String(addValArr[1]).length : 0;
        if (totalValLen >= nowValLen) {
            let zeroO = '';
            for (let i = 0; i < totalValLen - addValLen; i++) {
                zeroO += '0';
            }
            let bool = String(addVal).includes('.') ? `${addVal}${zeroO}` : `${addVal}.${zeroO}`;
            return Number(bool).toFixed(totalValLen);
        } else {
            let zeroO = '';
            for (let i = 0; i < nowValLen - addValLen; i++) {
                zeroO += '0';
            }
            let bool = String(addVal).includes('.') ? `${addVal}${zeroO}` : `${addVal}.${zeroO}`;
            return Number(bool).toFixed(nowValLen);
        }
    };

    /*子表添加复选框列和序号
     *
     * @param {Array} columns 渲染子表的每一列的属性数据 [{},{},...]
     * @param {*} records
     * @param {*} indexs
     * @param {*} comType
     * @param {*} json
     */
    const renderColumnsMultiSelectChild = (columns, records, indexs, json) => {
        let indexCol = {
            label: json && json['page-transferTable-0007'] || '序号-8',
            itemtype: 'customer',
            attrcode: 'numberindex',
            dataIndex: 'numberindex',
            visible: true,
            width: 60,
            className: 'col-customer-numberindex',
            render: (text, record2, index) => {
                return <span>{index + 1}</span>;
            },
        }; // 添加序号列

        let oprColumn = {
            label: <span>{json['page-transferTable-0006'] || '操作-6'}</span>,
            attrcode: 'operate',
            itemtype: 'customer',
            dataIndex: 'operate',
            width: 100,
            fixed: 'right',
            visible: true,
            className: 'col-customer-operate',
            render: (text, record, index) => {
                return (
                    <div
                        className="row-remove"
                        onClick={
                            clearSelectedData.bind(this, 'body', records.key, record.key, record, true)
                        }
                    >
                        {json && json['page-transferTable-0001'] || '移除-1'}
                    </div>
                );
            },
        };

        return [indexCol, ...columns, oprColumn];
    };

    //统计已选列表数量
    const getSelectedAmount = () => {
        let allSelected = this.state.transferTable.allSelected; //多来源转单全部页签
        //多来源有全部时统计全部页签的勾选重量
        if (allSelected) {
            let bodys = 0;
            Object.values(allSelected.selIds).forEach(item => {
                // 有个数据没有子数据  但是应该也算一行数据
                bodys += (item.length || 1);
            });
            let masterAmount = Object.keys(allSelected.selIds).length || (allSelected.masterCheckedId && allSelected.masterCheckedId.length);
            let bodyAmount = bodys;
            allSelected.masterAmount = masterAmount;
            allSelected.bodyAmount = bodyAmount;
            return { masterAmount, bodyAmount };
        }
    };

    let selectedAmount = getSelectedAmount(); //已选数据数量

    //选择主/子表时记录主表id key:转单id headId:主表id flag:选中/取消选中
    const setMasterId = (key, headId, flag) => {
        let { masterCheckedId, selectedData } = transferTable[key].selected;
        if (!masterCheckedId.includes(headId) && flag) {
            masterCheckedId.push(headId);
        } else if (masterCheckedId.indexOf(headId) !== -1 && !selectedData.selAll) {
            masterCheckedId.splice(masterCheckedId.indexOf(headId), 1);
        }
    };
    /*
    *  清除已选列表对应主/子表数据
    * type： String （nest/body）判断移除的是主表还是子表或者主子拉平的参数
    * headId： String 主表ID
    * bodyId： String 子表ID
    * record： Object 移除的当前行的数据
    * isFull： Boolean 是否是主子拉平
    *
    * */
    const clearSelectedData = (type, headId, bodyId, record, isFull) => {
        let delBodyData;
        for (let key of Object.keys(transferTable)) {
            let selectedData =
                key !== 'allSelected' ? transferTable[key].selected.selectedData : transferTable[key].selectedData;
            let masterSelData = selectedData.masterSelData;
            let childSelData = selectedData.childSelData;
            selectedData.selAll = false;
            if (type === 'head') {
                if (key === 'allSelected') {
                    delBodyData = childSelData[headId] && childSelData[headId]; //记录被删除的子表数据
                }
                delete masterSelData[headId];
                delete childSelData[headId];
                delete transferTable.allSelected.selIds[headId]; //删除全部已选中对应主表id
                delete transferTable.allSelected.selectedData.masterSelData[headId];
            } else if (type === 'body') {
                if (childSelData[headId]) {
                    let childSelDataKeys = Object.keys(childSelData[headId]);
                    if (childSelDataKeys.length) {
                        for (let val of childSelDataKeys) {
                            let selHeadId = transferTable.allSelected.selIds[headId];
                            if (selHeadId && val === bodyId && selHeadId.includes(val)) {
                                //删除全部已选中对应子表id
                                selHeadId.splice(selHeadId.indexOf(val), 1);
                            }
                            if (val === bodyId) {
                                //删除相应页签子表id
                                delete childSelData[headId][val];
                            }
                            // 没有子集就清理父级
                            if (Object.keys(childSelData[headId] || {}).length === 0) {
                                delete childSelData[headId];
                                delete masterSelData[headId];
                                delete transferTable.allSelected.selIds[headId];
                                delete transferTable.allSelected.selectedData.masterSelData[headId];
                                delete transferTable.allSelected.selectedData.childSelData[headId];
                            }
                        }
                    }
                    if (!Object.keys(childSelData[headId] || {}).length && masterSelData[headId]) {
                        // 如果没有子集  这里父子级都需要清理下
                        delete childSelData[headId];
                        delete masterSelData[headId];
                        delete transferTable.allSelected.selIds[headId];
                        delete transferTable.allSelected.selectedData.masterSelData[headId];
                        delete transferTable.allSelected.selectedData.childSelData[headId];
                    }
                }
            }
            if (key !== 'allSelected') setMasterId(key, headId, false);
        }

        // allSelected setMasterId 同步下 避免 统计数据时出现错误
        transferTable.allSelected.masterCheckedId = Object.keys(transferTable.allSelected.selIds || {});

        this.setState(
            { transferTable },
            () => {
                let bodySelData = delBodyData
                    ? Object.values(delBodyData).map(item => item.data.values || item.data)
                    : [];
                onSelectedItemRemove && onSelectedItemRemove(record, isFull ? [] : bodySelData);
            },
        );
    };

    //清空全部选中数据
    const clearAllSelectedData = () => {
        transferTable.allSelected.selectedData.masterSelData = {};
        transferTable.allSelected.selectedData.childSelData = {};
        transferTable.allSelected.selIds = {};
        transferTable.allSelected.masterAmount = 0;
        transferTable.allSelected.bodyAmount = 0;
        transferTable.allSelected.masterCheckedId = [];
        for (let key of Object.keys(transferTable)) {
            if (key !== 'allSelected') {
                transferTable[key].selected.masterCheckedId = []; //清空已选主表id
                transferTable[key].selected.selectedData.selAll = false; //取消全选
                transferTable[key].selected.selectedData.masterSelData = {};
                transferTable[key].selected.selectedData.childSelData = {};
            }
        }
        this.setState(
            { transferTable },
            () => {
                onClearAll && onClearAll();
            },
        );
    };

    /*
    * 设置已选列表columns
    * columns： Array  已选列表界面表格模板
    * json： Object 多语资源
    * */
    const setSelectedColumns = (columns = [], json) => {
        // 添加序号列
        let indexCol = {
            label: <span>{json['page-transferTable-0007'] || '序号-9'}</span>,
            title: <span>{json['page-transferTable-0007'] || '序号-9'}</span>,
            attrcode: 'numberindex',
            itemtype: 'customer',
            dataIndex: 'numberindex',
            visible: true,
            width: 55,
            className: 'col-customer-selected-numberindex',
            render: (text, record2, index) => {
                return <span>{index + 1}</span>;
            },
        };
        let operateColumn = {};
        if (transferTable.allSelected.showFullTable) {
            operateColumn = {
                title: <span bbq="已选拉平移除">{json['page-transferTable-0006'] || '操作-7'}</span>,
                attrcode: 'operate',
                itemtype: 'customer',
                dataIndex: 'operate',
                width: 81,
                visible: true,
                fixed: 'right',
                className: 'col-customer-operate',
                render: (text, record, index) => {
                    return (
                        <div
                            className="row-remove"
                            onClick={
                                !transferTable.allSelected.showFullTable ? (
                                    clearSelectedData.bind(this, 'head', record.key, '', record, false)
                                ) : (
                                    clearSelectedData.bind(this, 'body', record.headKey, record.key, record, true)
                                )
                            }
                        >
                            {json && json['page-transferTable-0001'] || '移除-6'}
                        </div>
                    );
                },
            };
        } else {
            operateColumn = {
                title: <span bbq="已选非拉平移除">{json['page-transferTable-0006'] || '操作-9'}</span>,
                attrcode: 'operate',
                itemtype: 'customer',
                dataIndex: 'operate',
                // fixed: 'right',
                width: 81,
                visible: true,
                className: 'col-customer-operate',
                render: (text, record, index) => {
                    return (
                        <div
                            className="row-remove"
                            onClick={
                                !transferTable.allSelected.showFullTable ? (
                                    clearSelectedData.bind(this, 'head', record.key, '', record, false)
                                ) : (
                                    clearSelectedData.bind(this, 'body', record.headKey, record.key, record)
                                )
                            }
                        >
                            {json && json['page-transferTable-0001'] || '移除-3'}
                        </div>
                    );
                },
            };
        }
        return [indexCol, ...columns, operateColumn];
    };

    //  处理数据
    const createNewData = data => {
        let datas = [];
        data.map((val, index) => {
            val = val.values || val;
            datas.push(val);
        });
        return datas;
    };

    /*
    * 将当前项arg1和之前的合计项arg2相加，并将结果进行精度千分位处理
    * arg1： String/Number 当前项
    * arg2： String/Number 之前的合计项
    * */
    const accAdd = (arg1, arg2) => {
        var r1,
            r2,
            m;
        try {
            r1 = arg1.toString().split('.')[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split('.')[1].length;
        } catch (e) {
            r2 = 0;
        }
        m = Math.pow(10, Math.max(r1, r2));
        return (arg1 * m + arg2 * m) / m;
    };

    /*
  * 处理合计数据
  * selected: Object 计算合计选中的数据
  * tableType：String 判断主子表结构、主子拉平结构或者单表结构 （nest/full/simple）
  * column：Array 合计行的模板数据
  * showFullTable：Boolean 判断是表示是否为拉平显示
  * */
    const processingTotalData = (selected, tableType, column, showFullTable, record) => {
        let { _this } = this.props;
        let headkey = '';
        let outerData = {};
        let totalKey = [];
        let totalScale = {};

        for (let item of column) {
            outerData[item['attrcode']] = { value: '', display: '' };
            if (item.istotal && item.itemtype === 'number') {
                totalKey.push(item.attrcode);
                totalScale[item.attrcode] = item.scale;
            }
        }
        if (tableType === 'body') {
            headkey = record.headKey || record.key;
            let bodyData = selected.childSelData[headkey];
            if (bodyData) {
                for (let item of Object.keys(bodyData)) {
                    for (let i of totalKey) {
                        if (
                            bodyData[item].data.values
                                ? bodyData[item].data.values.hasOwnProperty(i)
                                : bodyData[item].data.hasOwnProperty(i)
                        ) {
                            let values = bodyData[item].data.values || bodyData[item].data;
                            if (values[i] && typeof values[i] === 'object') {
                                let scale = values[i].scale ? values[i].scale : totalScale[i];
                                let value = values[i] ? values[i].value : 0;
                                if (!outerData[i].value) {
                                    outerData[i].scale = scale;
                                    outerData[i].value = getScaleData(value, scale);
                                } else {
                                    let totalData = sum(Number.parseFloat(value), Number.parseFloat(outerData[i].value.replace(/,/g, '')));
                                    outerData[i].scale = scale > outerData[i].scale ? scale : outerData[i].scale;
                                    outerData[i].value = getScaleData(String(totalData), outerData[i].scale);
                                }
                            }

                        }
                    }
                }
            }

        }
        return [outerData];
    };

    /*
    * 根据精度获取数据
    * data：String/Number 要获取精度的具体值
    * scal：String/Number 精度
    * */
    const getScaleData = (data, scal) => {
        let value = String(data).replace(/,/g, '');
        let scale = Number(scal);
        if (value) {
            let len = scale >= 0 ? value.split('.')[0].length + scale + 1 : value.split('.')[0].length;
            if (value.indexOf('.') !== -1) {
                let arr = value.split('.');
                if (!Number.isNaN(scale) && scale !== -1) {
                    if (scale === 0) {
                        value = String(value).split('.')[0];
                    } else {
                        value = String(value).padEnd(len, '0');
                    }
                }
            } else {
                if (!Number.isNaN(scale) && scale !== -1) {
                    if (scale === 0) {
                        value = String(value);
                    } else {
                        let str = value + '.';
                        value = str.padEnd(Number(len), '0');
                    }
                }
            }
        } else {
            return 0;
        }
        return commafy(value);
    };

    /*
    * 为已选列表的模板添加操作列
    * column：Array 需要处理的已选列表表格的模板
    * showIndex：Object 是否要为模板添加序号列
    * json：Object 多语资源
    * */
    const createSelectColumns = (column, showIndex, json) => {
        let result = [];
        result.push({
            label: json['page-transferTable-0007'] || '序号-6',
            attrcode: 'numberindex',
            itemtype: 'customer',
            dataIndex: 'numberindex',
            visible: true,
            width: 60, // 这里要和序号列同宽
            className: 'col-customer-numberindex',
            render: (text, record2, index) => {
                return <span title={json['page-transferTable-0010'] || '合计-7'}>{json['page-transferTable-0010'] || '合计-7'}</span>;
            },
        });
        column.map(item => {
            let width = item.width;
            if (!width) {
                width = 120;
            }
            let render = null;
            if (item.itemtype !== 'customer' && item.visible && !item.render) {
                render = (text, record, index) => {
                    if (record[item.attrcode]) {
                        let display = record[item.attrcode].display;
                        let value = record[item.attrcode].value;
                        let dom = '';
                        if (display || display === 0) {
                            dom = display;
                        } else {
                            dom = value;
                        }
                        if (isTimeType(item.itemtype)) {
                            if (item.itemtype == 'datetimepicker') {
                                dom = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                                dom = formatDatetime(dom, 'datetime');
                            } else if (item.itemtype == 'timepicker') {
                                dom = value;
                                dom = formatDatetime(dom, 'time');
                            } else {
                                dom = changeTime(value);
                                dom = formatDatetime(dom, 'date');
                            }
                        }

                        // 多语处理
                        if (item.itemtype === "residtxt") {
                            let langCode = getLangCode();
                            let loginLang = (item.languageMeta || []).filter(i => i.languageCode == langCode);
                            if (loginLang.length > 0) {
                                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                                if (
                                    record[item.attrcode + loginLang[0].index] &&
                                    record[item.attrcode + loginLang[0].index].value
                                ) {
                                    dom = record[item.attrcode + loginLang[0].index].value;
                                }
                            }
                        }

                        if (item.itemtype === 'number') {
                            dom = formatNumber(dom);
                        }
                        return (
                            <span className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : ''}`}>{
                                dom ? (
                                    <EllipsisToTooltip overlay={dom}>
                                        <span>{dom}</span>
                                    </EllipsisToTooltip>
                                ) : dom}
                            </span>
                        );

                    }
                };
            } else {
                if (item.itemtype === 'customer') {
                    render = item.render;
                } else {
                    render = (text, record, index) => {
                        return record[item.attrcode] ? (
                            <EllipsisToTooltip
                                className="tooltip-word-color"
                                placement={'left'}
                                delay={1}
                                overlay={item.render.call(
                                    null,
                                    text,
                                    record,
                                    index,
                                )}
                                noEllipsis
                            >
                                <div className={classnames(
                                    'col-customer-render-unit',
                                    {
                                        'single-line-and-ellipsis': this.state.transferTable['allSelected']['isEllipsis'],
                                        'three-line-and-ellipsis': !this.state.transferTable['allSelected']['isEllipsis'],
                                        'mult-line-and-ellipsis': !this.state.transferTable['allSelected']['isEllipsis'] && this.state.transferTable['allSelected']['bodyExpandAllInRow'],
                                    })
                                }>
                                    {item.render.call(null, text, record, index)}
                                </div>
                            </EllipsisToTooltip>
                        ) : record[item.attrcode];
                    };
                }
            }
            if (item.visible && item.attrcode !== 'checkbox' && item.attrcode !== 'numberindex') {
                result.push({ ...item, render, width, key: item.attrcode, title: item.label });
            }
        });
        if (result.length) {
            result[result.length - 1].render = () => {
                <span />;
            };
        }
        return result;
    };

    /*
    * 渲染子表表格数据
    * record：Object 主表展开行所属行的数据
    * index：Number 主表展开行所属行的下标
    * json：Object 多语资源
    * */
    const expandedRowRender = (record, index, json, uniqueTagCls) => {
        // let activeIndex = this.state.transferTable.allSelected.activeTab;
        // let currentBodyId = params[activeIndex].bodyTableId;

        // if (!this.state.meta[currentBodyId] || !this.state.meta[currentBodyId].items) {
        //     return <span />
        // }
        // let bodyColumn = this.state.meta[currentBodyId].items; //子表模板

        let bodyColumn = this.state.meta[allBodyId].items; //子表模板
        let newColumn = bodyColumn;
        let { sortType } = this.props;
        if (newColumn instanceof Array && newColumn.length > 0) {
            newColumn = renderColumnsMultiSelectChild.call(this, newColumn, record, index, json);

            let data = null,
                expandTableHeight = 0;
            let expandTableBodyHeight = 0;//展开子表表体的高度
            //已选列表的子表移除时需要删除行数据
            data = createNewData(
                Object.values(transferTable.allSelected.selectedData.childSelData[record.key] || {}).map(
                    item => item.data,
                ),
            );
            let isTotal = this.myState.status.bodyTotalStatusOfTransferTable;
            let $uniqueTagCls = document && document.querySelector(`.${uniqueTagCls}`);
            expandTableHeight = getExpandTableHeight(data, isTotal, $uniqueTagCls || ReactDOM.findDOMNode(this));
            expandTableBodyHeight = getExpandTableHeight(data, isTotal, $uniqueTagCls || ReactDOM.findDOMNode(this), true);
            // console.log(uniqueTagCls, $uniqueTagCls, expandTableHeight);

            return (
                <div style={{ height: `${expandTableHeight}px` }} className="transfertable-expand-wraper" fieldid={getSysFieldid(`selected-list-${index}_table`)}>
                    <ComplexTable
                        columns={createNewCol(newColumn, json, 'child')}
                        sort={sortType}
                        data={data}
                        lazyload={true}
                        isDrag={isDrag}
                        scroll={{ x: true, y: expandTableBodyHeight || 450 }}
                        isTotal={isTotal}
                        totalBodyHeight={30}
                        totalData={processingTotalData(
                            transferTable.allSelected.selectedData,
                            'body',
                            createNewCol(newColumn, json),
                            false,
                            record,
                        )}
                        totalColumns={createSelectColumns(createNewCol(newColumn, json), true, json)}
                        updateColumnHandle={updateColumnAfterSetting.bind(this)}
                        bodyStyle={{ minHeight: '30px' }}
                        tableType='transfer'
                        //折行回调
                        foldingRowChange={isEllipsis => {
                            this.state.transferTable['allSelected']['isEllipsis'] = isEllipsis
                            this.setState({ transferTable: this.state.transferTable })
                        }}
                        bodyDisplayInRow={this.state.transferTable['allSelected']['isEllipsis']}
                        bodyExpandAllInRow={this.state.transferTable['allSelected']['bodyExpandAllInRow']}
                        cancelCustomRightMenu={cancelCustomRightMenu}
                        colsSettingParam={getcolsSettingParam.call(this, this.state.meta, allBodyId)}
                    />
                    {/*</Col>*/}
                </div>

            );
        } else {
            return;
        }
    };

    /*
    * 提取已选列表主表数据
    * data：已选的原数据
    * */
    const getMasterData = data => {
        return Object.values(data.masterSelData).map(item => item.data);
    };

    /*
    * 点击页签
    * key：String 所点击的页签的唯一标识
    * */
    const handleTabClick = key => {
        if (this.state.transferTable.allSelected) {
            let headID = params[key].headTableId; // 当前页签的headId
            linkage.call(this, headID); // 多来源联动页签已选数据的更换
            // 改变当前页签下标
            this.state.transferTable.allSelected.activeTab = key;
        }
        this.setState(
            { transferTable: this.state.transferTable },
            () => {
                // 更新子表高度  by 这里的做法贼恶心 优秀的做法是  将计算高度放到render中
                this.infoExchangeController && this.infoExchangeController.publish('heightChange', { area: params[key].headTableId });
                if (typeof onTabClick === 'function') {
                    onTabClick(key);
                }
            },
        );
    };

    //点击切换视图
    const handleChangeView = () => {
        if (typeof onChangeViewClick === 'function') {
            onChangeViewClick();
        }
    };

    /*
    * 处理并获取已选列表主子拉平数据 （为已选的主子拉平数据增加headKey属性：将来点击生成按钮的时候提取pk需要用到）
    * data：已选的原数据
    * */
    const getSelectedFullData = data => {
        let { masterSelData, childSelData } = data;
        let result = [];
        for (let key of Object.keys(childSelData)) {
            // 存在 没有子表元素的主表数据
            if (hasObjProperty(childSelData[key])) {
                Object.values(childSelData[key]).forEach(item => {
                    // let childVal = JSON.parse(JSON.stringify(item.data.values || item.data || {}));
                    // let headData = JSON.parse(JSON.stringify(masterSelData[key].data || {}));
                    // 为了万行
                    let childVal = Object.assign({}, item.data.values || item.data || {});
                    let headData = Object.assign({}, masterSelData[key].data || {});

                    childVal.headKey = headData.key || headData.headKey;
                    // delete headData.headKey;
                    // delete headData.key;
                    let newData = Object.assign({}, childVal, headData); //合并后的主子拉平数据
                    if (newData.headKey) newData.headKey = key;
                    newData.key = childVal.key;

                    result.push(newData);
                });
            } else {
                // 没有就取主表数据
                // let newData = JSON.parse(JSON.stringify(masterSelData[key].data));
                // 为了万行
                let newData = masterSelData[key].data;
                newData.headKey = key;
                newData.key = key;
                result.push(newData);
            }
        }
        return result;
    };

    //获取多来源转单已选数据
    const getAllSelectedData = () => {
        let transferTable = this.state.transferTable;
        let allSelected = this.state.transferTable.allSelected;
        for (let items of Object.keys(transferTable)) {
            if (items !== 'allSelected') {
                let selected = transferTable[items].selected;
                let childS = selected.selectedData.childSelData;
                let masterS = selected.selectedData.masterSelData;
                for (let item of Object.keys(masterS)) {
                    if (Object.keys(masterS).length) {
                        if (!allSelected.selectedData.masterSelData.hasOwnProperty(item)) {
                            allSelected.masterCheckedId.push(item);
                            allSelected.selIds[item] = [];
                            allSelected.selectedData.masterSelData[item] = masterS[item];
                        }
                    }
                }
                for (let item of Object.keys(childS)) {
                    if (Object.keys(childS).length) {
                        if (!allSelected.selectedData.childSelData.hasOwnProperty(item)) {
                            allSelected.selectedData.childSelData[item] = childS[item];
                            for (let ite of Object.keys(childS[item])) {
                                allSelected.selIds[item].push(ite);
                            }
                        } else {
                            for (let ite of Object.keys(childS[item])) {
                                if (!allSelected.selectedData.childSelData[item].hasOwnProperty(ite)) {
                                    allSelected.selectedData.childSelData[item][ite] = childS[item][ite];
                                    allSelected.selIds[item].push(ite);
                                }
                            }
                        }
                    }
                }
                allSelected.masterAmount = allSelected.masterCheckedId.length;
                for (let item of Object.keys(allSelected.selIds)) {
                    // 有个数据没有子数据  但是应该也算一行数据
                    allSelected.bodyAmount += (allSelected.selIds[item].length || 1);

                }
            }
        }
        this.state.transferTable.allSelected = allSelected;
    };

    //点击生成按钮
    const handleGenerateClick = () => {
        let selIds = [];
        // getAllSelectedData();
        //获取已选列表主子表id
        if (!headPkIds || !bodyPkIds) {
            console.error('没有传主表或子表主键字段');
        }
        for (let ID of Object.keys(transferTable)) {
            if (ID !== 'allSelected') {
                let selected = transferTable[ID].selected;
                let masterData = JSON.parse(JSON.stringify(selected.selectedData.masterSelData));
                let childData = JSON.parse(JSON.stringify(selected.selectedData.childSelData));
                let bodys = [];
                for (let key of Object.keys(masterData)) {
                    let hValues = masterData[key].data;
                    if (hValues.saga_status && hValues.saga_status.value === '1') {
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                    if (childData[key]) {
                        for (let id of Object.keys(childData[key])) {
                            let values = childData[key][id].data.values || childData[key][id].data;
                            if (values.saga_status && values.saga_status.value === '1') {
                                // eslint-disable-next-line no-continue
                                continue;
                            }
                            bodyPkIds &&
                                bodyPkIds.map(pk => {
                                    let cData = childData[key][id].data.values || childData[key][id].data;
                                    if (cData.hasOwnProperty(pk)) {
                                        bodys.push({
                                            [pk]: id,
                                            ts: values.ts && values.ts.value,
                                        });
                                    }
                                });
                        }
                    }

                    headPkIds &&
                        headPkIds.map(pk => {
                            if (masterData[key].data.hasOwnProperty(pk)) {
                                selIds.push({
                                    head: {
                                        [pk]: key,
                                        ts: masterData[key].data.ts && masterData[key].data.ts.value,
                                    },
                                    bodys,
                                });
                            }
                        });
                    bodys = [];
                }
            }
        }
        ViewModel.setData('multiTransferIds', selIds);
        //避免无效渲染导致性能所以把setState去掉
        if (typeof onTransferBtnClick === 'function') {
            onTransferBtnClick(selIds);
        }
        /*this.setState(
            {
                transferTable: this.state.transferTable
            },
            () => {
                if (typeof onTransferBtnClick === 'function') {
                    onTransferBtnClick(selIds);
                }
            }
        );*/
    };

    if (Array.isArray(cutParams)) {
        return (
            <CreateMultiTransferTable
                _this={this}
                allHeadId={allHeadId}
                headPkIds={headPkIds}
                showAllList={showAllList}
                onSelectedBtnClick={onSelectedBtnClick}
                getAllSelectedData={getAllSelectedData}
                bodyPkIds={bodyPkIds}
                clearAllSelectedData={clearAllSelectedData}
                componentInitFinished={componentInitFinished}
                createNewCol={createNewCol}
                cutParams={cutParams}
                dataSource={dataSource}
                expandedRowRender={expandedRowRender}
                fullColumns={fullColumns}
                getMasterData={getMasterData}
                getScaleData={getScaleData}
                getSelectedFullData={getSelectedFullData}
                getTheLongestPrecision={getTheLongestPrecision}
                handleChangeView={handleChangeView}
                showChangeViewBtn={showChangeViewBtn}
                handleGenerateClick={handleGenerateClick}
                handleTabClick={handleTabClick}
                isTotal={this.myState.status.totalStatusOfTransferTable}
                isHeadTotal={this.myState.status.headTotalStatusOfTransferTable}
                isBodyTotal={this.myState.status.bodyTotalStatusOfTransferTable}
                isFullTotal={this.myState.status.fullTotalStatusOfTransferTable}
                params={params}
                showAll={showAll}
                onTabClick={this.onTabClick}
                onCheckedChange={onCheckedChange}
                showSeletedBtn={showSeletedBtn}
                showGenerateBtn={showGenerateBtn}
                // searchArea={searchArea}
                selectArea={selectArea}
                selectedHeaderRender={selectedHeaderRender}
                selectedAmount={selectedAmount}
                isShowBodyAmount={isShowBodyAmount}
                setSelectedColumns={setSelectedColumns}
                transferBtnText={transferBtnText}
                transferTable={transferTable}
                totalKey={totalKey}
                totalTitle={totalTitle}
                containerSelector={containerSelector}
                sortType={sortType}
                onExpandedChange={onExpandedChange}
                meta={this.state.meta}
                allBodyId={allBodyId}
                allFullTableId={allFullTableId}
                autoHeight={autoHeight}
            />
        );
    }
}
