/**
 * Created by wangshhj on 2018/1/16.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Table as NCTable, NumberInput as NCNumber, Checkbox, ErrorPop } from '@platform/base';
import CreateTranferTable from './CreateTranferTable';
import CreateTranferList from './CreateTranferList';
import {
    updateLastActiveFormData,
    thounsandTransfer,
    sortColumn,
    updateTransferDataAfterSort,
    updateColumnAfterSetting,
    getExpandTableHeight,
    iscludeCurrentData,
    checkboxSwitchValue,
    formatDot,
    getcolsSettingParam,
} from './methods';
import { getTableWidth } from './util'
import EllipsisToTooltip from './ellipsisToTooltip';
// 精度
import { sum, ViewModel, getSysFieldid, isTimeType, getLangCode, changeTime, formatAcuracy, commafy, linkTo, formatDatetime, formatNumber } from '@platform/api';
import classnames from 'classnames';
import { lib } from '@platform/table-core';
require('./index.less');
const { total, hoverContent } = lib;

const ComplexTable = hoverContent(total(NCTable.SortTable));
// 页面级别配置项
// const _DEFAULT = {
//     test_env: NODE_ENV === 'test', // node执行环境是否是test
// };
const defaultProps = {
    //复选框
    prefixCls: 'bee-table',
    multiSelect: {
        type: 'checkbox',
        param: 'key',
    },
};

const hasObjProperty = function (obj) {
    return !!(obj && Object.values(obj).length);
};

const defaultColor = ['#111111', '#555555', '#111', '#555'];

//创建转单
export function createTransferTable({
    dataSource, // 转单缓存数据的key string
    headTableId, // 转单主表ID string --表转单格的区域编码
    bodyTableId, // 转单子表ID string
    fullTableId, // 转单主子拉平ID string
    editStatus = false, // 转单是否可以编辑
    isMultiChild = false, // 判断转单是否是多来源的子
    comType = 'hasCheckBox_child', //根据组件类型，判断表格是否带复选框 hasCheckBox/hasCheckBox_child有复选框和selectedList无复选框
    showMasterIndex = true, // 主表是否要显示序号
    showChildIndex = true, // 子表是否要显示序号
    transferBtnText = '转单', // 右下角按钮
    onTransferBtnClick, // 右下角按钮点击回调事件
    containerSelector, // 转单的父容器
    componentInitFinished, // 转单初始化结束后的回调
    onChangeViewClick, // 切换视图的回调
    showChangeViewBtn = true, //已选列表页面 切换主子表/拉平表 的按钮显示控制 Boolean
    showSeletedArea = true, //有全部页签的多来源转单不显示已选列表所在灰条的控制参数
    showSeletedBtn = true, //普通多来源转单不显示查看已选按钮
    showGenerateBtn = true, // 控制是否显示生成按钮的参数
    tableType = 'nest', //表格显示类型 nest:主子表 full:主子拉平 simple:单表
    selectArea, //已选列表底部栏自定义区域
    onCheckedChange, //勾选回调函数
    onClearAll, //已选列表清空回调函数
    onSelectedItemRemove, // 已选列表移除某条数据的回调函数
    generateKeys, // 生成按钮需要保存的字段集合
    customAreaOfSelectedHead, // 已选列表顶部自定义区域
    headPkIds, // 多来源的时候由父组件传下来的参数，用于保存选中的其他页签的pk
    bodyPkIds, // 多来源的时候由父组件传下来的参数，用于保存选中的其他页签的pk

    cacheItem, // 需要缓存的字段  by gouyangf
    editableItem, // 可编辑的字段 by guoyangf
    afterEvent, // 可编辑字段的编辑后事件 by guoyangf

    totalKey = [], // 自定义合计字段
    totalTitle = [], // 自定义合计中文
    isDrag, // 拖拽
    sortType = { //排序的类型 Object
        mode: 'single', //'multiple'(多列排序) 或'single'(单列排序)(如果前端排序的时候想多列排序，对象里就穿这个字段就可以了),
        backSource: false, //false是前端排序，值为true为后端排序,默认false
        sortFun: (sortInfoObj, sortData) => { //sortInfoObj:{field:是模板的dataIndex,order:"ascend"升序/"descend"降序} , sortData:[]排序后的数据
            updateTransferDataAfterSort(sortData, headTableId, this, sortInfoObj);
        },
    },
    onExpandedChange, //主子表点击加号的回调 function
    customSortRule, //自定义排序规则
    getMultiChildChangeWidth, //获取多来源有全部页签的共用底部灰条宽度的额方法 function
    fieldid,
    fieldId,
    defaultOperateColumnHidden = false, // true  false
    // 多模板pageId  传入
    currentPageId,
    cancelCustomRightMenu = false,
    autoHeight = true,
    fullDataRule = 'mainbody',
    bodyExpandAllInRow = false, // 折行全部展开
}) {
    if (dataSource) {
        this.transferTableDataSource = dataSource;
    }

    /**
     * this.state.transferTable Object
     * {
            allSelected:{} 被选中的单据信息
            转单主表1 id:{
                转单主表id1:{}
                selected:{//当前表单选中的数据

                }
            }
            转单主表1 id:{
                转单主表id1:{}
            }
     * }
     */
    if (!this.state.transferTable.hasOwnProperty('allSelected')) {
        this.state.transferTable['allSelected'] = {
            activeTab: '0', //当前高亮tab
            selectedShow: false, //显示已选列表
            masterAmount: 0, //主表已选数量
            bodyAmount: 0, //子表已选数量
            masterCheckedId: [], //存放已选主表id，用于复选框半选
            selIds: {}, //存放其他页签的主子表id
            showFullTable: false, //已选列表中是否显示主子拉平
            selectedData: {
                selAll: false,
                masterSelData: {},
                childSelData: {},
            },
        };
    }

    if (!this.state.transferTable.hasOwnProperty(headTableId)) {
        this.state.transferTable[headTableId] = {
            [headTableId]: {
                tableType,
                bodyData: {},
                outerData: [],
                showFullTable: tableType === 'full',
                firstTime: true,
                bodyTableId,
            },
            selected: {
                selectedShow: false,
                masterAmount: 0, //主表已选数量
                bodyAmount: 0, //子表已选数量
                masterCheckedId: [], //存放已选主表id，用于复选框半选
                selectedData: {
                    selAll: false,
                    masterSelData: {},
                    childSelData: {},
                },
            },
        };
    }

    if (!this.state.meta) { //拿到模板数据之后才做渲染
        return false;
    }

    //总的转单的数据
    let transferTable = this.state.transferTable[headTableId];
    if (!transferTable.hasOwnProperty('isEllipsis')) {
        transferTable['isEllipsis'] = true
    }
    if(!transferTable.hasOwnProperty('bodyExpandAllInRow')){
        transferTable['bodyExpandAllInRow'] = bodyExpandAllInRow
    }
    //当前要渲染的转单的全部列表 数据
    let currentTable = this.state.transferTable[headTableId][headTableId];

    if (currentTable.showFullTable) { //是否要拉平展示
        if (!this.state.meta[fullTableId]) { //模板里是否有配置拉平的字段
            return false;
        }
        //headTableId = fullTableId;
    } else {
        if (!this.state.meta.hasOwnProperty(headTableId) || !this.state.meta[headTableId]) {
            return false;
        }
    }

    /**
     * currentTable.outerData 主表的数据 [{},{},...]  
     * data:Array
     */
    let data = currentTable.outerData.map(item => {
        item.values.headKey = item.values.key;
        item.values.headTs = item.values.ts;
        return item;
    });

    // let transformForm = this.state.transferTable[headTableId].transformForm;
    this.onTransferBtnClick = onTransferBtnClick; //转单按钮回调函数--生成采购单按钮
    this.onChangeViewClick = onChangeViewClick; //切换视图回调函数 ---切换按钮
    this.onCheckedChange = onCheckedChange; //复选框勾选回调函数 
    this.onClearAll = onClearAll; //已选列表清空回调函数

    //表格的列模板信息
    //主表模板
    let columns = this.state.meta[headTableId] && this.state.meta[headTableId].items.map((val, index) => {
        sortColumn(val, sortType, customSortRule);
        // 清理required 属性  不然会导致表头错行
        if (val && val.required) {
            delete val.required;
        }
        return val;
    });
    //子表模板
    let bodyColumn = bodyTableId && this.state.meta[bodyTableId] ? this.state.meta[bodyTableId].items.map((val, index) => {
        sortColumn(val, sortType, customSortRule);
        // 清理required 属性  不然会导致表头错行
        if (val && val.required) {
            delete val.required;
        }
        return val;
    }) : [];

    //主子拉平模板
    let fullColumns = fullTableId && this.state.meta[fullTableId] ? this.state.meta[fullTableId].items.map((val, index) => {
        sortColumn(val, sortType, customSortRule);
        // 清理required 属性  不然会导致表头错行
        if (val && val.required) {
            delete val.required;
        }
        return val;
    }) : [];

    // 是否遍历模板的状态 Boolean  查找是否有配合计字段istotal为true的，遍历到就为true
    let traversalStatus = this.myState.status.traversalStatus;

    //选择主/子表时记录主表id id:主表id flag:选中/取消选中
    const setMasterId = (id, flag) => {
        let { masterCheckedId, selectedData } = transferTable.selected;
        if (!masterCheckedId.includes(id) && flag) {
            masterCheckedId.push(id);
        } else if (masterCheckedId.indexOf(id) !== -1 && !selectedData.selAll) {
            masterCheckedId.splice(masterCheckedId.indexOf(id), 1);
        }
    };

    //判断合计行是否显示
    const traversalColumns = columns => {
        for (let items of columns) {
            if (items.istotal) { //是都 要显示合计行
                this.myState.status.totalStatusOfTransferTable = true;//判断合计行是否显示
                this.myState.status.traversalStatus = true;
            } else if (items.data) {
                for (let item of items.data) {
                    if (item.istotal) {
                        this.myState.status.totalStatusOfTransferTable = true;
                        this.myState.status.traversalStatus = true;
                    }
                }
            }
            if (this.myState.status.traversalStatus) {
                break;
            }
        }
        traversalColumn.call(this, columns[0].data, 'head');
        traversalColumn.call(this, columns[1].data, 'full');
        traversalColumn.call(this, columns[2].data, 'body');
    };

    //设置主表、子表、拉平表的合计行是否显示
    const traversalColumn = (column, key) => {
        if (column) {
            for (let items of column) {
                if (items.istotal) {
                    this.myState.status[`${key}TotalStatusOfTransferTable`] = true;
                    this.myState.status[`${key}traversalStatus`] = true;
                }
            }
        }
    };

    //查看是否遍历了合计状态
    // 去除  部分情况下  模板发生了变化  但是合计并没有更新
    // if (!traversalStatus) {
    let columnsArr = [
        { data: columns, title: 'columns' },
        { data: fullColumns, title: 'columns' },
        {
            data: bodyColumn,
            title: 'columns',
        },
    ];
    traversalColumns.bind(this, columnsArr)();
    // }

    //判断子表是否全选
    const isChildCheckAll = parents => {
        let innerDataLen = currentTable.bodyData.data[parents.key].rows.length;
        let childSelLen = Object.keys(transferTable.selected.selectedData.childSelData[parents.key]).length;
        if (innerDataLen === childSelLen) {
            return true;
        }
        return false;
    };

    //判断主表是否全选
    const isMasterCheckAll = () => {
        // let outerDataLen = currentTable.outerData.length;
        // let bodyDataLen = Object.keys(currentTable.bodyData.data).reduce((total, next, item) => {
        //     return total += currentTable.bodyData.data[next].rows.length;
        // }, 0);
        // let masterSelLen = transferTable.selected.masterCheckedId.length;
        // let bodySelLen = Object.keys(transferTable.selected.selectedData.childSelData).reduce((total, next, item) => {
        //     return total += Object.keys(transferTable.selected.selectedData.childSelData[next]).length;
        // }, 0);
        // if (tableType === 'simple') {
        //     if (outerDataLen === masterSelLen) {
        //         return true;
        //     }
        // } else if (bodyDataLen >= 0 && bodyDataLen <= bodySelLen) {
        //     return true;
        // } else if (bodyDataLen === 0 && bodyDataLen === bodySelLen) {
        //     if (outerDataLen === masterSelLen) {
        //         return true;
        //     }
        // }
        // return false;
        let { isSelectAll } = iscludeCurrentData(transferTable, headTableId);

        return isSelectAll;
    };

    /**
     * 主子表显示状态  切换子表的复选框的时候设置 allSelected、childSelData、masterSelData 里的选中数据
     *切换选中状态行的数据 record：{} 
     * 被选中子表的主表的数据 parents：{} 为了取key去作为属性去保存选中的数据信息
     * 是否选中 flag：Boolean 
     * 看是主表还是子表选中 type：String  body/head
     */
    const setAllSelectedValue = ({ record, parents, flag, type }) => {
        let parent = parents;
        let allSelected = this.state.transferTable.allSelected;
        let childSelData = allSelected.selectedData.childSelData;
        let masterSelData = allSelected.selectedData.masterSelData;
        if (type === 'body') {
            if (flag) {
                if (!allSelected.masterCheckedId.includes(parent.key)) allSelected.masterCheckedId.push(parent.key);
                if (!allSelected.selIds.hasOwnProperty(parent.key)) {
                    allSelected.selIds[parent.key] = [record.key];
                } else {
                    if (!allSelected.selIds[parent.key].includes(record.key)) allSelected.selIds[parent.key].push(record.key);
                }
                masterSelData[parent.key] = {
                    sel: true,
                    data: parent,
                };
                if (!childSelData.hasOwnProperty(parent.key)) {
                    childSelData[parent.key] = {
                        [record.key]: {
                            sel: true,
                            data: {
                                childId: record.key,
                                values: record,
                            },

                        },
                    };
                } else {
                    if (!childSelData[parent.key].hasOwnProperty(record.key)) {
                        childSelData[parent.key][record.key] = {
                            sel: true,
                            data: {
                                childId: record.key,
                                values: record,
                            },
                        }
                    }
                }

            } else {
                allSelected.selIds[parent.key] = allSelected.selIds[parent.key].filter(item => item !== record.key);
                if (!allSelected.selIds[parent.key].length) delete allSelected.selIds[parent.key];
                if (childSelData[parent.key].hasOwnProperty(record.key)) delete childSelData[parent.key][record.key];
                if (!Object.keys(childSelData[parent.key]).length) {
                    delete childSelData[parents.key];
                    delete childSelData[parents.key];
                    allSelected.masterCheckedId = allSelected.masterCheckedId.filter(item => item !== parents.key);
                }
            }
        } else if (type === 'head') {
            let curKey = record.headKey || record.key; //主子拉平取主表id
            if (flag) {
                let bodyData = transferTable[headTableId].bodyData.data;
                let selectedBodyData = {};
                let selIdsBodyData = {};
                if (!allSelected.masterCheckedId.includes(curKey)) allSelected.masterCheckedId.push(curKey);
                masterSelData[curKey] = {
                    sel: true,
                    data: record,
                };
                for (let key of Object.keys(bodyData)) {
                    let itemBodyData = {};
                    let itemSelIdsData = [];
                    if (key === curKey) {
                        for (let item of bodyData[key].rows) {
                            itemBodyData[item.childId] = {
                                sel: true,
                                data: {
                                    childId: item.childId,
                                    values: item.values,
                                },
                            };
                            if (!itemSelIdsData.includes(item.childId)) itemSelIdsData.push(item.childId);
                        }
                        selectedBodyData[key] = itemBodyData;
                        selIdsBodyData[key] = itemSelIdsData;
                    }

                }
                allSelected.selectedData.childSelData = { ...childSelData, ...selectedBodyData };
                allSelected.selIds = { ...allSelected.selIds, ...selIdsBodyData };
            } else {
                allSelected.masterCheckedId = allSelected.masterCheckedId.filter(item => item !== curKey);
                delete masterSelData[curKey];
                delete childSelData[curKey];
                delete allSelected.selIds[curKey];
            }
        }
        // 有个数据没有子数据  但是应该也算一行数据
        allSelected.bodyAmount = Object.values(allSelected.selIds).reduce((total, now) => total += (now.length || 1), 0);
        allSelected.masterAmount = allSelected.masterCheckedId.length;
    };
    //===========================子表添加复选框===========================================

    //所有选中的子表条数总合 所有主表的条数总合 的处理
    //transferTable Object
    const getAmount = transferTable => {
        let selected = transferTable.selected;
        let selectedData = selected.selectedData;
        let bodyAmount = 0;//所有选中的子表条数总合
        for (let item of Object.keys(selectedData.childSelData)) {
            // 有个数据没有子数据  但是应该也算一行数据
            bodyAmount += (Object.keys(selectedData.childSelData[item]).length || 1);
        }
        return {
            bodyAmount,
            masterAmount: Object.keys(selectedData.masterSelData).length, //所有主表的条数总合
        };
    };

    /*
     * 子表复选框单个勾选 修改数据里的allSelected、和selected里的一些选中信息
     * @param {*} text  
     * @param {Object} record 当前行的数据 
     * @param {Number} index 索引
     * @param {Object} parents 取名应该为parent，是选中子表的主表数据
     * @param {Number} parentsIndex  索引
     * @param {Boolean} bool 
     * @param {Boolean} isOnCheckedChange 
     * bool和isOnCheckedChange都是为了控制不给业务组复选框切换 传两次数据的  修复条件
     */
    const onCheckboxChangeChild = (text, record, index, parents, parentsIndex, bool = false, isOnCheckedChange = true) => {
        let selected = transferTable.selected;
        let selectedData = selected.selectedData;
        let master = selectedData.childSelData[parents.key];
        let flag = true;
        if (!master) {
            selectedData.childSelData[parents.key] = {
                [record.key]: {
                    sel: true,
                    data: record,
                    // masterData: parents
                },
            };
            selectedData.masterSelData[parents.key] = {
                sel: true,
                data: parents,
            };
            setAllSelectedValue({ record, parents, flag: true, type: 'body' }); // 为多来源转单设置选中数据
            // setAllSelVal('body', true, parents.key, record.key); //多来源有全部页签需要单独统计已选数据
            // //判断主表是否全选
            if (isMasterCheckAll()) {
                selectedData.selAll = true;
            }
            if (isChildCheckAll(parents)) {
                setMasterId(parents.key, true);
            }
        } else {
            let child = master[record.key];
            flag = !child;
            if (!child) {
                selectedData.childSelData[parents.key][record.key] = {
                    sel: true,
                    data: record,
                    // masterData: parents
                };
                selectedData.masterSelData[parents.key] = {
                    sel: true,
                    data: parents,
                };
                setAllSelectedValue({ record, parents, flag: true, type: 'body' }); // 为多来源转单设置选中数据
                setAllSelVal('body', true, parents.key, record.key); //多来源有全部页签需要单独统计已选数据
                // //判断子表数据是否全选
                if (isChildCheckAll(parents)) {
                    setMasterId(parents.key, true);
                }
                //判断主表是否全选
                if (isMasterCheckAll()) {
                    selectedData.selAll = true;
                }
            } else {
                delete selectedData.childSelData[parents.key][record.key];
                let childAll = selectedData.childSelData[parents.key];
                if (Object.keys(childAll).length === 0) {
                    delete selectedData.childSelData[parents.key];
                    delete selectedData.masterSelData[parents.key];
                }
                setAllSelectedValue({ record, parents, flag: false, type: 'body' }); // 为多来源转单设置选中数据

                // setAllSelVal('body', false, parents.key, record.key); //多来源有全部页签需要单独统计已选数据
                selectedData.selAll = false;
                setMasterId(parents.key, false);
            }
        }
        let amountData = getAmount.call(this, transferTable);
        selected.bodyAmount = amountData.bodyAmount;
        selected.masterAmount = amountData.masterAmount;
        this.state.transferTable[headTableId] = transferTable;
        this.setState(
            { transferTable: this.state.transferTable },
            () => {
                if (typeof bool === 'boolean') {
                    isOnCheckedChange && onCheckedChange && onCheckedChange(flag, JSON.parse(JSON.stringify(record)), index, null, parentsIndex);
                }
            },
        );
    };

    /*多来源有全部页签需要单独统计已选数据 切换选中状态的时候对selIds进行更改
     * 
     * @param {String} type head/body 被勾选的类型是主表还是子表
     * @param {Boolean} status  选中状态
     * @param {String} headId 主表id
     * @param {String} bodyId 子表id
     */
    const setAllSelVal = (type, status, headId, bodyId) => {
        window.transferTable = this.state.transferTable;
        let allSelected = this.state.transferTable.allSelected;
        if (allSelected) {
            if (status) {
                if (!allSelected.selIds[headId]) {
                    if (type === 'head') {
                        //主表勾选
                        allSelected.selIds[headId] = [];
                    } else {
                        //子表勾选
                        allSelected.selIds[headId] = [bodyId];
                    }
                } else if (bodyId && !allSelected.selIds[headId].includes(bodyId)) {
                    //子表勾选
                    allSelected.selIds[headId].push(bodyId);
                }
            } else {
                if (type === 'head') {
                    //主表取消勾选
                    delete allSelected.selIds[headId];
                } else {
                    //子表取消勾选
                    let selIds = allSelected.selIds;
                    if (selIds[headId]) {
                        selIds[headId].splice(selIds[headId].indexOf(bodyId), 1);
                        if (selIds[headId].length === 0) {
                            delete selIds[headId];
                        }
                    }
                }
            }
        }
    };

    //判断主表或子表是否有选中项
    const isHaveCheck = () => {
        let masterCheckFlag = false;
        let childCheckFlag = false;
        let masterChecklen = Object.keys(transferTable.selected.selectedData.masterSelData).length;
        let datalen = currentTable.outerData.length;
        if (masterChecklen > 0 && masterChecklen < datalen) {
            masterCheckFlag = true; //主表有选中项,且非全选
        }
        let childChecklen = Object.keys(transferTable.selected.selectedData.childSelData).length;
        if (childChecklen > 0) {
            childCheckFlag = true;
        }
        if (masterCheckFlag || childCheckFlag) {
            return true;
        }
        return false;
    };

    //将当前页所有数据加入/取消已选择 flag: Boolean
    const selectAllMaster = flag => {
        let { outerData } = currentTable;
        outerData.map((item, index) => {
            let hValues = item.values || item;
            if (!hValues.saga_status || hValues.saga_status.value !== '1') { // socket 错误数据过滤
                switchMasterChecked(flag, item.values, item.values, index, true);
            }
        });
    };

    //============================================主表添加复选框==================================
    //主表复选框全选点击事件 selAll: Boolean true为全部选中，false为全部取消
    const onAllCheckChange = selAll => {
        transferTable.selected.selectedData.selAll = selAll;
        selectAllMaster(selAll);
        this.state.transferTable[headTableId] = transferTable;
        this.setState({ transferTable: this.state.transferTable });
    };

    /*主表复选框单个勾选
     * @param {text} 暂时未发现用处，可console.log看看
     * @param {record} Object  选中或取消当前行的数据
     * @param {index} Number 选中或取消当前行的下标
     * @param {bool} Boolean 控制回调函数是否执行
     *
     * */
    const onCheckboxChange = (text, record, index, bool) => {
        let curKey = record.headKey || record.key; //主子拉平取主表id
        switchMasterChecked(!transferTable.selected.selectedData.masterSelData[curKey], text, record, index, bool);
    };

    //主表切换勾选状态 flag:开关状态，默认选中
    const switchMasterChecked = (flag = true, text, record, index, bool) => {
        let selected = transferTable.selected;
        let selectedData = transferTable.selected.selectedData;
        let bodyData = currentTable.bodyData.data;
        let curKey = record.headKey || record.key; //主子拉平取主表id
        let delBodyData;
        //选中
        if (flag) {
            selectedData.masterSelData[curKey] = {
                sel: true,
                data: record,
            };
            // setAllSelVal('head', true, curKey); //多来源有全部页签需要单独统计已选数据
            setAllSelectedValue({ record, parents: curKey, flag: true, type: 'head' });
            let innerData = bodyData[curKey];
            if (innerData) {
                selectedData.childSelData[curKey] = {};
                innerData.rows.map((item, index) => {
                    selectedData.childSelData[curKey][item.values.key] = {
                        sel: true,
                        data: item,
                    };
                    // setAllSelVal('body', true, curKey, item.values.key);
                });
            }
            setMasterId(curKey, true); //选择主表时记录主表id
            //判断主表是否全选
            if (isMasterCheckAll()) {
                selectedData.selAll = true;
            }
        } else {
            //移除
            selectedData.selAll = false;
            setMasterId(curKey, false);
            // setAllSelVal('head', false, curKey); //多来源有全部页签需要单独统计已选数据
            setAllSelectedValue({ record, flag: false, type: 'head' });

            if (selectedData.masterSelData[curKey]) {
                delete selectedData.masterSelData[curKey];
            }
            if (selectedData.childSelData[curKey]) {
                delBodyData = selectedData.childSelData[curKey]; //记录被删除的子表数据
                delete selectedData.childSelData[curKey];
            }
        }
        let amountData = getAmount.call(this, transferTable);
        selected.bodyAmount = amountData.bodyAmount;
        selected.masterAmount = amountData.masterAmount;
        this.state.transferTable[headTableId] = transferTable;
        this.setState(
            { transferTable: this.state.transferTable },
            () => {
                if (typeof bool === 'object' || bool === true) { // 控制回调方法是否执行 为了改已选列表移除的问题 NCCLOUD-163594
                    let bdata = flag ? selectedData.childSelData[curKey] : delBodyData;
                    let bodySelData = bdata ? Object.values(bdata).map(item => item.data.values || item.data) : []; //勾选主表获取子表数据
                    onCheckedChange && onCheckedChange(flag, JSON.parse(JSON.stringify(record)), index, JSON.parse(JSON.stringify(bodySelData)));
                }
            },
        );
    };

    /**
     * 表格行选中状态获取
     * @param {Array} childSelData 
     * @param {Array} masterSelData 
     * @param {Array} recordKey 
     * @param {Array} isFull  拉平
     */
    const getCheckedStatus = (childSelData = {}, masterSelData = {}, recordKey, isFull = false) => {
        let bodyData = transferTable[headTableId].bodyData.data[recordKey], // 所有的body数据
            status = 'empty';
        // console.log(masterSelData, childSelData, bodyData, recordKey);

        // 拉平时以子表为主 只有全选和不选
        if (isFull) {
            let ifff = (childSelData[recordKey] || (masterSelData && masterSelData[recordKey]));
            return ifff ? 'full' : status;
        }

        // 主表是否存在这条数据
        let res = masterSelData && masterSelData[recordKey];

        // 子表是否存在这条数据
        if (bodyData) {
            for (let item of bodyData.rows) {
                if (childSelData[item.childId]) {
                    status = 'indeterminate';
                } else {
                    res = false;
                }
            }
        }

        return res ? 'full' : status;
    };

    //主子拉平切换勾选状态
    const onCheckboxChangeFull = (text, record, index) => {
        let selected = transferTable.selected;
        let selectedData = transferTable.selected.selectedData;
        let master = selectedData.childSelData[record.headKey];
        // selectedData.childSelData[record.headKey][record.key] // 这个没有值
        // master = master[record.key] ? master : null;
        let outerData = transferTable[headTableId].outerData;
        let bodyData = transferTable[headTableId].bodyData;
        let nowItem = bodyData.data[record.headKey].rows.filter(item => {
            return item.childId === record.key;
        });
        let masterVal = {};
        for (let item of outerData) {
            if (item.rowId === record.headKey) {
                masterVal = item.values;
            }
        }
        let allSelected = this.state.transferTable.allSelected && this.state.transferTable.allSelected.selectedData;
        let flag = true;
        // 1、如果bodyData找到当前数据 代表是子表的数据 并且 selected中找不到对应的childData 
        // 1、如果bodyData找不到当前数据 代表是无子表的数据 并且 selected 中找不到对应的masterSelData
        let shouldChecked = (nowItem && nowItem[0] && (!master || !master[record.key])) || ((!nowItem || !nowItem[0]) && !selectedData.masterSelData[record.headKey]);

        if (shouldChecked) {
            // 处理一下不存在的问题
            selectedData.childSelData[record.headKey] = selectedData.childSelData[record.headKey] || {};
            nowItem && nowItem[0] && nowItem[0].values && (selectedData.childSelData[record.headKey][record.key] = {
                sel: true,
                data: nowItem[0].values,
            });

            selectedData.masterSelData[record.headKey] = {
                sel: true,
                data: masterVal,
            };
            //记录全部多来源主子拉平的勾选数据
            if (allSelected) {
                // 处理一下不存在的问题
                allSelected.childSelData[record.headKey] = allSelected.childSelData[record.headKey] || {};
                nowItem && nowItem[0] && nowItem[0].values && (allSelected.childSelData[record.headKey][record.key] = {
                    sel: true,
                    data: nowItem[0].values,
                });

                allSelected.masterSelData[record.headKey] = {
                    sel: true,
                    data: masterVal,
                };

                // 同步一下数据到 selectedData
                // selectedData.childSelData[record.headKey] = allSelected.childSelData[record.headKey];
                // selectedData.masterSelData[record.headKey] = allSelected.masterSelData[record.headKey];

            }
            setAllSelVal('body', true, record.headKey, record.key);
            // 两种情况  要设置masterId
            if (!master || bodyData.data[record.headKey].rows.length === Object.keys(selectedData.childSelData[record.headKey]).length) {
                setMasterId && setMasterId(record.headKey, true);
            }
            //判断主表是否全选
            if (isMasterCheckAll()) {
                selectedData.selAll = true;
                if (allSelected) {
                    allSelected.selAll = true;
                }
            }
        } else {
            flag = false;
            delete selectedData.childSelData[record.headKey][record.key];
            let childAll = selectedData.childSelData[record.headKey];
            if (Object.keys(childAll).length === 0) {
                delete selectedData.childSelData[record.headKey];
                delete selectedData.masterSelData[record.headKey];
            }
            selectedData.selAll = false;
            //删除全部多来源主子拉平的勾选数据
            if (allSelected) {
                delete allSelected.childSelData[record.headKey][record.key];
                let childAll = allSelected.childSelData[record.headKey];
                if (Object.keys(childAll).length === 0) {
                    delete allSelected.childSelData[record.headKey];
                    delete allSelected.masterSelData[record.headKey];
                }
                allSelected.selAll = false;
            }
            setMasterId(record.headKey, false);
            setAllSelVal('body', false, record.headKey, record.key);
        }
        let amountData = getAmount.call(this, transferTable);
        selected.bodyAmount = amountData.bodyAmount;
        selected.masterAmount = amountData.masterAmount;
        this.state.transferTable[headTableId] = transferTable;

        this.setState(
            { transferTable: this.state.transferTable },
            () => {
                onCheckedChange && onCheckedChange(flag, JSON.parse(JSON.stringify(record)), index);
            },
        );
    };

    /**
     * 获取数据
     * @param {Array} outerData 
     * @param {Array} bodyData 
     */
    const getTableData = (outerData, bodyData) => {
        let head = [],
            body = [];
        for (let od of outerData) {
            let hValues = od.values || od;
            if (hValues.saga_status && hValues.saga_status.value == '1') { // socket 错误数据过滤
                continue;
            }
            head.push(od.values || od);
            if (bodyData.data && bodyData.data[od.rowId] && bodyData.data[od.rowId].rows) {
                for (let item of bodyData.data[od.rowId].rows) {
                    let bValues = item.values || item;
                    if (bValues.saga_status && bValues.saga_status.value == '1') { // socket 错误数据过滤
                        continue;
                    }
                    body.push(item.values || item);
                }
            }
        }
        return { body, head };
    };

    /**
     * 转单表格表头和表体主表添加复选框列
     * @param {*} columns 
     * @param {*Object} json 多语
     */
    const renderColumnsMultiSelect = (columns, json, indexColWidth = 80, tableType) => {
        const { multiSelect } = defaultProps;
        let showFullTable = transferTable[headTableId].showFullTable;
        let headTableType = transferTable[headTableId].tableType;
        // 添加的序号列
        let indexCol = [
            {
                label: <span style={{ display: 'block', textAlign: 'left' }}>{json['page-transferTable-0007'] || '序号-3'}</span>,
                attrcode: 'numberindex',
                itemtype: 'customer',
                dataIndex: 'numberindex',
                visible: true,
                width: showFullTable || headTableType==='simple' ? 45 : indexColWidth,
                className: 'col-customer-numberindex',
                render: (text, record, index) => {
                    return <span style={{ display: 'block', textAlign: 'left' }}>{index + 1}</span>;
                },
            },
        ];
        let newColumn = [].concat(columns);

        let checkboxDisabled = !transferTable[headTableId].outerData.length;
        if (showMasterIndex) { // 已选列表时 不设置序号列  可能会有 展开的问题
            newColumn = indexCol.concat(newColumn);
        }
        if (!showMasterIndex && tableType === 'selectedAndExpand') { // 已选列表 不设置序号 但是要设置 空列 放置展开按钮
            newColumn = [
                {
                    label: <span style={{ display: 'block', textAlign: 'left' }} />,
                    attrcode: 'numberindex',
                    itemtype: 'customer',
                    dataIndex: 'numberindex',
                    visible: true,
                    width: 30,
                    className: 'col-customer-noindexcol',
                    render: (text, record, index) => {
                        return <span style={{ display: 'block', textAlign: 'left' }} />;
                    },
                },
            ].concat(newColumn);
        }
        if (multiSelect && multiSelect.type === 'checkbox') {
            let { isSelectAll: selAll, isIndeterminate: indeterminate } = iscludeCurrentData(transferTable, headTableId);
            // (transferTable.selected.selectedData.selAll && Object.keys(transferTable.selected.selectedData.masterSelData).length
            // console.log(indeterminate);
            //复选框模板[{}]
            newColumn.unshift({
                /* 表头复选框*/
                label: (
                    <Checkbox
                        className="table-checkbox"
                        disabled={checkboxDisabled}
                        checked={selAll}
                        // indeterminate={indeterminate && !selAll}
                        indeterminate={indeterminate}//半选
                        onChange={() => {
                            onAllCheckChange.call(this, !selAll);
                            if (!selAll) {
                                //全选事件
                                let { head: headData, body: bodyData } = getTableData(currentTable.outerData, currentTable.bodyData);
                                onCheckedChange && onCheckedChange(!selAll, JSON.parse(JSON.stringify(headData)), 'all', JSON.parse(JSON.stringify(bodyData)));
                            } else {
                                //全消钩子函数
                                if (typeof onClearAll === 'function') {
                                    onClearAll();
                                }
                            }
                        }}
                    />
                ),
                onCellClick: (record, e) => {
                    let ck = record.key || record.headKey;
                    let values = record.values || record;
                    let index = -1;
                    (currentTable.outerData || []).some((item, i) => {
                        let values = item.values || item;
                        if ((values.key || values.headKey) === ck) {
                            index = i;
                            return true;
                        }
                    });
                    if (index == -1 || (values && values.saga_status && values.saga_status.value === '1')) {
                        return;
                    }
                    // console.log(index, 'xxxxx');
                    showFullTable ? onCheckboxChangeFull.call(this, '', record, index) : onCheckboxChange.call(this, '', record, index,e)
                },
                attrcode: 'checkbox',
                itemtype: 'customer',
                dataIndex: 'checkbox',
                visible: true,
                width: showFullTable || headTableType==='simple'? 54 : 80,
                fixed: 'left',
                className: 'col-customer-checkbox',
                render: (text, record, index) => { //表体主表复选框
                    let ck = record.key || record.headKey, // 当前的key  拉平的时候子表的主键为主
                        childSelData = transferTable.selected.selectedData.childSelData,
                        masterSelData = transferTable.selected.selectedData.masterSelData,
                        checkedStatus = getCheckedStatus(childSelData[record.headKey || record.key], masterSelData, ck, showFullTable);
                    // console.log(record, childSelData, masterSelData);
                    // Socket改造
                    // let { billpkname, billtype, errorViewType } = this.nativeSocketBillInfo;
                    let values = record.values || record;
                    return values && values.saga_status && values.saga_status.value === '1' ? (
                        <ErrorPop
                            record={record}
                        />
                    ) : (
                            <Checkbox
                                className="table-checkbox"
                                checked={checkedStatus === 'full'}
                                indeterminate={checkedStatus === 'indeterminate'}
                                onClick={showFullTable ? onCheckboxChangeFull.bind(this, text, record, index) : onCheckboxChange.bind(this, text, record, index)}
                            />
                        );

                },
            });
        }
        return newColumn;
    };

    /*
     * 子表添加复选框列和序号
     * @param {Array} columns 渲染子表的每一列的属性数据 [{},{},...] 
     * @param {*} records 
     * @param {*} indexs 
     * @param {*} comType 
     * @param {*} json 
     */
    const renderColumnsMultiSelectChild = (columns, records, indexs, comType, json) => {
        let { multiSelect } = defaultProps;
        let indexCol = [
            {
                label: <span bbq="已选展开列序号" style={{ display: 'block', textAlign: 'left' }}>{json['page-transferTable-0007'] || '序号-2'}</span>,
                attrcode: 'numberindex',
                itemtype: 'customer',
                dataIndex: 'numberindex',
                visible: true,
                width: 70,
                className: 'col-customer-numberindex',
                render: (text, record2, index) => {
                    return (<span style={{ display: 'block', textAlign: 'left' }} >{index + 1}</span>);
                },
            },
        ]; // 添加序号列
        let newColumn = columns;
        let oprationLabel = json['page-transferTable-0006'] ? json['page-transferTable-0006'] : '操作-4';
        /*国际化处理 操作*/
        if (showChildIndex) {
            newColumn = indexCol.concat(newColumn);
        }

        //  根据组件类型，判断是否带复选框  // 按逻辑看 这里永远不会走
        if (comType == 'hasCheckBox_child') {
            if (multiSelect && multiSelect.type === 'checkbox') {
                let parId = transferTable.selected.selectedData.childSelData[records.key];
                // 主表有状态 那么子表就不需要状态 saga
                let pValues = records.values || records;
                let pSaga_status = pValues && pValues.saga_status && pValues.saga_status.value === '1';

                let defaultColumns = [
                    {
                        label: '',
                        attrcode: 'checkbox',
                        itemtype: 'customer',
                        dataIndex: 'checkbox',
                        width: 40,
                        className: 'col-customer-checkbox',
                        fixed: 'left',
                        visible: true,
                        onCellClick: (record, e) => {
                            let ck = record.key;
                            let values = record.values || record;
                            let index = -1;
                            let bodyData = ((currentTable.bodyData.data || {})[records.key] || {}).rows || [];

                            bodyData.some((item, i) => {
                                let values = item.values || item;
                                if (values.key === ck) {
                                    index = i;
                                    return true;
                                }
                            });

                            // console.log(bodyData, index, 'xxxxx');

                            if (index == -1 || pSaga_status || (values && values.saga_status && values.saga_status.value === '1')) {
                                return;
                            }
                            onCheckboxChangeChild.call(this, '', record, index, records, indexs)
                        },
                        render: (text, record, index) => {
                            // Socket改造
                            //let { billpkname, billtype, errorViewType } = this.nativeSocketBillInfo;

                            let values = record.values || record;
                            return (values && values.saga_status && values.saga_status.value === '1') || pSaga_status ? (
                                <ErrorPop
                                    record={record}
                                />
                            ) : (
                                    <Checkbox
                                        className="table-checkbox"
                                        checked={!!(parId && parId[record.key])}
                                        onChange={onCheckboxChangeChild.bind(this, text, record, index, records, indexs)}
                                    />
                                );
                        },
                    },
                ];
                newColumn = defaultColumns.concat(newColumn);
            }
        } else if (comType == 'selectedList' && defaultOperateColumnHidden !== true) { // 部分情况下  用户期望移除子表操作列
            //已选列表增加操作列
            let oprColumn = {
                label: <span> {oprationLabel} </span>,
                attrcode: 'operate',
                itemtype: 'customer',
                dataIndex: 'operate',
                width: 100,
                //fixed: "right",
                visible: true,
                render: (text, record, index) => {
                    return (
                        <div
                            className="row-remove"
                            bbq="子表移除"
                            onClick={() => {
                                //移除子表
                                onSelectedItemRemove && onSelectedItemRemove(record);
                                onCheckboxChangeChild(text, record, index, records, index, true, false);
                            }}
                        >
                            {json['page-transferTable-0001'] || '移除'}{/*国际化处理 移除*/}
                        </div>
                    );
                },
            };
            newColumn = [...newColumn, oprColumn];
        }

        return newColumn;
    };

    //已选列表主子拉平移除
    const clearSelectedFullData = (text, record, index, bool) => {
        let selected = transferTable.selected;
        let selectedData = transferTable.selected.selectedData;
        let childId = this.state.transferTable[headTableId][headTableId].childId;
        let headId = record.headKey;//转单主表id
        let bodyId = record[childId].value;//转单子表id
        delete selectedData.childSelData[headId][bodyId];
        let childAll = selectedData.childSelData[headId];
        if (Object.keys(childAll).length === 0) {
            delete selectedData.childSelData[headId];
            delete selectedData.masterSelData[headId];
        }
        setAllSelVal('body', false, headId, bodyId); //多来源有全部页签需要单独统计已选数据
        selectedData.selAll = false;
        setMasterId(headId, false);
        this.state.transferTable[headTableId] = transferTable;
        let amountData = getAmount.call(this, transferTable);
        selected.bodyAmount = amountData.bodyAmount;
        selected.masterAmount = amountData.masterAmount;
        this.setState({ transferTable: this.state.transferTable });
    };

    //已选列表清空事件
    const handleClearAllSelected = () => {
        let allSelected = this.state.transferTable.allSelected;
        if (allSelected) {
            allSelected.masterCheckedId = []; //清空已选主表id
            allSelected.selectedData.selAll = false; //取消全选
            allSelected.selectedData.masterSelData = {};
            allSelected.selectedData.childSelData = {};
            allSelected.masterAmount = 0;
            allSelected.bodyAmount = 0;
            allSelected.selIds = {};
        }
        transferTable.selected.masterCheckedId = []; //清空已选主表id
        transferTable.selected.selectedData.selAll = false; //取消全选
        transferTable.selected.selectedData.masterSelData = {};
        transferTable.selected.selectedData.childSelData = {};
        transferTable.selected.masterAmount = 0;
        transferTable.selected.bodyAmount = 0;
        this.setState(
            { transferTable: this.state.transferTable },
            () => {
                onClearAll && onClearAll();
            },
        );
    };

    if (data && currentTable.firstTime) {
        currentTable.firstTime = false;
    }

    //  根据组件类型，判断是否带复选框
    let column = columns;
    let classByType = null;
    if (comType == 'hasCheckBox' || comType == 'hasCheckBox_child') {
        classByType = 'hasCheckBox';
    }

    /**
     * 陈顺风  还款单
     * @param {*} item 
     * @param {*} value 
     * @param {*} index 
     * @param {*} outerRecordKey 
     * @param {*Object} record  被编辑行的全部数据
     * @param {*} scale 精度
     */
    const editableJSX = (item, value, index, outerRecordKey, record, scale) => {
        switch (item.itemtype) {
            case 'number':
                return (
                    <NCNumber
                        value={value}
                        scale={scale}
                        autoFocus={true}
                        onChange={newValue => {
                            // 因为需要在onblur的时候传输入框初始值给业务组，所以需判断初始值是否被保存过，如果没有保存过，则将初始值保存
                            if (!this.myState.transferTableEditOldValueIsSaved) {
                                this.myState.transferTableEditOldValueIsSaved = true;
                                this.myState.transferTableEditOldValue = value;
                            }
                            // 实时更新数据， 以防鼠标滑动导致表格重新渲染的时候输入框的值还原回去
                            let transferTable = this.state.transferTable;
                            let itemValueObj = null;
                            if (outerRecordKey) {
                                itemValueObj = transferTable[headTableId][headTableId].bodyData.data[outerRecordKey].rows[index].values[item.attrcode];
                            } else {
                                itemValueObj = transferTable[headTableId][headTableId].outerData[index].values[item.attrcode];
                            }
                            itemValueObj.value = newValue;
                        }}
                        onBlur={newValue => {
                            // 在鼠标脱焦后 将输入框的值保存状态初始化为false，以待下次编辑的时候出发onChange使用
                            this.myState.transferTableEditOldValueIsSaved = false;
                            let transferTable = this.state.transferTable;
                            let itemValueObj = null;
                            if (outerRecordKey) {
                                itemValueObj = transferTable[headTableId][headTableId].bodyData.data[outerRecordKey].rows[index].values[item.attrcode];
                            } else {
                                itemValueObj = transferTable[headTableId][headTableId].outerData[index].values[item.attrcode];
                            }
                            let oldValue = this.myState.transferTableEditOldValue;
                            itemValueObj.isEdit = false;
                            itemValueObj.value = formatDot(newValue, scale);
                            record[item.attrcode].value = newValue;
                            if (transferTable &&
                                headTableId &&
                                transferTable[headTableId] &&
                                transferTable[headTableId].selected &&
                                transferTable[headTableId].selected.selectedData &&
                                transferTable[headTableId].selected.selectedData.childSelData &&
                                record &&
                                record.key &&
                                record.parentRowId &&
                                transferTable[headTableId].selected.selectedData.childSelData[record.parentRowId] &&
                                transferTable[headTableId].selected.selectedData.childSelData[record.parentRowId][record.key] &&
                                transferTable[headTableId].selected.selectedData.childSelData[record.parentRowId][record.key].data &&
                                item &&
                                item.attrcode &&
                                transferTable[headTableId].selected.selectedData.childSelData[record.parentRowId][record.key].data[item.attrcode]
                            ) {
                                transferTable[headTableId].selected.selectedData.childSelData[record.parentRowId][record.key].data[item.attrcode].value = newValue;
                            }
                            record[item.attrcode].isEdit = false;
                            if (typeof afterEvent === 'function') {
                                afterEvent(item.attrcode, newValue, record, index, oldValue);
                            }
                            this.setState(
                                { transferTable: transferTable },
                            );
                        }}
                    />
                );
                break;
            default:
                return <span>{value}</span>;
                break;
        }
    };

    // 'switch' 根据value显示 ’是‘或 ’否‘
    const switchValue = (value, json) => {
        if (value) {
            return (json && json['page-transferTable-0020']) || '是';
        } else {
            return (json && json['page-transferTable-0021']) || '否';
        }
    };

    // 用来处理number类型的值的精度的 data是值， scal为精度
    const getScaleData = (data, scal) => {
        let value = String(data).replace(/,/g, '');
        let scale = Number(scal);
        if (value) {
            let len = scale >= 0 ? value.split('.')[0].length + scale + 1 : value.split('.')[0].length;
            if (value.indexOf('.') !== -1) {
                let arr = value.split('.');
                if (!Number.isNaN(scale) && scale !== -1) {
                    if (scale === 0) {
                        value = String(value).split('.')[0];
                    } else {
                        value = String(value).padEnd(len, '0');
                    }
                }
            } else {
                if (!Number.isNaN(scale) && scale !== -1) {
                    if (scale === 0) {
                        value = String(value);
                    } else {
                        let str = value + '.';
                        value = str.padEnd(Number(len), '0');
                    }
                }
            }
        } else {
            return 0;
        }
        return commafy(value);
    };

    // 获取 表格的排序状态
    const getColumnsSortStatus = (column, outerRecordKey) => {
        let transferTable = this.state.transferTable,
            currentTransferTable = transferTable[headTableId],
            currentTable = currentTransferTable[headTableId],
            selectedTable = currentTransferTable.selected,
            allSelectedTable = transferTable.allSelected,
            sortParam = [],
            res = {
                order: 'flatscend',
                orderNum: '',
            };

        // 判断表格类型 判断表格状态
        if (selectedTable.selectedShow || allSelectedTable.selectedShow) {
            if (currentTable.showFullTable || allSelectedTable.showFullTable) {
                sortParam = selectedTable.fullSortInfoObj || currentTransferTable.sortInfoObj || [];
            } else if (outerRecordKey) {
                sortParam = (selectedTable[outerRecordKey] && selectedTable[outerRecordKey].childSortInfoObj) || [];
            } else {
                sortParam = selectedTable.mainSortInfoObj || currentTransferTable.sortInfoObj || [];
            }
        } else {
            if (currentTable.showFullTable || allSelectedTable.showFullTable) {
                sortParam = currentTable.fullSortInfoObj || currentTransferTable.sortInfoObj || [];
            } else if (outerRecordKey) {
                sortParam = (currentTable[outerRecordKey] && currentTable[outerRecordKey].childSortInfoObj) || [];
            } else {
                sortParam = currentTable.mainSortInfoObj || currentTransferTable.sortInfoObj || [];
            }
        }

        sortParam.forEach(item => {
            if (column.attrcode === item.field) {
                res.order = item.order;
                res.orderNum = item.orderNum;
                res.field = item.field;
                res.isSort = true;
            }
        });
        // res.isSort && console.log(res, sortParam);
        return res;
    };

    // 处理模板 在模板数据上加checkbox、序号或者操作列
    /*
    * column： 原模板数据
    * outerRecordKey： 主表 行数据的的主键
    * expander： 最后一行是否要固定
    * json： 多语资源
    *
    * */
    const createNewCol = ({ column, outerRecordKey, bool, expander, columnType, json }) => {

        let result = [];
        let pageId = currentPageId || (this.state && this.state.meta.pageid);
        if (expander && column.length && column[column.length - 1].attrcode === 'operate') {
            column[column.length - 1].fixed = 'right';
        }
        column.map(item => {
            let width = item.width;
            if (!width) {
                width = 120;
            }
            let orderStatus = getColumnsSortStatus(item, outerRecordKey);
            if (orderStatus.order) {
                item.order = orderStatus.order;
                item.orderNum = orderStatus.orderNum;
            }
            let render = null;
            if (item.itemtype !== 'customer' && item.visible && !item.render) {
                item.dataIndex = item.attrcode;
                render = (text, record, index) => {
                    let hyperlinkflag = item.hyperlinkflag;
                    if (record[item.attrcode]) {
                        let display = record[item.attrcode].display;
                        let value = record[item.attrcode].value;
                        let recordScale = record[item.attrcode].scale ? record[item.attrcode].scale : item.scale ? item.scale : 0;
                        let dom = '';
                        if (display || display === 0) {
                            dom = display;
                        } else {
                            dom = value;
                        }
                        if (isTimeType(item.itemtype)) { //时区类型的控件
                            if (item.itemtype == 'datetimepicker') {
                                dom = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                                dom = formatDatetime(dom, 'datetime');
                            } else if (item.itemtype == 'timepicker') {
                                dom = value;
                                dom = formatDatetime(dom, 'time');
                            } else {
                                dom = changeTime(value);
                                dom = formatDatetime(dom, 'date');
                            }
                        }

                        // 多语处理
                        if (item.itemtype === "residtxt") {
                            let langCode = getLangCode();
                            let loginLang = (item.languageMeta || []).filter(i => i.languageCode == langCode);
                            if (loginLang.length > 0) {
                                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                                if (
                                    record[item.attrcode + loginLang[0].index] &&
                                    record[item.attrcode + loginLang[0].index].value
                                ) {
                                    dom = record[item.attrcode + loginLang[0].index].value;
                                }
                            }
                        }

                        if (item.itemtype == 'switch') {
                            dom = switchValue(value, json);
                        } else if (item.itemtype == 'checkbox_switch') {
                            dom = checkboxSwitchValue(value, editStatus, json);
                        }
                        let isEdit = record[item.attrcode].isEdit;
                        let disabled = item.disabled;
                        let realScale = recordScale;
                        if (recordScale === '-1' && dom) {
                            let arr = String(dom).split('.');
                            if (arr.length && arr[1]) realScale = arr[1].length;
                        }
                        if (editStatus) {
                            let domShow = dom;
                            if (item.itemtype === 'number') {
                                let val = formatDot(dom, recordScale);
                                domShow = formatAcuracy(val, realScale);
                                domShow = formatNumber(domShow);
                            }

                            return isEdit && !disabled ? (
                                <div
                                    fieldid={getSysFieldid(item.attrcode)}
                                    className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : ''}`}
                                >
                                    {editableJSX(item, dom, index, outerRecordKey, record, realScale)}
                                </div>
                            ) : (
                                    <span
                                        className={
                                            classnames({
                                                'number-td-span-wrapper editor-td-span-wrapper': item.itemtype === 'number',
                                                'single-line-and-ellipsis': this.state.transferTable[headTableId]['isEllipsis'],
                                                'three-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'],
                                                'mult-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'] && this.state.transferTable[headTableId]['bodyExpandAllInRow'],
                                            })
                                        }
                                        fieldid={getSysFieldid(item.attrcode)}
                                        // 会导致行高不是标准30
                                        // style={{ display: 'inline-block', width: '100%', height: '28px', lineHeight: '28px' }}
                                        onClick={() => {
                                            if (editableItem && !editableItem.includes(item.attrcode)) {
                                                return;
                                            }
                                            let transferTable = this.state.transferTable;
                                            if (outerRecordKey) {
                                                transferTable[headTableId][headTableId].bodyData.data[outerRecordKey].rows[
                                                    index
                                                ].values[item.attrcode].isEdit = true;
                                            } else {
                                                transferTable[headTableId][headTableId].outerData[index].values[
                                                    item.attrcode
                                                ].isEdit = true;
                                            }
                                            this.setState({ transferTable });
                                        }}
                                    >
                                        <EllipsisToTooltip overlay={domShow} >
                                            <span>
                                                {domShow}
                                            </span>
                                        </EllipsisToTooltip>
                                    </span>
                                );
                        } else {
                            if (item.itemtype === 'number') {
                                dom = formatDot(dom, recordScale);
                                dom = formatAcuracy(dom, realScale);
                                dom = formatNumber(dom);
                            }
                            return (
                                <span
                                    className={
                                        classnames({
                                            'number-td-span-wrapper': item.itemtype === 'number',
                                            'single-line-and-ellipsis': this.state.transferTable[headTableId]['isEllipsis'],
                                            'three-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'],
                                            'mult-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'] && this.state.transferTable[headTableId]['bodyExpandAllInRow'],
                                        })
                                    }
                                    fieldid={getSysFieldid(item.attrcode)}
                                >
                                    <EllipsisToTooltip overlay={dom}>
                                        {hyperlinkflag && dom ? (
                                            <a
                                                href="javascript:;"
                                                className={'hyper-link'}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    let moduleId = columnType === 'child' ? bodyTableId : columnType === 'full' ? fullTableId : headTableId;

                                                    let metaAreaPageId = pageId;
                                                    if (this.state && this.state.meta && this.state.meta[moduleId] && this.state.meta[moduleId].pageid) {
                                                        metaAreaPageId = this.state.meta[moduleId].pageid;
                                                    }
                                                    linkTo(metaAreaPageId, moduleId, item.attrcode, value || dom);
                                                }}
                                            >
                                                {dom}
                                            </a>
                                        ) : (<span>{dom}&nbsp;</span>)}

                                    </EllipsisToTooltip>
                                </span>
                            );
                        }
                    }
                };
            } else {
                if (item.itemtype === 'customer') {
                    render = item.render;
                } else {
                    render = (text, record, index) => {
                        return (
                            <EllipsisToTooltip
                                className="tooltip-word-color"
                                overlay={item.render.call(
                                    null,
                                    text,
                                    record,
                                    index,
                                )}
                                noEllipsis
                            >
                                <div
                                    className={classnames(
                                        'col-customer-render-unit',
                                        {
                                            'single-line-and-ellipsis': this.state.transferTable[headTableId]['isEllipsis'],
                                            'three-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'],
                                            'mult-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'] && this.state.transferTable[headTableId]['bodyExpandAllInRow'],
                                        })
                                    }>
                                    {item.render.call(null, text, record, index)}
                                </div>
                            </EllipsisToTooltip>
                        );
                    };
                }
            }
            if (item.visible) {
                result.push({
                    ...item,
                    render,
                    width,
                    key: item.attrcode,
                    dataIndex: item.dataIndex || item.attrcode,
                    title:
                        <span
                            className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : 'text-td-span-wrapper'}`}
                            style={{ color: defaultColor.includes(item.color) ? '' : item.color }}
                            fieldid={getSysFieldid(item.attrcode)}
                        >
                            {item.label}
                        </span>,
                });
            }
        });
        return result;
    };

    // 设置数据
    const createNewData = (data, columns) => {
        let datas = [];
        data.map((val, index) => {
            val = val.values || val;
            for (let item of Object.keys(val)) {
                let itemData = val[item];
                if (itemData && typeof itemData === 'object' && itemData.hasOwnProperty('scale')) {
                    val[item].value = formatDot(itemData.value, val[item].scale);
                }
            }
            // datas.push(JSON.parse(JSON.stringify(val)));
            datas.push(val);
        });
        return datas;
    };

    // 保留合计的最长小数位精度 nowVal: 当前要加的值， totalVal： 之前合计好的值
    const getTheLongestPrecision = (nowVal, totalVal) => {
        if (nowVal === '' || nowVal === null || nowVal === 'null') {
            return totalVal;
        }
        let nowValArr = String(nowVal).split('.');
        let totalValArr = String(totalVal).split('.');
        let nowValLen = nowValArr.length >= 2 ? String(nowValArr[1]).length : 0;
        let totalValLen = totalValArr.length >= 2 ? String(totalValArr[1]).length : 0;
        let addVal = Number.parseFloat(totalVal);
        if (typeof Number(nowVal) === 'number' && !Number.isNaN(Number(nowVal))) {
            nowVal = thounsandTransfer(nowVal, 'number');
            totalVal = thounsandTransfer(totalVal, 'number');
            addVal = Number.parseFloat(nowVal) + Number.parseFloat(Number(totalVal));
        } else {
            return totalVal;
        }

        let addValArr = String(addVal).split('.');
        let addValLen = addValArr.length >= 2 ? String(addValArr[1]).length : 0;
        if (totalValLen >= nowValLen) {
            let zeroO = '';
            for (let i = 0; i < totalValLen - addValLen; i++) {
                zeroO += '0';
            }
            let bool = String(addVal).includes('.') ? `${addVal}${zeroO}` : `${addVal}.${zeroO}`;
            return Number(bool).toFixed(totalValLen);
        } else {
            let zeroO = '';
            for (let i = 0; i < nowValLen - addValLen; i++) {
                zeroO += '0';
            }
            let bool = String(addVal).includes('.') ? `${addVal}${zeroO}` : `${addVal}.${zeroO}`;
            return Number(bool).toFixed(nowValLen);
        }
    };

    /*
    * 创建合计行表格的模板
    * column： 模板数据
    * showIndex： 是否显示序号
    * json： 多语json
    * */
    const createSelectColumns = (column, showIndex, json, isChild) => {
        let result = [];
        result.push({
            label: <span />,
            attrcode: 'checkbox',
            itemtype: 'customer',
            fixed: 'left',
            dataIndex: 'checkbox',
            visible: true,
            width: isChild ? 70 : 80,
            className: 'col-customer-checkbox col-customer-total',
            render: (text, record, index) => {
                return <span title={json['page-transferTable-0010'] || '合计-1'}>{json['page-transferTable-0010'] || '合计-1'}</span>;
            },
        });
        column.map(item => {
            let width = item.width;
            if (!width) {
                width = 120;
            }
            let render = null;
            if (item.itemtype !== 'customer' && item.visible && !item.render) {
                render = (text, record, index) => {
                    if (record[item.attrcode]) {
                        let display = record[item.attrcode].display;
                        let value = record[item.attrcode].value;
                        let dom = '';
                        if (display || display === 0) {
                            dom = display;
                        } else {
                            dom = value;
                        }
                        if (isTimeType(item.itemtype)) {
                            if (item.itemtype == 'datetimepicker') {
                                dom = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                                dom = formatDatetime(dom, 'datetime');
                            } else if (item.itemtype == 'timepicker') {
                                dom = value;
                                dom = formatDatetime(dom, 'time');
                            } else {
                                dom = changeTime(value);
                                dom = formatDatetime(dom, 'date');
                            }
                        }

                        // 多语处理
                        if (item.itemtype === "residtxt") {
                            let langCode = getLangCode();
                            let loginLang = (item.languageMeta || []).filter(i => i.languageCode == langCode);
                            if (loginLang.length > 0) {
                                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                                if (
                                    record[item.attrcode + loginLang[0].index] &&
                                    record[item.attrcode + loginLang[0].index].value
                                ) {
                                    dom = record[item.attrcode + loginLang[0].index].value;
                                }
                            }
                        }

                        if (item.itemtype === 'number') {
                            dom = formatNumber(dom);
                        }
                        return (
                            <span className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : ''}`}>
                                <EllipsisToTooltip overlay={dom}>
                                    <span
                                        className={classnames({
                                            'single-line-and-ellipsis': this.state.transferTable[headTableId]['isEllipsis'],
                                            'three-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'],
                                            'mult-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'] && this.state.transferTable[headTableId]['bodyExpandAllInRow'],
                                        })}>
                                        {dom}&nbsp;</span>
                                </EllipsisToTooltip>
                            </span>
                        );

                    }
                };
            } else {
                if (item.itemtype === 'customer') {
                    render = item.render;
                } else {
                    render = (text, record, index) => {
                        return (
                            <EllipsisToTooltip
                                className="tooltip-word-color"
                                inverse={true}
                                overlay={item.render.call(
                                    null,
                                    text,
                                    record,
                                    index,
                                )}
                                noEllipsis
                            >
                                <div
                                    className={classnames(
                                        'col-customer-render-unit',
                                        {
                                            'single-line-and-ellipsis': this.state.transferTable[headTableId]['isEllipsis'],
                                            'three-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'],
                                            'mult-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'] && this.state.transferTable[headTableId]['bodyExpandAllInRow'],
                                        })
                                    }>
                                    {item.render.call(null, text, record, index)}
                                </div>
                            </EllipsisToTooltip>
                        );
                    };
                }
            }
            if (item.visible && item.attrcode !== 'checkbox' && item.attrcode !== 'numberindex') {
                result.push({ ...item, render, width, key: item.attrcode, title: item.label });
            }
        });
        if (result[1] && !showIndex) {
            // result[1].width =  result[1].width -20
        }
        if (defaultOperateColumnHidden !== true) {
            result.push({
                label: <span>{json['page-transferTable-0006'] || '操作-2'}</span>, /*国际化处理 操作*/
                attrcode: 'operate',
                itemtype: 'customer',
                fixed: 'right',
                dataIndex: 'operate',
                visible: true,
                width: 100,
                render: (text, record, index) => {
                    return <span>&nbsp;</span>;
                },
            });
        }
        return result;
    };

    /*
    * 计算合计数据
    * data 选中的整体数据
    * column： 模板数据
    * record： 展开行的主表数据
    * */
    const processingTotalDataChild = (data, column, record) => {
        let key = record.key;
        let childData = data[key];
        let outerData = {};
        let totalKey = [];
        let totalScale = {};

        for (let item of column) {
            outerData[item['attrcode']] = { value: '', display: '' };
            if (item.istotal && item.itemtype === 'number') {
                totalKey.push(item.attrcode);
                totalScale[item.attrcode] = item.scale;
            }
        }
        if (childData) {
            for (let items of Object.keys(childData)) {
                let values = childData[items].data.values || childData[items].data;
                for (let i of totalKey) {
                    if (values[i] && typeof values[i] === 'object') {
                        let scale = values[i].scale ? values[i].scale : totalScale[i];
                        let value = values[i] ? String(values[i].value) ? String(values[i].value).replace(/,/g, '') : 0 : 0;
                        if (!outerData[i].value) {
                            outerData[i].scale = scale;
                            outerData[i].value = getScaleData(value, scale);
                        } else {
                            let totalData = sum(Number.parseFloat(value), Number.parseFloat(String(outerData[i].value).replace(/,/g, '')));
                            outerData[i].scale = scale > outerData[i].scale ? scale : outerData[i].scale;
                            outerData[i].value = getScaleData(String(totalData), outerData[i].scale);

                        }
                    }

                }
            }
        }
        return [outerData];
    };

    //表格展开显示的内容
    //渲染子表表格数据及复选框
    const expandedRowRender = (record, index, comType, json) => {
        let newColumn = bodyColumn;
        let { showChildIndex, sortType } = this.props;
        let isTotal = this.myState.status.bodyTotalStatusOfTransferTable;
        let curKey = record.headKey || record.key;
        let TotalDataChild = processingTotalDataChild.call(
            this,
            transferTable.selected.selectedData.childSelData,
            newColumn,
            record,
        );
        if (newColumn instanceof Array && newColumn.length > 0) {
            newColumn = renderColumnsMultiSelectChild.call(this, newColumn, record, index, comType, json);
            if ((currentTable.bodyData && Object.keys(currentTable.bodyData).length && currentTable.bodyData.data.hasOwnProperty(curKey)) || (
                transferTable && transferTable.selected && transferTable.selected.selectedData && comType === 'selectedList'
            )) {
                let data = null,
                    expandTableHeight = 0,
                    $thisDom = ReactDOM.findDOMNode(this);
                let expandTableBodyHeight = 0;//展开子表表体的高度
                //已选列表的子表移除时需要删除行数据
                if (comType === 'selectedList') {
                    data = createNewData(
                        Object.values(transferTable.selected.selectedData.childSelData[curKey] || {}).map(
                            item => item.data,
                        ),
                    );
                    $thisDom = $thisDom.querySelector('.selected-list-wrapper') || $thisDom;
                } else {
                    data = createNewData(
                        currentTable.bodyData.data[record.key].rows || currentTable.bodyData.data[record.key],
                    );
                }
                expandTableHeight = getExpandTableHeight(data, isTotal, $thisDom);
                expandTableBodyHeight = getExpandTableHeight(data, isTotal, $thisDom, true);
                let width = getTableWidth('selected-list');
                return (
                    <div className="transfertable-expand-wraper" style={{ height: `${expandTableHeight}px`, width: '100%' }}>
                        <ComplexTable
                            columns={createNewCol({ column: newColumn, outerRecordKey: curKey, expander: true, columnType: 'child', json })}
                            data={data}
                            isTotal={isTotal}
                            isDrag={isDrag}
                            lazyload={true}
                            totalBodyHeight={30}
                            totalData={TotalDataChild}
                            totalColumns={createSelectColumns.call(this, bodyColumn, showChildIndex, json, true)}
                            scroll={{ x: true, y: expandTableBodyHeight || 300 }}
                            sort={sortType}
                            updateColumnHandle={updateColumnAfterSetting.bind(this)}
                            bodyStyle={{ minHeight: '30px' }}
                            tableType='transfer'
                            //折行回调
                            foldingRowChange={isEllipsis => {
                                this.state.transferTable[headTableId]['isEllipsis'] = isEllipsis
                                this.setState({ transferTable: this.state.transferTable })
                            }}
                            bodyDisplayInRow={this.state.transferTable[headTableId]['isEllipsis']}
                            colsSettingParam={getcolsSettingParam.call(this, this.state.meta, bodyTableId)}
                            cancelCustomRightMenu={cancelCustomRightMenu}
                        />
                    </div>

                );
            }
        }
        console.log('没有找到子表数据呀！！');
        return <div />;
    };

    //设置已选列表columns
    /*
    * json: 多语
    * isFull：判断已选列表的表格是否是主子拉平表
    * */
    const setSelectedColumns = (columns, json = {}, isFull) => {
        let result = [];
        let bool = currentTable.showFullTable || tableType === 'simple';
        let label = json['page-transferTable-0006'] ? json['page-transferTable-0006'] : '操作-3';
        /*国际化处理 操作*/
        let operateColumn = bool ? {
            title: <span>{label}</span>,
            attrcode: 'operate',
            itemtype: 'customer',
            dataIndex: 'operate',
            width: 100,
            fixed: 'right',
            visible: true,
            render: (text, record, index) => {
                return (
                    <div
                        className="row-remove"
                        onClick={() => {
                            //移除主表
                            let selData = this.state.transferTable[headTableId].selected.selectedData;
                            let tableType = this.state.transferTable[headTableId][headTableId].tableType;
                            let childSelData = selData.childSelData[record.key] || selData.childSelData[record.headKey];
                            let bodys = [];
                            if (childSelData) {
                                for (let headKey of Object.keys(childSelData)) {
                                    // liuyjv修改解决返回数据不一致bug
                                    bodys.push(childSelData[headKey].data.values ? childSelData[headKey].data.values : childSelData[headKey].data);
                                }
                            }
                            onSelectedItemRemove && onSelectedItemRemove(record, isFull ? [] : bodys);
                            !currentTable.showFullTable
                                ? onCheckboxChange(text, record, index, true)
                                : clearSelectedFullData(text, record, index, true);
                        }}
                    >
                        {json['page-transferTable-0001'] || '移除'}{/*国际化处理 移除*/}
                    </div>
                );
            },
        } : {
                title: label,
                attrcode: 'operate',
                itemtype: 'customer',
                dataIndex: 'operate',
                width: 100,
                visible: true,
                render: (text, record, index) => {
                    return (
                        <div
                            className="row-remove"
                            // href="javascript:viod(0);"  // a 标签
                            onClick={() => {
                                //移除主表
                                let selData = this.state.transferTable[headTableId].selected.selectedData;
                                let tableType = this.state.transferTable[headTableId][headTableId].tableType;
                                let childSelData = selData.childSelData[record.key] || selData.childSelData[record.headKey];
                                let bodys = [];
                                if (childSelData) {
                                    for (let headKey of Object.keys(childSelData)) {
                                        // liuyjv修改解决返回数据不一致bug
                                        bodys.push(childSelData[headKey].data.values ? childSelData[headKey].data.values : childSelData[headKey].data);
                                    }
                                }
                                onSelectedItemRemove && onSelectedItemRemove(record, bodys);
                                !currentTable.showFullTable
                                    ? onCheckboxChange(text, record, index, true)
                                    : clearSelectedFullData(text, record, index, true);
                            }}
                        >
                            {json['page-transferTable-0001'] || '移除'}{/*国际化处理 移除*/}
                        </div>
                    );
                },
            };
        columns &&
            columns.map(item => {
                if (item.attrcode !== 'checkbox' && item.label !== label) {
                    result.push(item);
                }
            });
        return [...result, operateColumn];
    };

    //统计已选列表数量
    const getSelectedAmount = () => {
        let transferTable = this.state.transferTable;
        let bodyAmount = 0,
            masterAmount = 0;
        for (let item of Object.keys(transferTable)) {
            if (item !== 'allSelected') {
                let selected = transferTable[item].selected;
                masterAmount += selected.masterAmount ? Number(selected.masterAmount) : 0;
                bodyAmount += selected.bodyAmount ? Number(selected.bodyAmount) : 0;
            }
        }
        return { masterAmount, bodyAmount };
    };

    //获取已选列表主表数据
    const getMasterData = data => {
        let masterSelData = Object.values(data.masterSelData),
            selected = transferTable.selected,
            mainSortInfoObj = selected.mainSortInfoObj;
        masterSelData = masterSelData.map(item => item.data);

        return masterSelData;
    };

    //获取已选列表主子拉平数据
    const getSelectedFullData = data => {
        let { masterSelData, childSelData } = data;
        let result = [];
        for (let key of Object.keys(childSelData)) {
            // 存在 没有子表元素的主表数据
            if (hasObjProperty(childSelData[key])) {
                Object.values(childSelData[key]).forEach(item => {
                    // let childVal = JSON.parse(JSON.stringify(item.data.values || item.data || {}));
                    // let headData = JSON.parse(JSON.stringify(masterSelData[key].data || {}));
                    // 为了万行
                    let childVal = Object.assign({}, item.data.values || item.data || {});
                    let headData = Object.assign({}, masterSelData[key].data || {});

                    childVal.headKey = headData.key || headData.headKey;
                    // delete headData.headKey;
                    // delete headData.key;
                    let newData = Object.assign({}, childVal, headData); //合并后的主子拉平数据
                    if (newData.headKey) newData.headKey = key;
                    newData.key = childVal.key;
                    result.push(newData);
                });
            } else {
                // 没有就取主表数据
                // let newData = JSON.parse(JSON.stringify(masterSelData[key].data));
                // 为了万行
                let newData = masterSelData[key].data;
                newData.headKey = key;
                newData.key = key;
                result.push(newData);
            }
        }
        return result;
    };

    //点击生成转单去下游转单的按钮
    const handleGenerateClick = () => {
        let selIds = [];
        let selId = [];
        if (!isMultiChild) { // 转单不是多来源的子
            //获取已选列表主子表id
            let masterData = transferTable.selected.selectedData.masterSelData;
            let childSelData = transferTable.selected.selectedData.childSelData;
            if (masterData) {
                for (let key of Object.keys(masterData)) {
                    let hValues = masterData[key].data;
                    if (hValues.saga_status && hValues.saga_status.value === '1') {
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                    let bodys = [];
                    let head = {};
                    if (childSelData) {
                        let childData = transferTable.selected.selectedData.childSelData[key];
                        if (childData) {
                            for (let id of Object.keys(childData)) {
                                let values = childData[id].data.values || childData[id].data;
                                if (values.saga_status && values.saga_status.value === '1') {
                                    // eslint-disable-next-line no-continue
                                    continue;
                                }
                                let itemsJson = {};
                                if (cacheItem) {
                                    cacheItem.forEach(item => {
                                        let itemValue = values[item];
                                        if (itemValue) { //选中的子表数据里有要缓存的字段，
                                            itemsJson[item] = itemValue;
                                        } else { //选中的子表数据里有要缓存的字段就从主表数据里找
                                            let masterSelData = transferTable.selected.selectedData.masterSelData[key];
                                            let masterSelDataVal = masterSelData.data.values || masterSelData.data;
                                            let masterItemValue = masterSelDataVal[item];
                                            masterItemValue && (itemsJson[item] = masterItemValue);
                                        }
                                    });
                                }
                                if (generateKeys && generateKeys.body && generateKeys.body.length > 0) {
                                    let bodyKeyVal = {};
                                    for (let val of generateKeys.body) {
                                        bodyKeyVal[val] = values[val] && values[val].value;
                                    }
                                    bodys.push({
                                        bodyKeyVal,
                                        ...itemsJson,
                                    });
                                } else {
                                    bodys.push({
                                        pk: id,
                                        ts: values.ts && values.ts.value,
                                        ...itemsJson,
                                    });
                                }
                            }
                        }
                    }
                    if (generateKeys && generateKeys.head && generateKeys.head.length > 0) {
                        for (let val of generateKeys.head) {
                            head[val] = masterData[key].data[val] && masterData[key].data[val].value;
                        }
                    } else {
                        // 兼容一下特殊情况的判断
                        let headTs = masterData[key].ts ? masterData[key].ts : (masterData[key].data && masterData[key].data.ts && masterData[key].data.ts.value);
                        headTs = masterData[key].data.headTs ? masterData[key].data.headTs.value : headTs;
                        head = {
                            pk: key,
                            ts: headTs,
                        };
                    }
                    let itemsJson = {};
                    if (cacheItem) {
                        cacheItem.forEach(item => {
                            // if(childSelData && childSelData[key] && childSelData[key].data && childSelData[key].data[item]){
                            //     let itemValue = childSelData[key].data[item];
                            //     itemValue && (itemsJson[item] = itemValue);
                            // }

                            let itemValue = masterData[key].data[item];
                            itemValue && (itemsJson[item] = itemValue);
                        });
                    }
                    selIds.push({
                        head,
                        bodys,
                        ...itemsJson,
                    });
                }
            }
            ViewModel.setData('transferIds', selIds);
            this.state.transferTable[headTableId] = transferTable;
        } else { // 转单是多来源的子
            let allTranferTableData = this.state.transferTable;
            let selfMasterData = transferTable.selected.selectedData.masterSelData;
            let selfChildData = transferTable.selected.selectedData.childSelData;

            for (let ID of Object.keys(allTranferTableData)) {
                if (ID === 'allSelected') {
                    let selected = allTranferTableData.allSelected;
                    let masterData = JSON.parse(JSON.stringify(selected.selectedData.masterSelData));
                    let childData = JSON.parse(JSON.stringify(selected.selectedData.childSelData));
                    let bodys = [];
                    let selfBodys = [];
                    for (let key of Object.keys(masterData)) {
                        let hValues = masterData[key].data;
                        if (hValues.saga_status && hValues.saga_status.value === '1') {
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                        if (childData[key]) {
                            for (let id of Object.keys(childData[key])) {
                                let values = childData[key][id].data.values || childData[key][id].data;
                                if (values.saga_status && values.saga_status.value === '1') {
                                    // eslint-disable-next-line no-continue
                                    continue;
                                }
                                bodyPkIds &&
                                    bodyPkIds.map(pk => {
                                        let cData = childData[key][id].data.values || childData[key][id].data;
                                        if (cData.hasOwnProperty(pk)) {
                                            bodys.push({
                                                [pk]: id,
                                                pk: id,
                                                ts: values.ts && values.ts.value,
                                            });
                                        }
                                    });
                            }
                        }
                        if (selfChildData[key]) {
                            for (let id of Object.keys(selfChildData[key])) {
                                let values = selfChildData[key][id].data.values || selfChildData[key][id].data;
                                if (values.saga_status && values.saga_status.value === '1') {
                                    // eslint-disable-next-line no-continue
                                    continue;
                                }
                                bodyPkIds &&
                                    bodyPkIds.map(pk => {
                                        let cData = selfChildData[key][id].data.values || selfChildData[key][id].data;
                                        if (cData.hasOwnProperty(pk)) {
                                            selfBodys.push({
                                                [pk]: id,
                                                pk: id,
                                                ts: values.ts && values.ts.value,
                                            });
                                        }
                                    });
                            }
                        }
                        headPkIds &&
                            headPkIds.map(pk => {
                                if (masterData[key].data.hasOwnProperty(pk)) {
                                    selIds.push({
                                        head: {
                                            [pk]: key,
                                            pk: key,
                                            ts: masterData[key].data.ts && masterData[key].data.ts.value,
                                        },
                                        bodys,
                                    });
                                }

                                if (selfMasterData[key] && selfMasterData[key].data.hasOwnProperty(pk)) {
                                    selId.push({
                                        head: {
                                            [pk]: key,
                                            pk: key,
                                            ts: selfMasterData[key].data.ts && selfMasterData[key].data.ts.value,
                                        },
                                        bodys: selfBodys,
                                    });
                                }
                            });
                        bodys = [];
                    }
                }
            }
            ViewModel.setData('multiTransferIds', selIds);
            ViewModel.setData('transferIds', selId);
        }
        if (typeof onTransferBtnClick === 'function') {
            // 用来创建下游转单卡片的时候判断是否创建ref
            ViewModel.setData('isCreateTransferList', true);
            onTransferBtnClick(selIds);
        }
    };

    //点击切换视图
    const handleChangeView = () => {
        if (typeof onChangeViewClick === 'function') {
            onChangeViewClick();
        }
    };

    //将主子表数据拼成主子拉平数据
    const createFullData = data => {
        let { outerData, bodyData, fullSortData } = data;
        let result = [];
        let headData = {};
        const CurrentFullDataRule = fullDataRule || 'mainbody';
        if (fullSortData) {
            return fullSortData;
        }
        if (bodyData && bodyData.data) {
            // 为了排序  我豁出去了 以外层数据为标准  可以排序数据
            if (Array.isArray(outerData)) {
                outerData.forEach(oitem => {
                    let key = oitem.rowId;
                    // headData[key] = JSON.parse(JSON.stringify(oitem.values));
                    // 不进行拷贝了  不然大数据内存增长较大
                    headData[key] = oitem.values;
                    //子表有数据时，拉平
                    if (bodyData.data[key] && bodyData.data[key].rows && bodyData.data[key].rows.length) {
                        bodyData.data[key].rows.map(item => {
                            let newData = Object.assign(
                                // JSON.parse(JSON.stringify(item.values)),
                                // 不进行拷贝了  不然大数据内存增长较大
                                // JSON.parse(JSON.stringify(item.values)),
                                // JSON.parse(JSON.stringify(headData[key])),
                                {},
                                item.values,
                                headData[key],
                            ); //合并后的主子拉平数据

                            if (Object.prototype.toString.call(CurrentFullDataRule) == '[object Function]') {
                                // console.log(CurrentFullDataRule);
                                newData = CurrentFullDataRule(headData[key], item.values) || {};
                            }
                            if (CurrentFullDataRule === 'bodymain') {
                                // console.log(CurrentFullDataRule);
                                newData = Object.assign(
                                    {},
                                    headData[key],
                                    item.values,
                                );
                            }
                            if (CurrentFullDataRule === 'body') {
                                // console.log(CurrentFullDataRule);
                                newData = Object.assign(
                                    {},
                                    item.values,
                                );
                                newData.headKey = key;
                            }
                            if (CurrentFullDataRule === 'main') {
                                // console.log(CurrentFullDataRule);
                                newData = Object.assign(
                                    {},
                                    headData[key],
                                );
                            }

                            if (!newData.headKey) newData.headKey = key;
                            newData.key = item.values.key;
                            newData.headTs = headData[key]['ts'];
                            result.push(newData);
                        });
                        //子表无数据时，只去主表数据作为拉平数据
                    } else {
                        let newData = headData[key];
                        newData.headKey = key;
                        newData.key = key;
                        newData.headTs = headData[key]['ts'];
                        result.push(newData);
                    }
                });
            }
        }
        for (let val of result) {
            for (let item of Object.keys(val)) {
                let itemData = val[item];
                if (itemData && itemData.hasOwnProperty('scale')) {
                    val[item].value = formatDot(itemData.value, val[item].scale);
                }
            }
        }
        return result;
    };

    /* 主子表展开收起按钮的回调
     * @param {*Array} expandedPks 当前转单展开的所有主表的pk
     */
    const onExpandedRowsChange = expandedPks => {
        // console.log('展开收起回调',expandedPks)
        if (typeof onExpandedChange === 'function') onExpandedChange(expandedPks);
    };
    let selectedAmount = getSelectedAmount(); //已选数据数量
    // //==================转单部分========================
    return (
        <CreateTranferTable
            _this={this}
            // bodyAmount={bodyAmount}
            bodyColumn={bodyColumn}
            classByType={classByType}
            column={column}
            columns={columns}
            comType={comType}
            componentInitFinished={componentInitFinished}
            createNewCol={createNewCol}
            createFullData={createFullData}
            createNewData={createNewData}
            currentTable={currentTable}
            customAreaOfSelectedHead={customAreaOfSelectedHead}
            renderColumnsMultiSelect={renderColumnsMultiSelect}
            dataSource={dataSource}
            defaultProps={defaultProps}
            expandedRowRender={expandedRowRender}
            fullColumns={fullColumns}
            getMasterData={getMasterData}
            getSelectedFullData={getSelectedFullData}
            getScaleData={getScaleData}
            getTheLongestPrecision={getTheLongestPrecision}
            handleClearAllSelected={handleClearAllSelected}
            handleChangeView={handleChangeView}
            showChangeViewBtn={showChangeViewBtn}
            handleGenerateClick={handleGenerateClick}
            isTotal={this.myState.status.totalStatusOfTransferTable} //是否显示合计行 具体用到拿个要细查代码决定
            isHeadTotal={this.myState.status.headTotalStatusOfTransferTable}//否显示合计行 具体用到拿个要细查代码决定 主表是否合计
            isBodyTotal={this.myState.status.bodyTotalStatusOfTransferTable}//否显示合计行 具体用到拿个要细查代码决定 子表是否合计
            isFullTotal={this.myState.status.fullTotalStatusOfTransferTable}//否显示合计行 具体用到拿个要细查代码决定 拉平表是否合计
            isMultiChild={isMultiChild}
            onCheckedChange={onCheckedChange}
            onCheckboxChangeChild={onCheckboxChangeChild}
            selectArea={selectArea}
            selectedAmount={selectedAmount}
            setSelectedColumns={setSelectedColumns}
            showChildIndex={showChildIndex}
            showSeletedArea={showSeletedArea}
            showSeletedBtn={showSeletedBtn}
            showGenerateBtn={showGenerateBtn}
            showMasterIndex={showMasterIndex}
            tableType={tableType}
            transferBtnText={transferBtnText}
            transferTable={transferTable}
            totalKey={totalKey}
            totalTitle={totalTitle}
            isDrag={isDrag}
            sortType={sortType}
            containerSelector={containerSelector}
            getMultiChildChangeWidth={getMultiChildChangeWidth}
            onExpandedRowsChange={onExpandedRowsChange}
            meta={this.state.meta}
            headTableId={headTableId}
            bodyTableId={bodyTableId}
            fullTableId={fullTableId}
            defaultOperateColumnHidden={defaultOperateColumnHidden}
            autoHeight={autoHeight}
            cancelCustomRightMenu={cancelCustomRightMenu}
            fieldId={fieldId}
        />
    );
}

//创建卡片型转单列表
export function createTransferList({
    headcode, // 表单表头的区域编码
    bodycode, // 表体表格的区域编码
    transferListId,
    dataSource,
    componentInitFinished,
    onTransferItemClick,
    onTransferItemSelected,
    onUpdateLastActiveFormData,
    onGrandSonsDataUpdate,
}) {
    if (dataSource) {
        this.transferTableDataSource = dataSource;
    }

    if (headcode) {
        this.transferListCardHeadCode = headcode;
    }

    this.myState.transferListAreas = { headcode, bodycode };

    if (!this.myState.transferList.hasOwnProperty(transferListId)) {
        this.myState.transferList[transferListId] = {
            // selected: {
            //     selectedShow: false,
            //     masterAmount: 0, //主表已选数量
            //     bodyAmount: 0, //子表已选数量
            //     masterCheckedId: [], //存放已选主表id，用于复选框半选
            //     selectedData: {
            //         selAll: false,
            //         masterSelData: {},
            //         childSelData: {}
            //     }
            // },
            transformForm: {
                //转单列表
                activeIndex: 0,
                show: true, //是否显示转单视图
                listData: {}, //转单列表数据
                list: [],
                first: 0, //记录页面第几次进入的
            },
        };
    }

    // if (!this.state.meta) {
    //     return false;
    // }
    // if (!this.state.meta[transferListId] || !this.myState.transferList[transferListId]) {
    //     return false;
    // }
    // //转单模板信息
    // let transformListMeta = this.state.meta[transferListId].items;

    let transferList = this.myState.transferList;

    let transformForm = transferList[transferListId].transformForm;

    let dataAreaCode = headcode;

    this.onTransferItemClick = onTransferItemClick;

    this.onTransferItemSelected = onTransferItemSelected;

    const _pageScope = this;

    //默认选中第一条转单
    if (transformForm.first === 0) {
        transformForm.first++;
    } else if (transformForm.first === 1) {
        transformForm.list[0] &&
            onTransferItemSelected && onTransferItemSelected(
                transformForm.list[0].data,
                transformForm.list[0].complete,
                transformForm.activeIndex,
                'edit',
            );
        transformForm.first++;

    }

    //点击缩略显示区
    const handleTransformListClick = (v, index) => {
        let formStatus = _pageScope.form.getFormStatus(headcode);

        // 孙表的更新回调
        onGrandSonsDataUpdate && onGrandSonsDataUpdate(transferListId, formStatus === 'browse');

        if (headcode && bodycode) updateLastActiveFormData.call(this, transferListId, headcode, bodycode, formStatus === 'browse', onUpdateLastActiveFormData);

        transformForm.activeIndex = index;
        this.setState(
            { transformForm },
            () => {
                if (typeof onTransferItemSelected === 'function') {
                    let status = !transformForm.list[index].complete ? 'edit' : transformForm.list[index].formStatus;
                    onTransferItemSelected(v, transformForm.list[index].complete, index, status);
                }
            },
        );
    };

    let flag = transformForm.show && transformForm.list && transformForm.list.length > 1;

    return (
        <CreateTranferList
            // ref={(dom) => {this.myState.transferListRef = dom}}
            _this={this}
            dataAreaCode={dataAreaCode}
            dataSource={dataSource}
            componentInitFinished={componentInitFinished}
            flag={flag}
            handleTransformListClick={handleTransformListClick}
            transformForm={transformForm}
            transferListId={transferListId}
        //transformListMeta={transformListMeta}
        />
    );
}
