import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { sum, withViewModel, getSysFieldid, getMultiLang, changeTime, isTimeType, getLangCode, myBrowser, formatDatetime, formatNumber } from '@platform/api'
import { Table as NCTable, Button as NCButton, BackBtn as NCBackBtn, ToggleViewBtn as NCToggleViewBtn, Checkbox, ErrorPop, Tooltip } from '@platform/base'
import EllipsisToTooltip from './ellipsisToTooltip';
import {
    numberDispose,
    resizeEvent,
    getScrollTop,
    getcolsSettingParam,
    updateColumnAfterSetting,
    getExpandTableHeight,
    whenTransferTableUnmount,
} from './methods';
import { lib } from '@platform/table-core';
import classnames from 'classnames';
const { total, hoverContent } = lib;

const ComplexTable = hoverContent(total(NCTable.SortTable));

const tableHeaderHeight = 28;

const getHoverContent = columns => {
    let oprColumn = null;
    columns = [...columns];
    columns.forEach((item, index) => {
        if (
            item.attrcode === "opr" &&
            item.itemtype === "customer" &&
            item.render
        ) {
            oprColumn = item;
            // 移除下  这里直接移除可能有问题
            columns.splice(index, 1);
        }
    });
    return { oprColumn, columns };
}

@withViewModel

class CreateTranferTable extends Component {
    constructor(props) {
        super(props);
        this.ViewModel = this.props.ViewModel;
        this.expandArrId = this.props.events && this.props.events.expandArr;
        this.state = {
            switchViewState: 'evenUp',
            top: 0,
            height: 0,
            selectedTop: 0,
            selectedHeight: 0,
            json: {},
            LangData: null,
            modalBodyHeight: 0,
            windowHeight: 0,
            transferTableHeight: 0,
            selectedTransferTableHeight: 0,
            selectedListBoxHeight: 0,
            boundingClientRect: {},

            listHeight: 0, //
            selectedListHeight: 0,

        };
        this.main = {};
        this.setTimeIndex = null;
        this.selsectMain = null;
        this.bodyDom = null;
        this.transfertableMain = React.createRef();
        this.transfertableSelectedList = React.createRef();
        this.transfertableMainIsModalSChild = false; // 转单实例是否是欠在NCMODAL弹框里面的
        // ---------
        this.transferTableClient = null;
        this.isTransferTableSetHeight = false;
        this.isSelectedListSetHeight = false;
        this.transferTableHeight = null;
        this.modalBodyClient = null;
        this.modalBodyHeight = 0;

        // ---------
        this.total = null;
        this.doing = true; // 用来阻止事件的
        this.setListHeightIsDone = false; // 用来阻止事件的
        this.setSelectListHeightIsDone = false; // 用来阻止事件的
        this.setModalHeightIsDone = false; // 用来阻止事件的

        /***获取当前浏览器类型*/
        this.myBrowser = myBrowser();
    }

    componentWillMount() {
        let callback = (json, bool, LangData) => {
            this.setState({ json, LangData }, () => {
                let { _this, dataSource, componentInitFinished, headTableId } = this.props;
                if (dataSource) {
                    let data = this.ViewModel.getData(dataSource);
                    // 转单数据缓存还原
                    if (data && data.transferTable && Object.keys(data.transferTable).length) {
                        let pk = this.ViewModel.getData(`${dataSource}_PK`) || []; // 下有转单保存的pk， 需要在缓存中删除
                        // let ID = this.ViewModel.getData(`${dataSource}_ID`) || {}; // 主表子表每条数据的的key， 删除缓存中保存过的单据要用
                        this.processingData(data.transferTable, pk, headTableId);
                        _this.setState(
                            { transferTable: data.transferTable },
                            () => {
                                // 清除一下
                                // this.ViewModel.setData(dataSource, null);
                                //执行数据初始化周期函数
                                if (typeof componentInitFinished == 'function') {
                                    componentInitFinished();
                                }
                            },
                        );
                    }
                }
            });
        };
        getMultiLang({ moduleId: 'page-transferTable', callback });
    }

    componentDidMount() {
        setTimeout(() => {
            this.onresizeEvents({ isIntl: true });
        }, 200);
        window.onresize = () => {
            let { getMultiChildChangeWidth, isMultiChild, containerSelector } = this.props;
            //外层容器宽度
            let containerWidth = containerSelector && document.querySelector(containerSelector) && document.querySelector(containerSelector).clientWidth;
            if (isMultiChild && typeof getMultiChildChangeWidth === 'function') {
                getMultiChildChangeWidth(containerWidth, isMultiChild);
            }
            this.onresizeEvents({ windowResize: true });
        };
        // 设置一个接收装置 接收父级page所传递的信息
        let _this = this.props._this;
        // 查询区
        _this.infoExchangeController.subscribe('heightChange', (name, { area }) => {
            if (area === 'searchArea' || area === this.props.headTableId) {
                console.log(`侦测到${area}高度变化，进行高度毁灭打击！`);
                setTimeout(() => {
                    this.onresizeEvents({ windowResize: true });
                }, 20);
            } else {
                console.log(area);
            }
        });
        // 弹窗resize
        _this.infoExchangeController.subscribe('modelResizeEnd', (name, { indexInfo, className, id }) => {
            console.log('侦测到弹窗resize变化，随风起舞吧！', indexInfo, className, id, name);
            setTimeout(() => {
                this.onresizeEvents({ windowResize: true });
            }, 20);
        });
    }

    componentWillUnmount() {
        let { _this, dataSource, isMultiChild } = this.props;
        whenTransferTableUnmount.call(_this, dataSource, isMultiChild);
        // 可能需要清理 这里清理了 别人就不能用了  可以考虑 topic模式
        _this.infoExchangeController && _this.infoExchangeController.unsubscribe('heightChange');
        _this.infoExchangeController && _this.infoExchangeController.unsubscribe('modelResizeEnd');
    }

    componentDidUpdate() {
        let { _this } = this.props;
        if (_this.state.singleDelete) { //供应链刘奇的转单上下游是多页，下游保存单据后，上游需要更新删除保存后的数据
            this.dealDataFromTransferListBack(false);
            _this.state.singleDelete = false;
        }
    }

    /**
     * 转单下游回到上游以后，检测保存过的数据，然后删除
     */
    dealDataFromTransferListBack = (isWillMount = true) => {
        let { _this, dataSource, componentInitFinished, headTableId } = this.props;
        if (dataSource) {
            let data = this.ViewModel.getData(dataSource);
            // 转单数据缓存还原
            if (data && data.transferTable && Object.keys(data.transferTable).length) {
                let pk = this.ViewModel.getData(`${dataSource}_PK`) || []; // 下有转单保存的pk， 需要在缓存中删除
                // let ID = this.ViewModel.getData(`${dataSource}_ID`) || {}; // 主表子表每条数据的的key， 删除缓存中保存过的单据要用
                this.processingData(data.transferTable, pk, headTableId);
                _this.setState(
                    { transferTable: data.transferTable },
                    () => {
                        // 清除一下
                        // this.ViewModel.setData(dataSource, null);
                        //执行数据初始化周期函数
                        if (isWillMount && typeof componentInitFinished == 'function') {
                            componentInitFinished();
                        }
                    },
                );
            }
        }
    }

    /*
    * 设置参照曾行之类的转单所在模态框的高度
    * */
    setModalTransferTableHeight = () => {
        let { transferTable, showSeletedArea } = this.props,
            selectedShow = transferTable.selected.selectedShow && showSeletedArea,
            modalBodySelector = document.querySelector('.u-modal-body'),
            modalBodyNowClient = modalBodySelector ? modalBodySelector.getBoundingClientRect() : null,
            transferTableTop = this.transfertableMain && this.transfertableMain.current && this.transfertableMain.current.getBoundingClientRect && this.transfertableMain.current.getBoundingClientRect().top,
            transferTableHeight = 0;
        // 判断是设置全部列表的高度还是设置已选列表的高度
        if (selectedShow) {
            if (!this.setSelectListHeightIsDone) {
                transferTableHeight = modalBodyNowClient.bottom - transferTableTop; // 15 是modal的paddingBottom

                this.setState({
                    transferTableHeight,
                    selectedListHeight: transferTableHeight,
                }, () => {
                    this.setSelectListHeightIsDone = true;
                    this.showTransferTableTimer = null;
                });
            }
        } else {
            if (!this.setListHeightIsDone) {
                transferTableHeight = modalBodyNowClient.bottom - transferTableTop; // 15 是modal的paddingBottom
                this.setState({
                    transferTableHeight,
                    listHeight: transferTableHeight,
                }, () => {
                    this.setListHeightIsDone = true;
                    this.showTransferTableTimer = null;
                });
            }
        }

    }

    // 判断转单是不是放在modal中的
    transferTableIsInModal = () => {
        let modalSelector = document.querySelector('.u-modal-body');
        let transfertableNowCurrent = this.transfertableMain.current;
        let result = [];
        let father = transfertableNowCurrent && transfertableNowCurrent.parentNode;
        while (father && father.tagName != 'BODY') {
            result.push(father);
            father = father.parentNode;
        }
        this.doing = false;
        this.transfertableMainIsModalSChild = result.includes(modalSelector);
    }

    /*
    * 设置转单组件自适应宽高
    * isIntl: Boolean 页面是否初始化 （didMount的时候会设置为true）
    * windowResize：Boolean 窗口是否在变化宽高
    * */
    onresizeEvents = ({ isIntl, windowResize }) => {
        let { transferTable, showSeletedArea } = this.props;
        let selectedShow = transferTable.selected.selectedShow && showSeletedArea;
        let windowHeight = getScrollTop();
        let transferTableTop = this.transfertableMain && this.transfertableMain.current && this.transfertableMain.current.getBoundingClientRect && this.transfertableMain.current.getBoundingClientRect().top;
        resizeEvent(isIntl, windowResize, selectedShow, transferTableTop, windowHeight, this);
    }

    //切换 全部列表 和 已选列表的显示状态
    switchSelectedAndAll = () => {
        let { _this, transferTable, headTableId } = this.props;
        let oldSelectedShow = transferTable.selected.selectedShow;
        if (!oldSelectedShow) { //to查看已选列表
            transferTable.selected.selectedShow = true;
        } else { //to关闭已选列表
            transferTable.selected.selectedShow = false;
            _this.state.transferTable[headTableId] = transferTable;
        }
        _this.setState({ transferTable: _this.state.transferTable }, () => {
            this.onresizeEvents({ isIntl: false, windowResize: false });
        });
    }

    //统计已选列表数量
    getSelectedAmount = () => {
        let { _this } = this.props;
        let transferTable = _this.state.transferTable;
        let bodyAmount = 0,
            masterAmount = 0;
        for (let item of Object.keys(transferTable)) {
            if (item !== 'allSelected') {
                let selected = transferTable[item].selected;
                masterAmount += selected.masterAmount ? Number(selected.masterAmount) : 0;
                bodyAmount += selected.bodyAmount ? Number(selected.bodyAmount) : 0;
            }
        }
        return { masterAmount, bodyAmount };
    };

    // 自定义合计
    customAggregate = () => {
        let { _this, totalKey, getTheLongestPrecision, totalTitle, transferTable } = this.props;
        let allSelected = transferTable['selected'].selectedData;
        let masterSelData = allSelected.masterSelData;
        let childSelData = allSelected.childSelData;
        let data = [];
        let values = totalKey.reduce((total, now) => {
            total[now] = 0;
            return total;
        }, {});
        for (let [name, item] of Object.entries(masterSelData)) {
            let masterValue = item.data;
            for (let key of totalKey) {
                if (masterValue.hasOwnProperty(key)) {
                    values[key] = getTheLongestPrecision(String(masterValue[key].value), String(values[key]));
                    values[key] = numberDispose(values[key]);
                }
            }
            if (childSelData.hasOwnProperty(name)) {
                for (let [na, it] of Object.entries(childSelData[name])) {
                    let childValue = it.data.values ? it.data.values : it.data;
                    for (let key of totalKey) {
                        if (childValue.hasOwnProperty(key)) {
                            values[key] = getTheLongestPrecision(String(childValue[key].value), String(values[key]));
                            values[key] = numberDispose(values[key]);
                        }
                    }
                }
            }
        }

        totalKey.map((item, index) => {
            data.push({
                title: totalTitle[index],
                value: values[item],
                key: item,
            });
        });
        // 我墮落了
        return data.length ? (
            <div style={{ display: 'inline-block' }}>
                {data.map(item => {
                    return (
                        <span key={item.key} style={{ display: 'inline-block', marginRight: '20px' }}>
                            {`${item.title}: ${item.value}`}
                        </span>
                    );

                })}
            </div>
        ) : '';
    }

    // 判断数据是否在内部
    bodyTableDataIsSelected = (rowData, item) => {
        return rowData && rowData.rows && rowData.rows.some(function (ritem) { return ritem.childId === item; });
    }

    /*
    * 处理合计数据
    * selected: Object 计算合计选中的数据
    * tableType：String 判断主子表结构、主子拉平结构或者单表结构 （nest/full/simple）
    * column：Array 合计行的模板数据
    * showFullTable：Boolean 判断是表示是否为拉平显示
    * */
    processingTotalData = (selected, tableType, column, showFullTable, currentTable) => {
        let { getScaleData } = this.props;
        let outerData = {};
        let totalKey = [];
        let totalScale = {};
        let childSelData = selected.selectedData.childSelData;
        let masterSelData = selected.selectedData.masterSelData;
        let masterTableData = currentTable ? currentTable.outerData : Object.keys(masterSelData);
        let bodyTableData = currentTable && currentTable.bodyData && currentTable.bodyData.data;

        for (let item of column) {
            outerData[item['attrcode']] = { value: '', display: '' };
            if (item.istotal) {
                totalKey.push(item.attrcode);
                totalScale[item.attrcode] = item.scale;
            }
        }
        if (!showFullTable || tableType === 'simple') {
            //  应该依赖outData 进行计算
            for (let items of masterTableData) {
                items = items.rowId ? items.rowId : items;
                if (masterSelData[items]) { // outData 存在  已选不存在的  不计算
                    let values = masterSelData[items].data;
                    for (let i of totalKey) {
                        if (values[i] && typeof values[i] === 'object') {
                            let value = values[i] ? values[i].value : 0;
                            let scale = values[i].scale ? values[i].scale : totalScale[i];
                            if (!outerData[i].value) {
                                outerData[i].scale = scale;
                                outerData[i].value = getScaleData(String(value), outerData[i].scale);
                            } else {
                                let totalData = sum(Number.parseFloat(value), Number.parseFloat(outerData[i].value.replace(/,/g, '')));
                                outerData[i].scale = scale > outerData[i].scale ? scale : outerData[i].scale;
                                outerData[i].value = getScaleData(String(totalData), outerData[i].scale);
                            }
                        }

                    }
                }
            }
        } else if (showFullTable && tableType !== 'simple') {
            if (childSelData) {
                for (let items of Object.keys(childSelData)) {
                    for (let item of Object.keys(childSelData[items])) {
                        if (!bodyTableData || (bodyTableData && this.bodyTableDataIsSelected(bodyTableData[items], item))) { // 做一下判断当前表格中是否有对应已选的数据
                            for (let i of totalKey) {
                                if (
                                    childSelData[items][item].data.values
                                        ? childSelData[items][item].data.values.hasOwnProperty(i)
                                        : childSelData[items][item].data.hasOwnProperty(i)
                                ) {
                                    let values = childSelData[items][item].data.values || childSelData[items][item].data;
                                    if (values[i] && typeof values[i] === 'object') {
                                        let value = values[i] ? values[i].value : 0;
                                        let scale = values[i].scale ? values[i].scale : totalScale[i];
                                        if (!outerData[i].value) {
                                            outerData[i].scale = scale;
                                            outerData[i].value = getScaleData(String(value), outerData[i].scale);
                                        } else {
                                            let totalData = sum(Number.parseFloat(value), Number.parseFloat(outerData[i].value.replace(/,/g, '')));
                                            outerData[i].scale = scale > outerData[i].scale ? scale : outerData[i].scale;
                                            outerData[i].value = getScaleData(String(totalData), outerData[i].scale);
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
            //  应该依赖outData 进行计算
            for (let items of masterTableData) {
                items = items.rowId ? items.rowId : items;
                if (masterSelData[items]) { // outData 存在  已选不存在的  不计算
                    let values = masterSelData[items].data.values || masterSelData[items].data;
                    for (let i of totalKey) {
                        if (values.hasOwnProperty(i)) {
                            if (values[i] && typeof values[i] === 'object') {
                                let value = values[i] ? values[i].value : '0';
                                let scale = values[i].scale ? values[i].scale : totalScale[i];

                                if (!outerData[i].value) {
                                    outerData[i].scale = scale;
                                    outerData[i].value = getScaleData(String(value), scale);
                                } else {
                                    let totalData = sum(Number.parseFloat(value), Number.parseFloat(outerData[i].value.replace(/,/g, '')));
                                    outerData[i].scale = scale > outerData[i].scale ? scale : outerData[i].scale;
                                    outerData[i].value = getScaleData(String(totalData), outerData[i].scale);
                                }
                            }

                        }
                    }
                }
            }
        }
        return [outerData];
    };

    /*
    * 计算子表的合计航数据
    * data：Object 计算合计选中的数据
    * column：Array 合计行的模板数据
    * record：Object 该子表所属主表所在行的数据
    * */
    processingTotalDataChild = (data, column, record) => {
        let { getScaleData } = this.props;
        let key = record.key;
        let childData = data[key];
        let outerData = {};
        let totalKey = [];
        let totalScale = {};
        for (let item of column) {
            outerData[item['attrcode']] = { value: '', display: '' };
            if (item.istotal) {
                totalKey.push(item.attrcode);
                totalScale[item.attrcode] = item.scale;
            }
        }
        if (childData) {
            for (let items of Object.keys(childData)) {
                let values = childData[items].data.values ? childData[items].data.values : childData[items].data;
                for (let i of totalKey) {
                    if (values[i] && typeof values[i] === 'object') {
                        let scale = values[i].scale ? values[i].scale : totalScale[i];
                        let value = values[i] ? values[i].value : 0;
                        if (!outerData[i].value) {
                            outerData[i].scale = scale;
                            outerData[i].value = getScaleData(String(value), scale);
                        } else {
                            let totalData = sum(Number.parseFloat(value), Number.parseFloat(outerData[i].value.replace(/,/g, '')));
                            outerData[i].scale = scale > outerData[i].scale ? scale : outerData[i].scale;
                            outerData[i].value = getScaleData(String(totalData), outerData[i].scale);
                        }
                    }

                }
            }
        }
        return [outerData];
    };

    /*
    * 表格展开显示的内容 渲染子表表格数据及复选框
    * index：Number 展开行所属主表所在行的下标
    * record：Object 该子表所属主表所在行的数据
    * */
    expandedRowRender = (record, index) => {
        let {
            bodyColumn,
            isBodyTotal,
            _this,
            currentTable,
            createNewData,
            transferTable,
            setSelectedColumns,
            createNewCol,
            isDrag,
            showChildIndex,
            comType,
            sortType,
            bodyTableId,
            headTableId,
            meta,
            cancelCustomRightMenu,
        } = this.props;

        // socket 错误消息
        let socketErrorClass = '',
            values = record.values || record;
        if (
            values &&
            values.saga_status &&
            values.saga_status.value === '1'
        ) {
            socketErrorClass = ' socket-error-class';
        }

        let newColumn = bodyColumn;
        let curKey = record.headKey || record.key;
        let TotalDataChild = this.processingTotalDataChild(
            transferTable.selected.selectedData.childSelData,
            newColumn,
            record,
        );

        let colsSettingParam = getcolsSettingParam.call(_this, meta, bodyTableId);
        if (newColumn instanceof Array && newColumn.length > 0) {
            newColumn = this.renderColumnsMultiSelectChild.call(_this, newColumn, record, index, comType);

            if ((currentTable.bodyData && Object.keys(currentTable.bodyData).length && currentTable.bodyData.data.hasOwnProperty(curKey)) || (
                transferTable && transferTable.selected && transferTable.selected.selectedData && comType === 'selectedList'
            )) {
                let data = null;
                let expandTableHeight = 0;//展开子表的高度
                let expandTableBodyHeight = 0;//展开子表表体的高度
                //已选列表的子表移除时需要删除行数据
                if (comType === 'selectedList') {
                    data = createNewData(
                        Object.values(transferTable.selected.selectedData.childSelData[curKey] || {}).map(
                            item => item.data,
                        ),
                    );
                } else {
                    let intlData = currentTable.bodyData.data[record.key].rows || currentTable.bodyData.data[record.key];

                    data = createNewData(
                        intlData,
                    );
                    expandTableHeight = getExpandTableHeight(data, isBodyTotal, ReactDOM.findDOMNode(this));
                    expandTableBodyHeight = getExpandTableHeight(data, isBodyTotal, ReactDOM.findDOMNode(this), true);
                }
                // console.log(expandTableHeight, `selected-list-${index}_table`, ReactDOM.findDOMNode(this));
                let selectedStatus = transferTable.selected.selectedShow;
                if (selectedStatus) {
                    return (
                        <div className={`u-col-md-12 u-col-sm-12 u-col-xs-12 transfertable-expand-wraper ${socketErrorClass}`} fieldid={getSysFieldid(`selected-list-${index}_table`)} style={{ height: `${expandTableHeight}px` }}>
                            {/* 多了一个dom 这个dom 原来是给tinper 做计算用的 */}
                            <ComplexTable
                                className={`${isBodyTotal ? 'expand-table expand-table-total' : 'expand-table'}`}
                                columns={setSelectedColumns(createNewCol({ column: newColumn, outerRecordKey: curKey, columnType: 'child', json: this.state.json }))}
                                data={data}
                                lazyload={true}
                                isDrag={isDrag}
                                isTotal={isBodyTotal}
                                totalBodyHeight={30}
                                totalData={TotalDataChild}
                                totalColumns={this.createClumus(newColumn, showChildIndex, false, 45, true)}
                                scroll={{ x: true, y: expandTableBodyHeight || 450 }}
                                sort={sortType}

                                updateColumnHandle={updateColumnAfterSetting.bind(this)}
                                bodyStyle={{ minHeight: '30px' }}
                                tableType='transfer'
                                //折行回调
                                foldingRowChange={isEllipsis => {
                                    _this.state.transferTable[headTableId]['isEllipsis'] = isEllipsis
                                    _this.setState({ transferTable: _this.state.transferTable })
                                }}
                                bodyDisplayInRow={_this.state.transferTable[headTableId]['isEllipsis']}
                                bodyExpandAllInRow={_this.state.transferTable[headTableId]['bodyExpandAllInRow']}
                                cancelCustomRightMenu={cancelCustomRightMenu}
                                colsSettingParam={colsSettingParam}
                            />
                        </div>
                    );
                }
                return (
                    <div className={`u-col-md-12 u-col-sm-12 u-col-xs-12 transfertable-expand-wraper ${socketErrorClass}`} fieldid={getSysFieldid(`transfer-${index}_table`)} style={{ height: `${expandTableHeight}px` }}>
                        {/* 多了一个dom  这个dom 原来是给tinper 做计算用的 */}
                        <ComplexTable
                            className={`${isBodyTotal ? 'expand-table expand-table-total' : 'expand-table'}`}
                            columns={createNewCol({ column: newColumn, outerRecordKey: curKey, columnType: 'child', json: this.state.json })}
                            data={data}
                            isDrag={isDrag}
                            scroll={{ x: true, y: expandTableBodyHeight || 450 }}
                            lazyload={true}
                            isTotal={isBodyTotal}
                            totalData={TotalDataChild}
                            totalBodyHeight={30}
                            totalColumns={this.createClumus(newColumn, showChildIndex, false, 45, true)}
                            sort={sortType}

                            updateColumnHandle={updateColumnAfterSetting.bind(this)}
                            bodyStyle={{ minHeight: '30px' }}
                            tableType='transfer'
                            //折行回调
                            foldingRowChange={isEllipsis => {
                                _this.state.transferTable[headTableId]['isEllipsis'] = isEllipsis
                                _this.setState({ transferTable: _this.state.transferTable })
                            }}
                            bodyDisplayInRow={_this.state.transferTable[headTableId]['isEllipsis']}
                            bodyExpandAllInRow={_this.state.transferTable[headTableId]['bodyExpandAllInRow']}
                            colsSettingParam={colsSettingParam}
                            cancelCustomRightMenu={cancelCustomRightMenu}
                        />
                    </div>
                );
            }
        } else {
            return <span />;
        }
    };

    /*
    *  控制行展开图标是否显示，当子表模板找不到并且子表数据为空数组时，设置展开图标隐藏
        headTableId:String 转单主表ID
        record:将要渲染的这行主表的数据
        index：将要渲染的这行主表的索引
        函数返回false显示展开icon，反之不显示
    */
    haveExpandIcon = (headTableId, isSelected, record, index) => {
        let { bodyColumn, currentTable, transferTable } = this.props;
        let haveBodyData,
            haveBodyColumn;
        if (isSelected) {
            let childSelData = transferTable.selected.selectedData.childSelData;
            let childSelDatalen = Object.keys(childSelData) && Object.keys(childSelData).length;
            if (childSelDatalen) {
                haveBodyData = true;
            }
        } else {
            if (transferTable &&
                transferTable[headTableId] &&
                transferTable[headTableId].outerData
            ) {
                // 这里要修正下 因为滚动加载导致 index返回值并不正确
                // let rowId = transferTable[headTableId].outerData[index].rowId;
                let rowId = record.key || record.headKey;
                if (currentTable &&
                    currentTable.bodyData &&
                    currentTable.bodyData.data &&
                    currentTable.bodyData.data[rowId]
                ) {
                    if (currentTable.bodyData.data[rowId].rows.length > 0) {
                        haveBodyData = true;
                    }
                }
            }
        }
        if (bodyColumn instanceof Array && bodyColumn.length > 0) {
            haveBodyColumn = true;
        }
        if (haveBodyData && haveBodyColumn) {
            return false;
        } else {
            return true;
        }
    }

    /*
   * 页面初始化的时候去除下游保存过的数据
   * transferTable：Object 转单数据 （this.state.transferTable）
   * pk：Array 下游保存过的所有单据主键
   * headTableId：String 转单ID
   * */
    processingData = (transferTable, pk, headTableId) => {
        // 有些数据可能没清掉 导致BUG NCCLOUD-172457
        let { onCheckedChange, tableType } = this.props;
        if (pk.length) {
            for (let items of Object.keys(transferTable)) {
                if (items === headTableId) {
                    let data = transferTable[items][headTableId]['bodyData'].hasOwnProperty('data') ? transferTable[items][headTableId]['bodyData']['data'] : null;
                    let outerData = transferTable[items][headTableId]['outerData'];
                    let selected = transferTable[items]['selected']['selectedData'];
                    let masterCheckedId = transferTable[items]['selected'] && transferTable[items]['selected'].masterCheckedId;
                    let childSelect = selected && selected.childSelData;
                    let masterSelect = selected && selected.masterSelData;
                    let selectedShow = transferTable[items]['selected'].selectedShow;
                    let bodyAmountLen = 0;
                    // 上游选择了多子数据
                    // 如果下游只保存了一个数据 这里就有问题了 
                    // transferTable[items][headTableId]['outerData'] = outerData.length ? outerData.filter(item => {
                    //     return !pk.includes(item.rowId) && !masterCheckedId.includes(item.rowId);
                    // }) : [];

                    // 先清理主 再清理子
                    masterCheckedId = masterCheckedId.filter(item => {
                        return !pk.includes(item);
                    });

                    transferTable[items][headTableId]['outerData'] = outerData.length ? outerData.filter(item => {
                        if (pk.includes(item.rowId)) {
                            return false;
                        }
                        if (data && Object.keys(data).length && data[item.rowId] && data[item.rowId].rows) {
                            // 这里需要先记录下 初始数据  不然会导致没有子级的数据返回时被删除
                            let originLength = data[item.rowId].rows.length;

                            data[item.rowId].rows = data[item.rowId].rows.filter(citem => {
                                return !pk.includes(citem.childId);
                            });
                            // 如果子集都没有了 父级也不需要了？？？ 这里注意 一定要一开始有子集 然后才能移除父级
                            if (originLength && data[item.rowId].rows.length === 0) {
                                return false;
                            }
                        }
                        return true;
                    }) : [];
                    // 还有一个全选的状态也需要处理下  TODO
                    if (pk && pk.length > 0) {
                        selected && (selected.selAll = false);
                    }

                    // 根据保存时缓存的pk删除之前保存过的单据
                    if (data && Object.keys(data).length) {
                        for (let val of Object.keys(data)) {
                            if (pk.includes(val)) {
                                delete data[val];
                            } else {
                                data[val].rows = data[val].rows.filter(item => {
                                    return !pk.includes(item.childId);
                                });
                            }
                        }
                    }
                    // 删表体数据
                    if (childSelect && Object.keys(childSelect).length) {
                        for (let itemm of Object.keys(childSelect)) {
                            let onChecked = {
                                body: [],
                                head: {},
                            };
                            let headIndex = Object.keys(data).indexOf(itemm);
                            if (pk.includes(itemm)) {
                                delete childSelect[itemm];
                            } else {
                                for (let item of Object.keys(childSelect[itemm])) {
                                    if (pk.includes(item)) {
                                        delete childSelect[itemm][item];
                                        if (!Object.keys(childSelect[itemm]).length) {
                                            delete childSelect[itemm];
                                            delete masterSelect[itemm];
                                            transferTable[items]['selected'].masterCheckedId = masterCheckedId.filter(it => {
                                                return it !== itemm;
                                            });
                                            transferTable.allSelected.masterCheckedId = masterCheckedId.filter(it => {
                                                return it !== itemm;
                                            });
                                        }
                                    } else {
                                        onChecked.body.push(childSelect[itemm][item].data.values ? childSelect[itemm][item].data.values : childSelect[itemm][item].data);
                                    }
                                }
                            }
                            if (masterSelect.hasOwnProperty(itemm)) {
                                onChecked.head = masterSelect[itemm].data;
                            }
                            if (Object.keys(onChecked.head).length) {
                                if (typeof onCheckedChange === 'function') {
                                    onCheckedChange(true, JSON.parse(JSON.stringify(onChecked.head)), headIndex, JSON.parse(JSON.stringify(onChecked.body)));
                                }
                            }
                        }
                    }
                    // 删表头数据
                    if (masterSelect && Object.keys(masterSelect).length) {
                        for (let items of Object.keys(masterSelect)) {
                            let onChecked = {
                                body: [],
                                head: {},
                            };
                            if (masterSelect.hasOwnProperty(items)) {
                                onChecked.head = masterSelect[items].data;
                            }
                            let headIndex = Object.keys(masterSelect).indexOf(items);
                            if (pk.includes(items)) {
                                delete masterSelect[items];
                            } else if (tableType === 'simple') {
                                if (Object.keys(onChecked.head).length) {
                                    if (typeof onCheckedChange === 'function') {
                                        onCheckedChange(true, JSON.parse(JSON.stringify(onChecked.head)), headIndex, JSON.parse(JSON.stringify(onChecked.body)));
                                    }
                                }
                            }
                        }
                    }

                    if (childSelect && Object.keys(childSelect).length) {
                        for (let item of Object.keys(childSelect)) {
                            // 有个数据没有子数据  但是应该也算一行数据
                            bodyAmountLen += (Object.keys(childSelect[item]).length || 1);
                        }
                    }
                    transferTable[items]['selected'].selectedShow = selectedShow;
                    transferTable[items]['selected'].bodyAmount = bodyAmountLen;
                    transferTable[items]['selected'].masterAmount = Object.keys(masterSelect).length;
                    transferTable[items]['selected'] && (transferTable[items]['selected'].masterCheckedId = masterCheckedId);
                }
                // 如果不清理 allSelected 会导致问题
                if (items == 'allSelected') {
                    let selIds = transferTable.allSelected ? transferTable.allSelected.selIds : null;
                    let selectedData = transferTable.allSelected.selectedData;
                    let childSelData = selectedData.childSelData;
                    let masterSelData = selectedData.masterSelData;
                    let masterCheckedId = transferTable.allSelected.masterCheckedId || [];
                    let bodyAmount = 0;
                    if (selIds) {
                        for (let item of Object.keys(selIds)) {
                            transferTable.allSelected.selIds[item] = selIds[item].filter(i => {
                                return !pk.includes(i);
                            });
                            if (!selIds[item].length) {
                                masterCheckedId = masterCheckedId.filter(i => {
                                    return i !== item;
                                });
                                delete selIds[item];
                            }
                        }
                        for (let item of Object.keys(childSelData)) {
                            for (let i of Object.keys(childSelData[item])) {
                                if (pk.includes(i)) {
                                    delete childSelData[item][i];
                                }
                            }
                            if (!Object.keys(childSelData[item]).length) {
                                masterCheckedId = masterCheckedId.filter(masterItems => {
                                    return masterItems !== item;
                                });
                                delete masterSelData[item];
                                delete childSelData[item];
                            }
                        }
                        for (let item of Object.keys(selIds)) {
                            if (selIds[item]) {
                                // 有个数据没有子数据  但是应该也算一行数据
                                bodyAmount += (selIds[item].length || 1);
                            }
                        }
                        transferTable.allSelected.masterCheckedId = masterCheckedId;
                        transferTable.allSelected.masterAmount = Object.keys(selIds).length;
                        transferTable.allSelected.bodyAmount = bodyAmount;
                    }
                }
            }
            // 后续可以优化下
            // 由于刘奇那边有个统计  所以这里触发一下onCheckedChange  NCCLOUD-158999
        } else if (this.props.onCheckedChange) {
            for (let items of Object.keys(transferTable)) {
                if (items === headTableId) {
                    let dataKeys = Object.keys(transferTable[items][headTableId]['bodyData'].hasOwnProperty('data') ? transferTable[items][headTableId]['bodyData']['data'] : {}),
                        selected = transferTable[items]['selected']['selectedData'],
                        childSelect = selected && selected.childSelData,
                        childSelectKeys = Object.keys(childSelect),
                        masterSelect = selected && selected.masterSelData,
                        masterSelectKeys = Object.keys(masterSelect);

                    if (childSelect && childSelectKeys.length) {
                        for (let citems of childSelectKeys) {
                            let onChecked = {
                                body: [],
                                head: masterSelect[citems] ? masterSelect[citems].data : {},
                            };
                            let childSelectItem = childSelect[citems],
                                childSelectItemsKeys = Object.keys(childSelectItem);
                            for (let item of childSelectItemsKeys) {
                                onChecked.body.push(childSelectItem[item].data.values ? childSelectItem[item].data.values : childSelectItem[item].data);
                            }
                            if (Object.keys(onChecked.head).length && typeof onCheckedChange === 'function') {
                                let headIndex = dataKeys.indexOf(citems);
                                onCheckedChange(true, JSON.parse(JSON.stringify(onChecked.head)), headIndex, JSON.parse(JSON.stringify(onChecked.body)));
                            }
                        }
                    }
                    if (masterSelectKeys.length && tableType === 'simple') {
                        for (let mitems of masterSelectKeys) {
                            if (!pk.includes(mitems)) {
                                if (typeof onCheckedChange === 'function') {
                                    let headIndex = masterSelectKeys.indexOf(mitems);
                                    onCheckedChange(true, JSON.parse(JSON.stringify(masterSelect[mitems].data)), headIndex, masterSelectKeys.indexOf(mitems), []);
                                }
                            }
                        }
                    }
                }
            }
        } else {
            console.log('么得数据！么得PK！');
        }
    };

    /*
    * 子表添加复选框列
    * columns：Array 子表的模板数据
    * records：Object 子表所属主表行数据
    * indexs：Number 子表所属主表行下标
    * comType：String 根据组件类型，判断是否带复选框（hasCheckBox_child/selectedList）
    * */
    renderColumnsMultiSelectChild = (columns, records, indexs, comType) => {
        let { json } = this.state;
        let { _this, defaultProps, showChildIndex, onSelectedItemRemove, transferTable, onCheckboxChangeChild, currentTable } = this.props;
        let { multiSelect } = defaultProps;
        let indexCol = [
            {
                label: <span
                    style={{
                        display: 'block',
                        textAlign: 'left',
                    }}
                    bbq="子表序号"
                >{json['page-transferTable-0007'] || '序号-1'}
                </span>,
                attrcode: 'numberindex',
                itemtype: 'customer',
                dataIndex: 'numberindex',
                visible: true,
                width: comType == 'hasCheckBox_child' ? 45 : 55,
                className: 'col-customer-numberindex',
                render: (text, record2, index) => {
                    return (
                        <span
                            style={{ display: 'block', textAlign: 'left' }}
                        >{index + 1}
                        </span>
                    );
                },
            },
        ]; // 添加序号列
        let newColumn = [].concat(columns);
        if (showChildIndex) {
            newColumn = indexCol.concat(newColumn);
        }
        let oprationLabel = json['page-transferTable-0006'] ? json['page-transferTable-0006'] : '操作-2';
        /*国际化处理 操作*/
        //  根据组件类型，判断是否带复选框
        if (comType == 'hasCheckBox_child') {
            if (multiSelect && multiSelect.type === 'checkbox') {
                let parId = transferTable.selected.selectedData.childSelData[records.key];
                // 主表有状态 那么子表就不需要状态 saga
                let pValues = records.values || records;
                let pSaga_status = pValues && pValues.saga_status && pValues.saga_status.value === '1';

                let defaultColumns = [
                    {
                        label: <span bbq="子表复选框" />,
                        attrcode: 'checkbox',
                        itemtype: 'customer',
                        fixed: 'left',
                        dataIndex: 'checkbox',
                        width: 70,
                        visible: true,
                        className: 'col-customer-checkbox',
                        onCellClick: (record, e) => {
                            let ck = record.key;
                            let values = record.values || record;
                            let index = -1;
                            let bodyData = ((currentTable.bodyData.data || {})[records.key] || {}).rows || [];

                            bodyData.some((item, i) => {
                                let values = item.values || item;
                                if (values.key === ck) {
                                    index = i;
                                    return true;
                                }
                            });

                            // console.log(bodyData, index, 'xxxxx');

                            if (index == -1 || pSaga_status || (values && values.saga_status && values.saga_status.value === '1')) {
                                return;
                            }
                            onCheckboxChangeChild.call(this, '', record, index, records, indexs)
                        },
                        render: (text, record, index) => {
                            // Socket改造
                            // let { billpkname, billtype, errorViewType } = _this.nativeSocketBillInfo;
                            let values = record.values || record;
                            return (values && values.saga_status && values.saga_status.value === '1') || pSaga_status ? (
                                <ErrorPop
                                    record={record}
                                />
                            ) : (
                                <Checkbox
                                    className="table-checkbox"
                                    checked={!!(parId && parId[record.key])}
                                    onChange={onCheckboxChangeChild.bind(_this, text, record, index, records, indexs)}
                                />
                            );
                        },
                    },
                ];
                newColumn = defaultColumns.concat(newColumn);
            }
        } else if (comType == 'selectedList') {
            //已选列表增加操作列
            let oprColumn = {
                label: <span>{oprationLabel}</span>,
                attrcode: 'operate',
                itemtype: 'customer',
                dataIndex: 'operate',
                width: 100,
                fixed: 'right',
                visible: true,
                render: (text, record, index) => {
                    return (
                        <div
                            className="row-remove"
                            bbq="子表移除"
                            onClick={() => {
                                //移除子表
                                onSelectedItemRemove && onSelectedItemRemove(record);
                                onCheckboxChangeChild(text, record, index, records, index, true);
                            }}
                        >
                            {this.state.json['page-transferTable-0001']}{/*国际化处理 移除*/}
                        </div>
                    );
                },
            };
            newColumn = [...newColumn, oprColumn];
        }

        return newColumn;
    };

    /*
    * 已选列表设置展开行的子表内容
    * record：Object 展开行所属主表行数据
    * index：Number 展开行所属主表行下标
    * */
    expandedRowRenderSelect = (record, index) => {
        let { expandedRowRender } = this.props;
        return expandedRowRender(record, index, 'selectedList', this.state.json);
    };

    /*
    * 设置模板
    * column：Array 模板数据
    * showIndex：Boolean 是否需要添加序号行
    * bool：Boolean 是否在模板最后要添加空的操作行（在设置已选列表模板会用到）
    * */
    createClumus = (column, showIndex, bool = false, indexWidth = 80, isChild) => {
        let { json } = this.state;
        let result = [];
        let { comType, headTableId } = this.props;
        let multiLangTotal = json['page-transferTable-0010'];
        let oprationLabel = json['page-transferTable-0006'] ? json['page-transferTable-0006'] : '操作-1';
        /*国际化处理 操作*/

        if ((comType == 'hasCheckBox' || comType == 'hasCheckBox_child') && multiLangTotal) {
            result.push({
                label: <span style={{
                    display: 'block',
                    textAlign: 'left',
                }}
                >{json['page-transferTable-0007'] || '序号-1'}
                </span>,
                attrcode: 'checkbox',
                itemtype: 'customer',
                dataIndex: 'checkbox',
                visible: true,
                fixed: 'left',
                width: isChild ? 70 : 80,
                className: 'col-customer-checkbox col-customer-total',
                render: (text, record, index) => {
                    return (
                        <span
                            bbq="普通表主合计"
                            title={multiLangTotal || '合计-4'}
                            style={{
                                display: 'block',
                                textAlign: 'left',
                                paddingRight: '13px',
                            }}
                        >{multiLangTotal || '合计-4'}
                        </span>
                    );
                },
            });
        }
        if (showIndex && (!isChild || (isChild && comType == 'hasCheckBox_child'))) {
            result.push({
                label: <span style={{
                    display: 'block',
                    textAlign: 'left',
                }}
                >{json['page-transferTable-0007'] || '序号-1'}
                </span>,
                attrcode: 'numberindex',
                itemtype: 'customer',
                dataIndex: 'numberindex',
                visible: true,
                width: indexWidth,
                className: 'col-customer-numberindex',
                render: (text, record, index) => {
                    return <span style={{}} />;
                },
            });
        }
        column.map(item => {
            let width = item.width;
            if (!width) {
                width = 120;
            }
            let render = null;
            if (item.itemtype !== 'customer' && item.visible && !item.render) {
                render = (text, record, index) => {
                    if (record[item.attrcode]) {
                        let display = record[item.attrcode].display;
                        let value = record[item.attrcode].value;
                        let dom = '';
                        if (display || display === 0) {
                            dom = display;
                        } else {
                            dom = value;
                        }
                        if (isTimeType(item.itemtype)) {
                            if (item.itemtype == 'datetimepicker') {
                                dom = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                                dom = formatDatetime(dom, 'datetime');
                            } else if (item.itemtype == 'timepicker') {
                                dom = value;
                                dom = formatDatetime(dom, 'time');
                            } else {
                                dom = changeTime(value);
                                dom = formatDatetime(dom, 'date');
                            }
                        }

                        // 多语处理
                        if (item.itemtype === "residtxt") {
                            let langCode = getLangCode();
                            let loginLang = (item.languageMeta || []).filter(i => i.languageCode == langCode);
                            if (loginLang.length > 0) {
                                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                                if (
                                    record[item.attrcode + loginLang[0].index] &&
                                    record[item.attrcode + loginLang[0].index].value
                                ) {
                                    dom = record[item.attrcode + loginLang[0].index].value;
                                }
                            }
                        }

                        if (item.itemtype === 'number') {
                            dom = formatNumber(dom);
                        }
                        return (
                            <span className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : ''}`}>
                                <EllipsisToTooltip overlay={dom}>
                                    <span className={classnames({
                                        'single-line-and-ellipsis': this.state.transferTable[headTableId]['isEllipsis'],
                                        'three-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'],
                                        'mult-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'] && this.state.transferTable[headTableId]['bodyExpandAllInRow'],
                                    })}>{dom}</span>
                                </EllipsisToTooltip>
                            </span>
                        );

                    }
                };
            } else {
                if (item.itemtype === 'customer') {
                    render = item.render;
                } else {
                    render = (text, record, index) => {
                        return (
                            <EllipsisToTooltip
                                className="tooltip-word-color"
                                overlay={item.render.call(
                                    null,
                                    text,
                                    record,
                                    index,
                                )}
                            >
                                <div className={classnames(
                                    'col-customer-render-unit',
                                    {
                                        'single-line-and-ellipsis': this.state.transferTable[headTableId]['isEllipsis'],
                                        'three-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'],
                                        'mult-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'] && this.state.transferTable[headTableId]['bodyExpandAllInRow'],
                                    })
                                }>
                                    {item.render.call(null, text, record, index)}
                                </div>
                            </EllipsisToTooltip>
                        );
                    };
                }
            }
            if (item.visible && item.attrcode !== 'checkbox' && item.attrcode !== 'numberindex') {
                result.push({ ...item, render, width, key: item.attrcode, title: item.label });
            }
        });
        bool && result.push({
            label: <span>{oprationLabel}</span>,
            attrcode: 'operate',
            itemtype: 'customer',
            dataIndex: 'operate',
            visible: true,
            width: 100,
            render: (text, record, index) => {
                return <span />;
            },
        });
        return result;
    };

    /*
    * 设置已选模板
    * column：Array 模板数据
    * */
    createSelectColumns = (column, showIndex, indexWidth = 80) => {
        let { json } = this.state;
        let result = [];
        let { comType, headTableId } = this.props;
        let oprationLabel = json['page-transferTable-0006'] || '操作-1';
        // 这里如果用let render方法里接收不到对应的变量  会出现显示错误  写这里也不行  必须写到里面
        // var multiLangTotal = this.state.json["page-transferTable-0010"] || '合计-5';
        /*国际化处理 操作*/
        if (comType == 'hasCheckBox' || comType == 'hasCheckBox_child') {
            result.push({
                label: <span />,
                attrcode: 'checkbox',
                itemtype: 'customer',
                dataIndex: 'checkbox',
                visible: true,
                fixed: 'left',
                width: indexWidth,
                className: 'col-customer-checkbox col-customer-total',
                render: (text, record, index) => {
                    return <span title={this.state.json['page-transferTable-0010'] || '合计-5'}>{this.state.json['page-transferTable-0010'] || '合计-5'}</span>;
                },
            });
        }
        column.map(item => {
            let width = item.width;
            if (!width) {
                width = 120;
            }
            let render = null;
            if (item.itemtype !== 'customer' && item.visible && !item.render) {
                render = (text, record, index) => {
                    if (record[item.attrcode]) {
                        let display = record[item.attrcode].display;
                        let value = record[item.attrcode].value;
                        let dom = '';
                        if (display || display === 0) {
                            dom = display;
                        } else {
                            dom = value;
                        }
                        if (isTimeType(item.itemtype)) {
                            if (item.itemtype == 'datetimepicker') {
                                dom = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                                dom = formatDatetime(dom, 'datetime');
                            } else if (item.itemtype == 'timepicker') {
                                dom = value;
                                dom = formatDatetime(dom, 'time');
                            } else {
                                dom = changeTime(value);
                                dom = formatDatetime(dom, 'date');
                            }
                        }

                        // 多语处理
                        if (item.itemtype === "residtxt") {
                            let langCode = getLangCode();
                            let loginLang = (item.languageMeta || []).filter(i => i.languageCode == langCode);
                            if (loginLang.length > 0) {
                                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                                if (
                                    record[item.attrcode + loginLang[0].index] &&
                                    record[item.attrcode + loginLang[0].index].value
                                ) {
                                    dom = record[item.attrcode + loginLang[0].index].value;
                                }
                            }
                        }

                        if (item.itemtype === 'number') {
                            dom = formatNumber(dom);
                        }
                        return (
                            <span className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : ''}`}>
                                <EllipsisToTooltip overlay={dom}>
                                    <span className={classnames({
                                        'single-line-and-ellipsis': this.state.transferTable[headTableId]['isEllipsis'],
                                        'three-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'],
                                        'mult-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'] && this.state.transferTable[headTableId]['bodyExpandAllInRow'],
                                    })}>{dom}</span>
                                </EllipsisToTooltip>
                            </span>
                        );

                    }
                };
            } else {
                if (item.itemtype === 'customer') {
                    render = item.render;
                } else {
                    render = (text, record, index) => {
                        return (
                            <EllipsisToTooltip
                                className="tooltip-word-color"
                                overlay={item.render.call(
                                    null,
                                    text,
                                    record,
                                    index,
                                )}
                                noEllipsis
                            >
                                <div className={classnames(
                                    'col-customer-render-unit',
                                    {
                                        'single-line-and-ellipsis': this.state.transferTable[headTableId]['isEllipsis'],
                                        'three-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'],
                                        'mult-line-and-ellipsis': !this.state.transferTable[headTableId]['isEllipsis'] && this.state.transferTable[headTableId]['bodyExpandAllInRow'],
                                    })
                                }>
                                    {item.render.call(null, text, record, index)}
                                </div>
                            </EllipsisToTooltip>
                        );
                    };
                }
            }
            if (item.visible && item.attrcode !== 'checkbox' && item.attrcode !== 'numberindex') {
                result.push({ ...item, render, width, key: item.attrcode, title: item.label });
            }
        });

        result.push({
            label: <span>{oprationLabel}</span>,
            attrcode: 'operate',
            itemtype: 'customer',
            dataIndex: 'operate',
            visible: true,
            width: 100,
            render: (text, record, index) => {
                return <span />;
            },
        });
        return result;
    };

    render() {
        let {
            classByType,
            column,
            columns,
            comType,
            isHeadTotal,
            isFullTotal,
            createNewCol,
            createFullData,
            createNewData,
            currentTable,
            customAreaOfSelectedHead,
            fullColumns,
            getMasterData,
            getSelectedFullData,
            handleClearAllSelected,
            handleChangeView,
            showChangeViewBtn,
            handleGenerateClick,
            renderColumnsMultiSelect,
            selectArea,
            setSelectedColumns,
            showMasterIndex,
            showChildIndex,
            showSeletedArea,
            showSeletedBtn,
            showGenerateBtn,
            tableType,
            transferBtnText,
            transferTable,
            totalKey,
            totalTitle,
            isDrag,
            headTableId,
            fullTableId,
            bodyTableId,
            sortType,
            containerSelector,
            onExpandedRowsChange,
            meta,
            fieldid,
            _this,
            cancelCustomRightMenu,
            autoHeight,
            fieldId
        } = this.props;

        let selectedAmount = this.getSelectedAmount();
        let {
            json,
            LangData,
            listHeight, // 整个单据的高度 42为底部灰条高度 5预留滚动条的位置， 28表格头部的高度
            selectedListHeight,
        } = this.state;
        let { showFullTable } = currentTable;
        let c = !showFullTable ? columns : fullColumns;
        let isTotal = false,
            colsSettingParam = {};
        if (tableType === 'simple') {
            isTotal = isHeadTotal;
            colsSettingParam = getcolsSettingParam.call(_this, meta, headTableId);
        } else {
            if (!showFullTable) {
                isTotal = isHeadTotal;
                colsSettingParam = getcolsSettingParam.call(_this, meta, headTableId);
            } else {
                isTotal = isFullTotal;
                colsSettingParam = getcolsSettingParam.call(_this, meta, fullTableId);
            }
        }

        let showSelectedListCSS = !showSeletedArea || (showSeletedArea && !transferTable.selected.selectedShow) ? '' : 'showSelectedList'; // 已选列表转单css

        let indexWidthC = 80;
        if (!showSelectedListCSS) {
            indexWidthC = 45;
        }

        if (showSelectedListCSS && showFullTable) {
            indexWidthC = 60;
        }

        // 单来源 表头固定字段设置
        if (comType == 'hasCheckBox' || comType == 'hasCheckBox_child') {
            column = renderColumnsMultiSelect(columns, json, indexWidthC, !showFullTable && showSelectedListCSS ? 'selectedAndExpand' : '');
            fullColumns = renderColumnsMultiSelect(fullColumns, json, indexWidthC);
            classByType = 'hasCheckBox';
        }

        let totalColumn = this.createClumus(c, showMasterIndex, false, indexWidthC);
        let totalSelectedColumn = this.createSelectColumns(c, showMasterIndex, indexWidthC);
        selectedListHeight = autoHeight ? selectedListHeight : 400;
        listHeight = autoHeight ? listHeight : 400;
        let transferTableMainHeight = showSeletedArea && transferTable.selected.selectedShow ? selectedListHeight : listHeight;

        //外层容器宽度
        let containerWidth = document.querySelector(containerSelector) && document.querySelector(containerSelector).clientWidth;
        // 转单下方固定灰条的css
        // let fixedCss = this.transfertableMainIsModalSChild ? { position: 'absolute', bottom: 0, width: '100%' } : { width: containerWidth },
        //     // 全部列表css样式
        //     allListCSS = transferTable.selected.selectedShow ? {
        //         height: 0,
        //         width: 0,
        //         opacity: 0
        //     } : {};
        // 为啥要计算样式
        let fixedCss = this.transfertableMainIsModalSChild ? { position: 'absolute', bottom: 0, width: 'auto', left: '0', right: '0' } : { width: 'auto', left: '15px', right: '15px' },
            // 全部列表css样式
            allListCSS = transferTable.selected.selectedShow ? {
                height: 0,
                width: 0,
                opacity: 0,
            } : {};
        // 浏览器修正 滚动条多8px
        let browserDiff = this.myBrowser === 'Firefox' || this.myBrowser === 'IE' ? 8 : 0;
        // 有合计行的表格scroll高度
        let totalTableScrollYHeight = listHeight - tableHeaderHeight - 45 - 42 - (browserDiff * 2), // tableHeaderHeight表头高度 45合计行高度 10预留滚动条的高度 42 底部灰条的高度
            // 无合计行的表格scroll高度
            noTotalTableScrollYheight = listHeight - tableHeaderHeight - 42 - browserDiff,
            // 已选列表有合计行表格的scroll高度
            selectedTotalTableScrollYHeight = selectedListHeight - tableHeaderHeight - 45 - 42 - 54 - (browserDiff * 2), // 54 是已选列表界面头部按钮区域的高度
            // 已选列表无合计行表格的scroll高度
            selectedNoTotalTableScrollYHeight = selectedListHeight - tableHeaderHeight - 42 - 54 - browserDiff;

        // let tableBodyDefaultHeight =  - tableHeaderHeight - 45 - 42, // tableHeaderHeight表头高度 45合计行高度 10预留滚动条的高度 42 底部灰条的高度

        /*
            表格三种：
                单表、
                主子表【主表是子表公共信息的抽取、子表可以展开收起】、
                主子拉平【完整显示一行不做抽取】
         */

        const showExpandRender = () => {
            if (_this.state.transferTable['allSelected'] && _this.state.transferTable['allSelected'].params) {
                let allSelected = _this.state.transferTable['allSelected'];
                let params = allSelected.params;
                if (!params.length) {
                    return false;
                }
                let activeIndex = allSelected.activeTab;
                if (params[activeIndex].bodyTableId === bodyTableId) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }
        return (
            <div
                className={`transfertable-main transfertable-main-${this.myBrowser || 'chrome'}  ${isTotal ? 'transfertable-main-total-wrapper' : ''}`}
                style={{
                    width: '100%',
                    height: `${transferTableMainHeight ? (transferTableMainHeight) + 'px' : 'auto'}`,
                    position: 'relative',
                    // overflow: 'hidden'
                }}
                ref={this.transfertableMain}
            >
                <div className={['insertTable', classByType].join(' ')} >
                    {/* 全部列表 */}
                    <div style={allListCSS}>
                        <div fieldid={getSysFieldid(`${fieldid || headTableId}_table`)}>
                            {/* 单表和主子表 */}
                            {!showFullTable ? (
                                <ComplexTable
                                    columns={createNewCol({ column, json: this.state.json })}//模板
                                    data={createNewData(currentTable.outerData)}//渲染表格的行的数据
                                    expandedRowRender={((tableType === 'nest' || tableType === 'full') && showExpandRender()) ? this.expandedRowRender : undefined}//嵌套子表
                                    haveExpandIcon={tableType === 'nest' || tableType === 'full' ? this.haveExpandIcon.bind(this, headTableId, false) : undefined}//是否有展开子表的icon
                                    onExpandedRowsChange={onExpandedRowsChange}//点击展开子表icon的回调
                                    scroll={{ x: true, y: isHeadTotal ? totalTableScrollYHeight : noTotalTableScrollYheight }}
                                    //lazyload={false}//是否开启滑动加载
                                    isDrag={isDrag}//否是拖拽列
                                    // totalConfig={{ showTotal: true }}
                                    isTotal={isHeadTotal}//是否合计
                                    totalData={isHeadTotal ? this.processingTotalData(//合计的数据
                                        transferTable.selected,
                                        tableType,
                                        c,
                                        showFullTable,
                                        currentTable,
                                    ) : undefined}
                                    totalColumns={isHeadTotal ? totalColumn : undefined}//合计行的模板
                                    bodyStyle={{ height: isHeadTotal ? totalTableScrollYHeight : noTotalTableScrollYheight }}
                                    sort={sortType}//排序
                                    updateColumnHandle={updateColumnAfterSetting.bind(this)}//列设置更新模板的方法
                                    tableType='transfer'
                                    //折行回调
                                    foldingRowChange={isEllipsis => {
                                        _this.state.transferTable[headTableId]['isEllipsis'] = isEllipsis
                                        _this.setState({ transferTable: _this.state.transferTable })
                                    }}
                                    bodyDisplayInRow={_this.state.transferTable[headTableId]['isEllipsis']}
                                    bodyExpandAllInRow={_this.state.transferTable[headTableId]['bodyExpandAllInRow']}
                                    colsSettingParam={colsSettingParam}//列设置需要的参数
                                    cancelCustomRightMenu={cancelCustomRightMenu}
                                />
                            ) :
                                (
                                    // 显示主子拉平
                                    <ComplexTable
                                        columns={createNewCol({ column: fullColumns, bool: true, columnType: 'full', json: this.state.json })}
                                        data={createFullData(currentTable)}

                                        scroll={{ x: true, y: isFullTotal ? totalTableScrollYHeight : noTotalTableScrollYheight }}
                                        isTotal={isFullTotal}
                                        totalData={isFullTotal ? this.processingTotalData(
                                            transferTable.selected,
                                            tableType,
                                            c,
                                            showFullTable,
                                            currentTable,
                                        ) : undefined}
                                        lazyload={true}
                                        isDrag={isDrag}
                                        bodyStyle={{ height: isFullTotal ? totalTableScrollYHeight : noTotalTableScrollYheight }}
                                        totalColumns={isFullTotal ? totalColumn : undefined}
                                        sort={sortType}
                                        updateColumnHandle={updateColumnAfterSetting.bind(this)}
                                        tableType='transfer'
                                        //折行回调
                                        foldingRowChange={isEllipsis => {

                                            _this.state.transferTable[headTableId]['isEllipsis'] = isEllipsis
                                            _this.setState({ transferTable: _this.state.transferTable })
                                        }}
                                        bodyDisplayInRow={_this.state.transferTable[headTableId]['isEllipsis']}
                                        bodyExpandAllInRow={_this.state.transferTable[headTableId]['bodyExpandAllInRow']}
                                        cancelCustomRightMenu={cancelCustomRightMenu}
                                        colsSettingParam={colsSettingParam}
                                    />
                                )}
                        </div>
                    </div>
                    {/* 已选列表 */}
                    <div className={`${showSelectedListCSS} selected-list-wrapper`}>
                        <div className="selected-list">
                            <div className="header-area">
                                <span className="selected-icon-back-btn">
                                    <NCBackBtn
                                        onClick={this.switchSelectedAndAll}
                                        className="back-transfer-list"
                                    />
                                </span>
                                <span
                                    className="header-title"
                                >{this.state.json['page-transferTable-0002']}{/*国际化处理 已选列表*/}
                                </span>
                                <div className="header-right">
                                    <div className="header-right-inner">
                                        <span className="header-right-change-view-area">
                                            {showChangeViewBtn === true && tableType !== 'simple' ? (
                                                <NCToggleViewBtn
                                                    tooltips={[json['page-transferTable-0023'], json['page-transferTable-0022']]}
                                                    className="selected-toggle-view"
                                                    expand={!!showFullTable}
                                                    onClick={handleChangeView.bind(this)}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </span>

                                        <span className="header-right-custom-area">
                                            {
                                                customAreaOfSelectedHead && customAreaOfSelectedHead()
                                            }
                                        </span>
                                        <span className="clear-selected-item-btn">
                                            <NCButton className="item" fieldid="clearAll" onClick={handleClearAllSelected.bind(this)}>
                                                {
                                                    json['page-transferTable-0013'] || '清空'
                                                }
                                            </NCButton>
                                        </span>

                                    </div>
                                </div>
                            </div>
                            <div
                                className="content-area"
                                ref={this.transfertableSelectedList}
                            >
                                <div fieldid={getSysFieldid('selected-list_table')}>
                                    {!showFullTable ? (
                                        <ComplexTable
                                            className="insertTable transfer-table-selected-wrapper"
                                            columns={setSelectedColumns(createNewCol({
                                                column,
                                                bool: tableType === 'simple',
                                                json: this.state.json,
                                            }), this.state.json)}
                                            data={getMasterData(transferTable.selected.selectedData)}
                                            expandedRowRender={(tableType === 'nest' && showExpandRender()) ? this.expandedRowRenderSelect : undefined}
                                            haveExpandIcon={tableType === 'nest' ? this.haveExpandIcon.bind(this, headTableId, true) : undefined}
                                            onExpandedRowsChange={onExpandedRowsChange}
                                            rowKey={record => record.key}
                                            isTotal={isHeadTotal}
                                            // lazyload={false}
                                            isDrag={isDrag}
                                            totalData={isHeadTotal ? this.processingTotalData(
                                                transferTable.selected,
                                                tableType,
                                                c,
                                                showFullTable,
                                            ) : undefined}
                                            totalColumns={isHeadTotal ? totalSelectedColumn : undefined}
                                            scroll={{ x: true, y: isHeadTotal ? selectedTotalTableScrollYHeight : selectedNoTotalTableScrollYHeight }}
                                            bodyStyle={{ height: isHeadTotal ? selectedTotalTableScrollYHeight : selectedNoTotalTableScrollYHeight }}
                                            sort={sortType}
                                            updateColumnHandle={updateColumnAfterSetting.bind(this)}
                                            tableType='transfer'
                                            //折行回调
                                            foldingRowChange={isEllipsis => {
                                                _this.state.transferTable[headTableId]['isEllipsis'] = isEllipsis
                                                _this.setState({ transferTable: _this.state.transferTable })
                                            }}
                                            bodyDisplayInRow={_this.state.transferTable[headTableId]['isEllipsis']}
                                            bodyExpandAllInRow={_this.state.transferTable[headTableId]['bodyExpandAllInRow']}
                                            colsSettingParam={colsSettingParam}
                                            cancelCustomRightMenu={cancelCustomRightMenu}
                                        />
                                    ) : (
                                        // 主子拉平
                                        <ComplexTable
                                            className="insertTable transfer-table-selected-wrapper"
                                            lazyload={true}
                                            isDrag={isDrag}
                                            columns={setSelectedColumns(createNewCol({
                                                column: fullColumns,
                                                bool: true,
                                                columnType: 'full',
                                                json: this.state.json,
                                            }), this.state.json, true)}
                                            data={getSelectedFullData(transferTable.selected.selectedData)}
                                            scroll={{ x: true, y: isFullTotal ? selectedTotalTableScrollYHeight : selectedNoTotalTableScrollYHeight }}
                                            bodyStyle={{ height: isFullTotal ? selectedTotalTableScrollYHeight : selectedNoTotalTableScrollYHeight }}
                                            isTotal={isFullTotal}
                                            totalData={isFullTotal ? this.processingTotalData(
                                                transferTable.selected,
                                                tableType,
                                                c,
                                                showFullTable,
                                            ) : undefined}
                                            totalColumns={isFullTotal ? totalSelectedColumn : undefined}
                                            sort={sortType}
                                            updateColumnHandle={updateColumnAfterSetting.bind(this)}
                                            tableType='transfer'
                                            //折行回调
                                            foldingRowChange={isEllipsis => {
                                                _this.state.transferTable[headTableId]['isEllipsis'] = isEllipsis
                                                _this.setState({ transferTable: _this.state.transferTable })
                                            }}
                                            bodyDisplayInRow={_this.state.transferTable[headTableId]['isEllipsis']}
                                            bodyExpandAllInRow={_this.state.transferTable[headTableId]['bodyExpandAllInRow']}
                                            colsSettingParam={colsSettingParam}
                                            cancelCustomRightMenu={cancelCustomRightMenu}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showSeletedArea ? (
                    <div
                        className="fixed-bottom-outer"
                        style={fixedCss}
                    >
                        <div className="fixed-bottom-container bottom-area-container" fieldid={getSysFieldid('bottom-area')}>
                            {//普通多来源不显示查看已选按钮
                                showSeletedBtn ? (
                                    <div className="area-left">
                                        <NCButton className="middle-btn" fieldid={transferTable.selected.selectedShow ? 'alllist' : 'selected'} onClick={this.switchSelectedAndAll}>
                                            {transferTable.selected.selectedShow ? (this.state.json['page-transferTable-0003'] || '全部列表-1') : (this.state.json['page-transferTable-0004'] || '查看已选-1')}
                                        </NCButton>
                                    </div>
                                ) : (
                                    ''
                                )}
                            <div className="area-center">
                                <span>
                                    {/* <span>
                                        {LangData && LangData.get('page-transferTable-0015', { n: selectedAmount.masterAmount }) || `合计: ${selectedAmount.masterAmount}条`}
                                    </span> */}
                                    <span>
                                        {this.state.json['page-transferTable-0010']}
                                        :
                                        <span>{selectedAmount.masterAmount}</span>
                                        {this.state.json['page-transferTable-0017']}
                                    </span>
                                    {tableType !== 'simple' ? (
                                        <span
                                            style={{ marginLeft: 8 }}
                                        >
                                            {this.state.json['page-transferTable-0018']}
                                            :
                                            <span>{selectedAmount.bodyAmount}</span>
                                            {this.state.json['page-transferTable-0019']}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </span>
                            </div>
                            {selectArea ? (
                                <div className="area-custom">
                                    {selectArea && typeof selectArea == 'function' && selectArea()}
                                </div>
                            ) : (
                                ''
                            )}
                            {totalKey.length && totalTitle.length ? (
                                <span>
                                    {this.customAggregate()}
                                </span>
                            ) : ''}
                            {showGenerateBtn ? (
                                <div className="area-right">
                                    <NCButton
                                        fieldid={fieldId || "generate"}
                                        className="main-button button-component large-btn"
                                        onClick={handleGenerateClick.bind(this)}
                                        disabled={
                                            JSON.stringify(transferTable.selected.selectedData.masterSelData) === '{}'
                                        }
                                    >
                                        {transferBtnText}
                                    </NCButton>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default CreateTranferTable;
