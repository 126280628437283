import React from 'react';
import { Checkbox } from '@platform/base';
import { myBrowser, pageTo, formatAcuracy, isNullOrVoid, isFunction, ViewModel } from '@platform/api'
const timeTypes = ['datepicker', 'NCTZDatePickerStart', 'NCTZDatePickerEnd', 'timepicker', 'datetimepicker', 'rangepicker', 'datePickerNoTimeZone']
const displayTypes = ['select', 'radio', 'checkbox', 'refer']
function flat(arr) {
    let ret = [];
    let dirty = false;
    arr.forEach(item => {
        if (Array.isArray(item)) {
            dirty = true;
            ret.push(...item);
        } else {
            ret.push(item);
        }
    });
    return dirty ? flat(ret) : ret;
}

// 控制非选中行表格单元格的显示状态的复选框的勾选状态
export function checkboxSwitchValue(value, editStatus, json) {
    if (editStatus) {
        let bool = !!(value === 'true' || value === 'Y' || value === true);
        return <Checkbox disabled={true} checked={bool} />;
    } else {
        let bool = value === 'true' || value === 'Y' || value === true ? ((json && json['page-transferTable-0020']) || '是') : ((json && json['page-transferTable-0021']) || '否');
        return bool;
    }
}

//转单页面，在修改查询条件查询的时候，已选数据放在已选页面，不请空，一下逻辑是对比已选数据是否存在于当前数据中，控制表头全选按钮的状态
export function iscludeCurrentData(transferTable, headTableId) {
    console.time('测试用时');
    let selected = transferTable.selected,
        outerData = transferTable[headTableId].outerData,
        bodyData = transferTable[headTableId].bodyData,
        childSelData = selected.selectedData.childSelData,
        masterCheckedId = selected.masterCheckedId,
        isSelectAll = false, // 是否全选
        isIndeterminate = false; // 是否半选
    if (!outerData || !bodyData || !outerData.length) {
        return { isSelectAll, isIndeterminate };
    }

    bodyData = bodyData.data; // 子表数据对象
    let unSelectAll = false; // 不是全选

    // 主子表计数
    for (let item of outerData) {
        let hValues = item.values || item;
        if (hValues.saga_status && hValues.saga_status.value === '1') {
            // eslint-disable-next-line no-continue
            continue;
        }

        // 子表计数  获取所有子表数据
        let key = item.rowId,
            bdc = childSelData[key] || {},
            bd = bodyData[key] || { rows: [] },
            bdl = bd.rows.length,
            ioc = 0, // 当前主表下子表选中的数目
            sagaCount = 0;

        for (let i of bd.rows) {
            let values = i.values || i;
            if (values.saga_status && values.saga_status.value === '1') {
                sagaCount++;
                // eslint-disable-next-line no-continue
                continue;
            }
            if (bdc[i.childId]) {
                // 如果有子表选中就是半选
                isIndeterminate = true;
                // 如果有已选数据  就计数
                // 每个主表下子表的已选数目
                ioc++;
            }
        }

        // 是否有主表数据选中
        if (masterCheckedId.includes(key)) {
            // 已选的数据长度
            if (bdl === 0) {
                // 如果有条数据没有子表 且选中了
                isIndeterminate = true;
            }
            // 表格中已选子表数据与主表是否匹配
            if (bdl > 0 && ioc !== (bdl - sagaCount)) {
                // 有子表 但是子表没有全部选中 不能全选
                unSelectAll = true;
            }
        } else { // 如果主表没有选中
            if (bdl === 0) {
                // 而且没有子表  不能全选
                unSelectAll = true;
            } else {
                if (ioc !== bdl) {
                    // 有子表 但是子表没有全部选中 不能全选
                    unSelectAll = true;
                }
            }

        }
    }
    // 是否全选
    isSelectAll = !unSelectAll;

    console.timeEnd('测试用时');

    console.log(isIndeterminate, isSelectAll);

    return { isSelectAll, isIndeterminate };
}
/**
 * 根据表格行数动态计算表格的高度
 * @param {*Array} data 渲染转单表格的数据
 */
export function getExpandTableHeight(data, isTotal, wrapper, tableBody = false) {
    wrapper = wrapper || document;
    let expandTableHeight = 0;
    if (data && data.length) {
        let tableHead = wrapper.querySelector('.u-table-thead');
        let tbody = wrapper.querySelector('.u-table-tbody');
        let tableRow = tbody && tbody.querySelectorAll('.u-table-row')[0];
        let theadHeight = tableHead && tableHead.getBoundingClientRect().height;
        let trowHeight = tableRow && tableRow.getBoundingClientRect().height;
        let TotalRowHeight = isTotal ? 30 : 0;//合计行高度  合計行高度改爲了30
        let BodyMinHeight = 30;//UE定的表体最小高度为30
        let paddingHeight = 4 * 2;//上下padding为 2
        let scrollBarHeight = isTotal ? 16 : 8;// 预留滚动条高度
        let b = myBrowser();
        scrollBarHeight = b === 'Firefox' || b === 'IE' ? (isTotal ? 14 * 2 : 14) : scrollBarHeight;
        // console.log(isTotal, scrollBarHeight);
        if (tableRow && theadHeight) {
            let baseHeight = theadHeight + paddingHeight + TotalRowHeight + scrollBarHeight;
            // let baseHeight = theadHeight + TotalRowHeight;
            if (data.length < 11) { //最多展示10行
                if (data.length * trowHeight < BodyMinHeight) {
                    expandTableHeight = BodyMinHeight + baseHeight;
                } else {
                    expandTableHeight = data.length * trowHeight + baseHeight;
                }
            } else {
                expandTableHeight = 10 * trowHeight + baseHeight;
            }
            if (tableBody) {
                return data.length > 9 ? (expandTableHeight - baseHeight) : '';
            }
            return expandTableHeight;
        } else {
            return 0;
        }
    }
}

/**
 * 列设置后更新模板
 * @param {*Array} newColumn 列设置后的模板
 * @param {*} areaCode 表格的区域id
 */
export function updateColumnAfterSetting(newColumn, areaCode) {
    let _this;
    if (this.state.meta) {
        _this = this;
    } else if (this.props && this.props._this) {
        _this = this.props._this;
    }
    let meta = _this.state.meta;
    if (newColumn.length && areaCode) {
        let finalColumn = [...meta[areaCode].items];

        let sortIndexArr = [];
        let sortObjArr = [];
        newColumn.map(newItem => {
            meta[areaCode].items.map((oldItem, index) => {
                if (newItem.attrcode === oldItem.attrcode) {
                    oldItem.width = newItem.width;
                    oldItem.islock = newItem.islock;
                    oldItem.visible = newItem.visible;
                    sortIndexArr.push(index);
                    sortObjArr.push(oldItem);
                }
            });
        });
        sortIndexArr.sort((a, b) => a - b);
        sortIndexArr.forEach((item, index) => {
            finalColumn[item] = sortObjArr[index];
        });
        meta[areaCode].items = finalColumn;
        _this.setState({ meta });
    }
}

/**
* 获取表格底层列设置需要的模板参数

* @param {*String} areaCode 主表/拉平表 的区域编码
*/
export function getcolsSettingParam(meta, areaCode) {
    let areaCodeMeta = meta && meta[areaCode] || {};
    return {
        //appcode: pageTo.getAppCode(), //应用编码
        //pagecode: pageTo.getPageCode(), //页面编码
        appcode: areaCodeMeta.appcode || pageTo.getAppCode(),
        pagecode: areaCodeMeta.pagecode || pageTo.getPageCode(), //页面编码
        code: areaCodeMeta.templateCode || meta && meta.code, //模板编码
        pageid: areaCodeMeta.pageid || meta && meta.pageid, //模板主键   
        areaCode, //主表/拉平表 的区域编码
        areaId: areaCodeMeta.oid, //区域主键
    };
}

/**
 * 保存排序状态
 */
function saveSortStatus(transferTable, headTableId, sortInfoObj, sortData) {
    let currentTransferTable = transferTable[headTableId],
        currentTable = currentTransferTable[headTableId],
        selectedTable = currentTransferTable.selected,
        allSelectedTable = transferTable.allSelected;

    let parentRowId = sortData[0].parentRowId;

    currentTransferTable.sortInfoObj = sortInfoObj;
    // 判断表格类型 判断表格状态
    if (selectedTable.selectedShow || allSelectedTable.selectedShow) {
        if (currentTable.showFullTable || allSelectedTable.showFullTable) {
            selectedTable.fullSortInfoObj = sortInfoObj;
        } else if (parentRowId) {
            selectedTable[parentRowId] = {};
            selectedTable[parentRowId].childSortInfoObj = sortInfoObj;
        } else {
            selectedTable.mainSortInfoObj = sortInfoObj;
        }
    } else {
        if (currentTable.showFullTable || allSelectedTable.showFullTable) {
            currentTable.fullSortInfoObj = sortInfoObj;
        } else if (parentRowId) {
            currentTable[parentRowId] = {};
            currentTable[parentRowId].childSortInfoObj = sortInfoObj;
        } else {
            currentTable.mainSortInfoObj = sortInfoObj;
        }
    }
}

// 排序转单表 主子表数据
function sortTransferData(sortData, headTableId, _this, sortInfoObj) {
    let transferTable = Object.assign({}, _this.state.transferTable); // 这里是浅拷贝 所以才可以导致下面可以被静默更新
    let currentTransferTable = transferTable[headTableId][headTableId],
        outerData = currentTransferTable.outerData,
        bodyData = currentTransferTable.bodyData.data,
        showFullTable = currentTransferTable.showFullTable;

    let newOuterData = [],
        parentRowId = sortData[0].parentRowId,
        newBodyData = {};

    // 拉平时略微复杂  进行内外两层排序
    if (showFullTable) {
        if (outerData.length) {
            // let outerDataSign = {},
            //     bodyDataSign = {};
            // sortData.map(item => {
            //     // 没有子集
            //     if (!item.parentRowId || item.key === item.headKey) {
            //         // 这个find的方式去找  非常的不合适
            //         !outerDataSign[item.key] && newOuterData.push(outerData.find(v => { return v.rowId === item.key; }));
            //         outerDataSign[item.key] = item.key;
            //         !newBodyData[item.key] && (newBodyData[item.key] = { rows: [] });
            //     } else {
            //         // 这个find的方式去找  非常的不合适
            //         !outerDataSign[item.parentRowId] && newOuterData.push(outerData.find(v => { return v.rowId === item.parentRowId; }));
            //         outerDataSign[item.parentRowId] = item.parentRowId;
            //         !newBodyData[item.parentRowId] && (newBodyData[item.parentRowId] = { rows: [] });
            //         // 这个find的方式去找  非常的不合适
            //         if (!bodyDataSign[item.key]) {
            //             // 先搭好架子
            //             !bodyDataSign[item.key] && newBodyData[item.parentRowId].rows.push(bodyData[item.parentRowId].rows.find(v => { return v.childId === item.key; }));
            //             bodyDataSign[item.key] = item.key;
            //         }
            //     }
            // });
            // currentTransferTable.outerData = newOuterData;
            // currentTransferTable.bodyData.data = newBodyData;
            // console.log(newBodyData, newOuterData);
            currentTransferTable.fullSortData = sortData;
            _this.setState({ transferTable });
        }
        return newOuterData;
    }
    //当主子表排序之后,就初始化拉平表的排序信息
    delete currentTransferTable.fullSortData;
    delete currentTransferTable.fullSortInfoObj;
    //用parentRowId判断点击的是主表的数据还是子表的数据，有parentRowId说明排序子表
    if (parentRowId) { // 主子表  子表排序
        newBodyData = Object.assign({}, bodyData);
        if (bodyData) {
            let attrs = Object.keys(bodyData);
            let sortChanged = false;
            attrs.forEach(key => {
                if (key === parentRowId) {
                    sortChanged = true;
                }
            });
            if (sortChanged) {
                newBodyData[parentRowId].rows = sortData.map(val => {
                    let value = {
                        status: 0,
                        values: val,
                        childId: val.key,
                    };
                    return value;
                });
                currentTransferTable.bodyData.data = newBodyData;
                _this.setState({ transferTable });
            }
        }
    } else {
        if (outerData.length) { // 主子表  主表排序
            let isSort = sortData.some((val, index) => {
                if (outerData[index].values.key !== val.key) {
                    return true;
                }
            });
            if (isSort) {
                newOuterData = sortData.map(val => {
                    let value = {
                        status: '0',
                        values: val,
                        rowId: val.key,
                    };
                    return value;
                });
                currentTransferTable.outerData = newOuterData;
                // 这里不能直接更新 会导致 排序图标状态出错  如果要更新就要参考cardtable 动态设置排序状态
                _this.setState({ transferTable });
            }
        }
    }
}

// 排序转单已选列表 主子表数据
function sortTransferSelectedData(sortData, headTableId, _this, sortInfoObj) {

    let transferTable = Object.assign({}, _this.state.transferTable); // 这里是浅拷贝 所以才可以导致下面可以被静默更新
    let currentTransferTable = transferTable[headTableId][headTableId],
        outerData = currentTransferTable.outerData,
        bodyData = currentTransferTable.bodyData.data,
        showFullTable = currentTransferTable.showFullTable,
        selectedTable = transferTable[headTableId].selected,
        allSelectedTable = transferTable.allSelected;

    let newOuterData = [],
        parentRowId = sortData[0].parentRowId,
        newBodyData = {},
        selectedData = [];
    if (selectedTable.selectedShow) {
        selectedData = transferTable[headTableId].selected;
        // console.log('单选列表排序', selectedData, sortData);
    }
    if (allSelectedTable.selectedShow) {
        selectedData = transferTable.allSelected;
        // console.log('全选列表排序', selectedData, sortData);
    }
}

/**
 * 点击排序之后把排序后的数据重新放在state上
 * @param {*} sortData 排序之后底层返回的数据
 */
export function updateTransferDataAfterSort(sortData, headTableId, _this, sortInfoObj) {
    let transferTable = _this.state.transferTable,
        currentTransferTable = transferTable[headTableId],
        // currentTable = currentTransferTable[headTableId],
        selectedTable = currentTransferTable.selected,
        allSelectedTable = transferTable.allSelected;

    if (!sortData || !sortData.length || !sortInfoObj) {
        return false;
    }

    // 保存排序状态
    saveSortStatus(transferTable, headTableId, sortInfoObj, sortData);

    // 判断表格类型 判断表格状态
    if (selectedTable.selectedShow || allSelectedTable.selectedShow) {
        // 已选列表
        sortTransferSelectedData(sortData, headTableId, _this, sortInfoObj);
        // console.log(selectedTable, allSelectedTable);
    } else {
        // 主转单表
        sortTransferData(sortData, headTableId, _this, sortInfoObj);
    }

}
/**
 * 获取body的clientHeight值
 * 备注：转单内部使用
 */
export function getScrollTop() {
    var scrollTop = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.clientHeight;
    } else if (document.body) {
        scrollTop = document.body.clientHeight;
    }
    return scrollTop;
}

/**
 *  设置转单组件自适应宽高
 * @param {*Boolean} isIntl 页面是否初始化 （didMount的时候会设置为true）
 * @param {*Boolean} windowResize 窗口是否在变化宽高
 * @param {*Boolean} selectedShow 已选列表显示状态
 * @param {*Number} transferTableTop 转单外层容器距在可视区的top值
 * @param {*Number} windowHeight 页面可视区的高度clientHeight
 * 备注：转单内部使用
 */
export function resizeEvent(isIntl, windowResize, selectedShow, transferTableTop, windowHeight, that) {
    if (isIntl || windowResize) { // 初始化或者窗口缩小的时候重新计算
        that.setSelectListHeightIsDone = false;
        that.setListHeightIsDone = false;
        that.setModalHeightIsDone = false;
    }
    that.setTimeIndex = setTimeout(() => {
        if (that.doing) that.transferTableIsInModal(); // 判断转单组件是否存在于弹框之内
        if (that.transfertableMainIsModalSChild) { // 如果在弹框中的时候 需要先设置弹框的body高度 再设置转单的高度
            if (!that.setModalHeightIsDone) {
                let modalBodySelector = document.querySelector('.u-modal-body');
                // 先给modal设置高度
                if (modalBodySelector) {
                    modalBodySelector.style.padding = '0 15px';
                    modalBodySelector.style.height = (windowHeight - 70 * 2) + 'px';// 模态框header和足部的高度，以及距离屏幕顶部底部的距离 总共140
                    // 解决ie下  弹窗不计算的问题 by bbqin
                    let dialogDom = modalBodySelector.parentElement && modalBodySelector.parentElement.parentElement; //(u-modal-dialog)
                    dialogDom.style.overflow = 'auto';
                }
                that.setState({ modalBodyHeight: windowHeight - 70 * 2 }, () => {
                    that.setModalHeightIsDone = true;
                    // 因modal中转单会有个过渡间断才能在dom中的位置固定， 所以需要初始化的时候加个延迟
                    if (isIntl) {
                        that.showTransferTableTimer = setTimeout(() => {
                            that.setModalTransferTableHeight();
                        }, 200);
                    } else {
                        that.setModalTransferTableHeight();
                    }
                });
            } else {
                that.setModalTransferTableHeight();
            }
        } else {
            let transferTableHeight = 0;
            // 判断是设置全部列表的高度还是设置已选列表的高度
            if (selectedShow) {
                if (!that.setSelectListHeightIsDone) {
                    transferTableHeight = windowHeight - transferTableTop;
                    that.setState({
                        transferTableHeight,
                        selectedListHeight: transferTableHeight,
                    }, () => {
                        that.setSelectListHeightIsDone = true;
                    });
                }
            } else {
                if (!that.setListHeightIsDone) {
                    transferTableHeight = windowHeight - transferTableTop;
                    that.setState({
                        transferTableHeight,
                        listHeight: transferTableHeight,
                    }, () => {
                        that.setListHeightIsDone = true;
                    });
                }
            }
        }
    });

}

/**
 * 多来源有全部页签共用底部灰条的时候，监听多来源子的window.onresize后灰条计算的宽度
 * width:多来源的子的父级容器的宽度 Number
 * isMultiChild：转单是否为多来源的子 Boolean
 * 备注：转单内部使用
 */
export function getMultiChildChangeWidth(width, isMultiChild) {
    if (isMultiChild && width) {
        this.setState({ fixedWidth: width });
    }
}

/**
 * 点击表头部排序图标排序对列进行排序
 * @param {*} val 被排序的每一行的详细数据
 * @param {*Object} sortType: 
 *         { mode:'single', backSource:false ,sortFun:(a)=> {console.log(a)}}
*                   mode：值为multiple'(多列排序) 或'single'(单列排序)
                   backSource：false是前端排序，值为true为后端排序,默认false
                   sortFun：后端排序的回调函数
 * @param {*Function} customSortRule 自定义排序规则
* 备注：转单内部使用
 */
export function sortColumn(val, sortType, customSortRule) {
    let attrcode = val.attrcode,
        valType = val.itemtype;
    if (!isFunction(customSortRule)) {
        switch (true) {
            case valType === 'number':
                val.sorter = (front, behind) => {
                    const frontValue = front[attrcode] ? front[attrcode].value || '0' : '0';
                    const behindValue = behind[attrcode] ? behind[attrcode].value || '0' : '0';
                    return Number(frontValue) - Number(behindValue);
                };
                break;
            case timeTypes.includes(valType):
                val.sorter = (front, behind) => {
                    const frontValue = front[attrcode] ? front[attrcode].value || '0' : '0';
                    const behindValue = behind[attrcode] ? behind[attrcode].value || '0' : '0';
                    return Number(new Date(frontValue).getTime()) - Number(new Date(behindValue).getTime());
                };
                break;
            case displayTypes.includes(valType):
                val.sorter = (front, behind) => {
                    const frontValue = String(front[attrcode] ? front[attrcode].display || '' : '');
                    const behindValue = String(behind[attrcode] ? behind[attrcode].display || '' : '');
                    // if (behindValue > frontValue) {
                    //     return -1;
                    // } else if (behindValue < frontValue) {
                    //     return 1;
                    // } else {
                    //     return 0;
                    // }
                    return frontValue.localeCompare(behindValue);
                };
                break;
            case valType === 'label':
                val.sorter = (front, behind) => {
                    const fvalue = front[attrcode] ? front[attrcode].value || '' : '';
                    const fdisplay = front[attrcode] ? front[attrcode].display : '';
                    const bvalue = behind[attrcode] ? behind[attrcode].value || '' : '';
                    const bdisplay = behind[attrcode] ? behind[attrcode].display : '';
                    const frontValue = String(isNullOrVoid(fdisplay) ? fvalue : fdisplay);
                    const behindValue = String(isNullOrVoid(bdisplay) ? bvalue : bdisplay);
                    // if (behindValue > frontValue) {
                    //     return -1;
                    // } else if (behindValue < frontValue) {
                    //     return 1;
                    // } else {
                    //     return 0;
                    // }
                    return frontValue.localeCompare(behindValue);
                };
                break;
            case attrcode !== 'numberindex' && attrcode !== 'opr':
                val.sorter = (front, behind) => {
                    const frontValue = String(front[attrcode] ? front[attrcode].value || '' : '');
                    const behindValue = String(behind[attrcode] ? behind[attrcode].value || '' : '');
                    // if (behindValue > frontValue) {
                    //     return -1;
                    // } else if (behindValue < frontValue) {
                    //     return 1;
                    // } else {
                    //     return 0;
                    // }
                    return frontValue.localeCompare(behindValue);
                };
                break;
        }
    } else {
        customSortRule(val);
    }
    /**
     * columnInfo:排序的列的模板信息
     * type:排序的类型
     */
    val.sorterClick = (columnInfo, type) => { //排序的回调函数
        if (sortType && sortType.backSource === true && isFunction(sortType.sortFun)) { //后端排序
            sortType.sortFun(columnInfo, type);
        }
    };
}

/**
 * 把字数加千分位逗号或者去逗号
 * @param {*Number or String} val 传入需要转化的为带千分号逗号的字符串 或者需要转为数字的为带千分号逗号的字符串
 * @param {*Number or String} needType 转出的类型
 * 备注：转单内部使用
 */
export function thounsandTransfer(val, needType) {
    let newvalN,
        newvalS;
    if (val && val !== 'NaN' && val !== 'undefined' && val !== 'null') { //传入的值存在
        if ((needType === 'number' || needType === 'string')) {
            if (String(val).length > 4) {
                if (typeof val === 'number' && needType === 'string') { //数字转字符串
                    val = String(val);
                    let MTthounsand = val.slice(0, 3),
                        LTthounsand = val.slice(3, val.length);
                    newvalS = `${MTthounsand},${LTthounsand}`;
                    return newvalS;
                } else if (typeof val === 'string' && needType === 'number') { //字符串转数字
                    newvalN = Number(String(val).split(',').join(''));
                    return newvalN;
                } else {
                    return val;
                }
            } else {
                return val;
            }
        } else { //需要转为的不是数字或者字符串类型
            return null;
        }
    } else { //传入的值不存在
        if (needType === 'number') {
            return 0;
        } else if (needType === 'string') {
            return '';
        } else { //需要转为的不是数字或者字符串类型
            return null;
        }
    }
}

/**
 * 获取当前页签转单表格所有数据
 * @param {*String} id 当前页签转单的主表ID 【创建时候配置的headTableId】
 */
export function getTransferTableValue(id) {
    if (id) {
        return this.state.transferTable[id];
    } else {
        return this.state.transferTable;
    }
}

/**
 * 获取表格所有勾选数据
 * @param {*String} dataSource 缓存ID (如果传了dataSource则会先从缓存取获取表格所有勾选数据)
 * @param {*Boolean} isDeepClone 是否深克隆
 */
export function getTransferTableSelectedValue(dataSource, isDeepClone = false) {
    let dataCache = null;
    let transferTable = null;
    if (dataSource) {
        dataCache = ViewModel.getData(dataSource);
        transferTable = dataCache ? dataCache.transferTable : null;
    }
    let { allSelected, ...transfers } = this.state.transferTable;
    if (transferTable) {
        transfers = transferTable;
    }

    if (!transfers) {
        if (!dataSource) {
            return console.error('未传dataSource');
        }
        let { allSelected, ...transfers1 } = ViewModel.getData(dataSource);
        let result = {};
        for (let key of Object.keys(transfers1)) {
            if (!result.hasOwnProperty(key)) {
                result[key] = [];
            }
            let masterSelData = transfers1[key].selected.selectedData.masterSelData;
            let childSelData = transfers1[key].selected.selectedData.childSelData;
            let bodyTableId = transfers1[key][key].bodyTableId;
            if (transfers1[key][key].tableType === 'simple') { // 单表
                for (let id of Object.keys(masterSelData)) {
                    result[key].push(masterSelData[id].data);
                }
            } else { //主子表
                for (let headKey of Object.keys(childSelData)) {
                    let headArr = [],
                        bodyArr = [];
                    if (headArr.length == 0) {
                        headArr.push({ values: masterSelData[headKey].data });
                    }
                    Object.values(childSelData[headKey]).forEach(item => {
                        let val = item.data.values || item.data;
                        bodyArr.push({ values: val });
                    });
                    result[key].push({
                        head: { [key]: { rows: headArr } },
                        body: { [bodyTableId]: { rows: bodyArr } },
                    });
                }
            }
        }
        return JSON.parse(JSON.stringify(result));
    } else {
        let result = {};
        for (let key of Object.keys(transfers)) {
            if (key !== 'allSelected') {
                if (!result.hasOwnProperty(key)) {
                    result[key] = [];
                }
                let masterSelData = transfers[key].selected.selectedData.masterSelData;
                let childSelData = transfers[key].selected.selectedData.childSelData;
                let bodyTableId = transfers[key][key].bodyTableId;
                if (transfers[key][key].tableType === 'simple') { // 单表
                    for (let id of Object.keys(masterSelData)) {
                        result[key].push(masterSelData[id].data);
                    }
                } else { //主子表
                    for (let headKey of Object.keys(childSelData)) {
                        let headArr = [],
                            bodyArr = [];
                        if (headArr.length == 0) {
                            headArr.push({ values: masterSelData[headKey].data });
                        }
                        Object.values(childSelData[headKey]).forEach(item => {
                            let val = item.data.values || item.data;
                            bodyArr.push({ values: val });
                        });
                        result[key].push({
                            head: { [key]: { rows: headArr } },
                            body: { [bodyTableId]: { rows: bodyArr } },
                        });
                    }
                }
            }
        }
        return isDeepClone ? JSON.parse(JSON.stringify(result)) : result;
    }
}

/**
 * 设置转单表格数据
 * @param {*String} id         转单缓存数据的key
 * @param {*String} childCode 子表ID
 * @param {*Array} newData    转单原数据 [{},{},...]
 * @param {*String/Array} rowId     主表主键字段 如果是全部多来源需要传数组，其他页签主表主键放到数组中
 * @param {*String/Array} childId   子表主键字段 如果是全部多来源需要传数组，其他页签子表主键放到数组中
 * @param {*Function} callback 
 */
export function setTransferTableValue(id, childCode, newData, rowId, childId, callback) {
    // this.state.transferTable[id].selected = {
    //     selectedShow: false,
    //     masterAmount: 0, //主表已选数量
    //     bodyAmount: 0, //子表已选数量
    //     masterCheckedId: [], //存放已选主表id，用于复选框半选
    //     selectedData: {
    //         selAll: false,
    //         masterSelData: {},
    //         childSelData: {}
    //     }
    // };
    this.state.transferTable[id].selected.selectedData.selAll = false;
    if (!this.state.transferTable.hasOwnProperty('allSelected')) {
        // let tabActiveIndex = this.state.transferTable['allSelected'].activeTab
        this.state.transferTable['allSelected'] = {
            activeTab: '0', //当前高亮tab
            selectedShow: false, //显示已选列表
            masterAmount: 0, //主表已选数量
            bodyAmount: 0, //子表已选数量
            masterCheckedId: [], //存放已选主表id，用于复选框半选
            selIds: {}, //存放其他页签的主子表id
            showFullTable: false, //已选列表中是否显示主子拉平
            selectedData: {
                selAll: false,
                masterSelData: {},
                childSelData: {},
            },
        };
    }
    // 取已选数据进行对比操作
    let childSelData = this.state.transferTable[id].selected.selectedData.childSelData || {};
    let masterSelData = this.state.transferTable[id].selected.selectedData.masterSelData || {};
    let allChildSelData = this.state.transferTable.allSelected.selectedData.childSelData || {};
    let allMasterSelData = this.state.transferTable.allSelected.selectedData.masterSelData || {};

    let outerData = [];
    let bodyData = {};
    let rowKey,
        childKey;
    let relationship = {}; // 记录行数据是表头还是表体或者单表的某条数据， 方便以后通过接口设置勾选数据等操作
    if (typeof rowId === 'string' && typeof childId === 'string') {
        rowKey = rowId;
        childKey = childId;
    }
    //表格类型为单表
    if (this.state.transferTable[id][id].tableType === 'simple') {
        if (newData.rows && newData.rows.length > 0) {
            newData.rows.map((item, index) => {
                item.rowId = item.values[rowKey].value;
                item.values.key = item.rowId;
                item.values.rowIndex = index;
                relationship[item.rowId] = 'simple';
                outerData.push(item);
                // 数据同步到已选  bbqin
                if (masterSelData[item.rowId] && masterSelData[item.rowId]['data']) {
                    item.values.headTs = item.values.ts; // 其实应该使用合并数据的 但是这里判断太麻烦
                    masterSelData[item.rowId]['data'] = JSON.parse(JSON.stringify(item.values || {}));
                }
                if (allMasterSelData[item.rowId] && allMasterSelData[item.rowId]['data']) {
                    item.values.headTs = item.values.ts; // 其实应该使用合并数据的 但是这里判断太麻烦
                    allMasterSelData[item.rowId]['data'] = JSON.parse(JSON.stringify(item.values || {}));
                }
            });
        }
    } else {
        if (newData && newData.length > 0) {
            newData.forEach((item, index) => {
                let val = item.head[id].rows[0];
                //全部多来源主键字段不唯一，需要传入其他页签主键字段
                if (Array.isArray(rowId)) {
                    rowId.map(id => {
                        if (Object.keys(val.values).includes(id)) {
                            rowKey = id;
                        }
                    });
                }
                if (!val.rowId && val.rowId !== 0) {
                    if (!val.values[rowKey]) {
                        console.error('setTransferTableValue方法中，主表Id没找到');
                        return false;
                    }
                    val.rowId = val.values[rowKey].value;
                }
                val.values.key = val.rowId;
                val.values.rowIndex = index;
                relationship[val.rowId] = 'head';

                outerData.push(val);

                // 数据同步到已选  bbqin
                if (masterSelData[val.rowId] && masterSelData[val.rowId]['data']) {
                    val.values.headTs = val.values.ts; // 该死的ts  其实应该使用合并数据的 但是这里判断太麻烦
                    masterSelData[val.rowId]['data'] = JSON.parse(JSON.stringify(val.values || {}));
                }
                if (allMasterSelData[val.rowId] && allMasterSelData[val.rowId]['data']) {
                    val.values.headTs = val.values.ts; // 该死的ts 其实应该使用合并数据的 但是这里判断太麻烦
                    allMasterSelData[val.rowId]['data'] = JSON.parse(JSON.stringify(val.values || {}));
                }

                let bodyDataRows = item.body[childCode].rows.map((elem, ii) => {
                    //全部多来源主键字段不唯一，需要传入其他页签子表主键字段
                    if (Array.isArray(childId)) {
                        childId.map(cid => {
                            if (Object.keys(elem.values).includes(cid)) {
                                childKey = cid;
                            }
                        });
                    }
                    if (!elem.childId && elem.childId !== 0) {
                        if (!elem.values[childKey]) {
                            console.error('setTransferTableValue方法中，子表Id没找到');
                            //return false
                        }
                        elem.childId = elem.values[childKey] && elem.values[childKey].value;
                    }
                    elem.values.key = elem.childId;
                    elem.values.rowIndex = ii;
                    elem.values.parentRowId = val.rowId; //根据索引设置行数据的api要用到
                    relationship[elem.childId] = 'body';
                    // 数据同步到已选  bbqin
                    if (childSelData[val.rowId] && childSelData[val.rowId][elem.childId] && childSelData[val.rowId][elem.childId]['data']) {
                        let od = childSelData[val.rowId][elem.childId]['data'] || {};
                        childSelData[val.rowId][elem.childId]['data'] = JSON.parse(JSON.stringify(od.values ? elem : elem.values));
                    }
                    if (allChildSelData[val.rowId] && allChildSelData[val.rowId][elem.childId] && allChildSelData[val.rowId][elem.childId]['data']) {
                        let aod = allChildSelData[val.rowId][elem.childId]['data'] || {};
                        allChildSelData[val.rowId][elem.childId]['data'] = JSON.parse(JSON.stringify(aod.values ? elem : elem.values));
                    }
                    return elem;
                });
                bodyData[val.rowId] = { rows: bodyDataRows };
            });
        }

    }
    //保存服务端原始数据，用于afterEvent最后一个参数
    // this.serverOuterData = {
    //     ...this.serverOuterData,
    //     [id]: JSON.parse(JSON.stringify(outerData))
    // };
    // this.serverBodyData = {
    //     ...this.serverBodyData,
    //     [id]: JSON.parse(JSON.stringify(bodyData))
    // };

    this.state.transferTable[id][id].masterId = rowKey;
    this.state.transferTable[id][id].childId = childId;
    this.state.transferTable[id][id].outerData = outerData;
    this.state.transferTable[id][id].bodyData = { data: bodyData };
    linkage.call(this, id);
    if (this.transferTableDataSource) {
        ViewModel.setData(
            `${this.transferTableDataSource}_ID`,
            {
                headPk: rowId,
                bodyPk: childId,
            });
    }
    this.myState.transferTableRelationship = relationship; // 记录行数据是表头还是表体或者单表的某条数据， 方便以后通过接口设置勾选数据等操作
    this.setState({ transferTable: this.state.transferTable }, () => {
        callback && callback();
    });
}

/*
"refreshData": [{
  "saga_status": "1",
  "mny": "123",
  "cbillid": "123124",
  "ts": "2019-10-28 12:23:23"
 }, {
  "saga_status": "1",
  "mny": "444",
  "cbillid": "44444",
  "ts": "2019-10-28 12:23:23"
 }]
 */

/**34
 *  根据wensocket推送值，更新表格数据
 * refreshData
 */

export function updateDataByRefresh(tableId, pkname, refreshData, saga_errormesg) {
    let myTable = this.state.transferTable[tableId][tableId];
    if (typeof tableId == 'string' && myTable && Array.isArray(refreshData)) {
        let outerData = myTable.outerData,
            bodyData = myTable.bodyData,
            oLen = outerData.length,
            refreshLen = refreshData.length,
            bLen = bodyData && bodyData.data && Object.keys(bodyData.data),
            RD = {};

        if (!oLen || !refreshLen) return;
        // 数据转型
        refreshData.forEach((item, index) => {
            let obj = {};
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const val = item[key];
                    obj[key] = {
                        value: val.value || val,
                        display: val.display || val,
                    };
                }
            }
            RD[item[pkname]] = obj;
        });
        // 外层数据 过滤
        outerData.forEach((item, index) => {
            let values = item.values,
                pkn = values[pkname],
                pkv = pkn && pkn.value,
                rd = RD[pkv];
            if (rd && pkn) {
                // 合并數據
                values = Object.assign(values, rd);
                //有错误信息时，将错误信息数据放表格行
                rd['saga_status'] === '1' && (values.saga_errormesg = saga_errormesg);
            }
        });
        // 内存数据过滤
        if (bLen) {
            bodyData = bodyData.data;
            for (const key in bodyData) {
                if (bodyData.hasOwnProperty(key)) {
                    const element = bodyData[key];
                    element.rows.forEach((item, index) => {
                        let values = item.values,
                            pkn = values[pkname],
                            pkv = pkn && pkn.value,
                            rd = RD[pkv];
                        if (rd && pkn) {
                            // 合并數據
                            values = Object.assign(values, rd);
                            //有错误信息时，将错误信息数据放表格行
                            rd['saga_status'] === '1' && (values.saga_errormesg = saga_errormesg);
                        }
                    });
                }
            }
        }
        this.setState({ transferTable: this.state.transferTable });
    }
}

/**
 * 更新转单表格数据
 * @param {*String} id 转单主表ID
 * @param {*String} childCode 子表ID
 * @param {*Object} newData 转单原数据
 * @param {*String/Array} rowId 主表主键字段 如果是全部多来源需要传数组，其他页签主表主键放到数组中
 * @param {*String/Array} childId 子表主键字段 如果是全部多来源需要传数组，其他页签子表主键放到数组中
 */
export function updateTransferTableValue(id, childCode, newData, rowId, childId) {
    let bodyAmount = this.state.transferTable[id].selected.bodyAmount;
    let childSelData = this.state.transferTable[id].selected.selectedData.childSelData;
    let bodyData = this.state.transferTable[id][id].bodyData.data;
    for (let [key, values] of Object.entries(newData)) {
        if (bodyData.hasOwnProperty(key)) {
            let res = bodyData[key].rows.reduce((total, nowItem, index) => {
                total[nowItem.childId] = index;
                return total;
            }, {});
            let arr = Object.keys(res);
            for (let item of Object.keys(values)) {
                if (arr.includes(item)) {
                    let index = res[item];
                    bodyData[key].rows[index].values = { ...bodyData[key].rows[index].values, ...values[item] };
                } else {
                    bodyData[key].rows.push({
                        status: 0,
                        values: { headKey: key, key: item, ...values[item] },
                        childId: item,
                    });
                }
            }
        }
        if (childSelData.hasOwnProperty(key)) {
            for (let item of Object.keys(values)) {
                if (childSelData[key].hasOwnProperty(item)) {
                    childSelData[key][item].data = {
                        status: 0,
                        values: { ...childSelData[key][item].data.values, ...newData[key][item] },
                        childId: item,
                    };
                } else {
                    childSelData[key][item] = {
                        data: {
                            status: 0,
                            values: { headKey: key, key: item, ...newData[key][item] },
                            childId: item,
                        },
                        sel: true,
                    };
                    this.state.transferTable[id].selected.bodyAmount = bodyAmount + 1;
                }
            }
        }
    }
    this.setState({ transferTable: this.state.transferTable });
}

/**
 * 插入转单表格数据
 * [{rowId: "1001B110000000006RS1", status: "0", values: {}, children: [{ childId: "1001B110000000006RS1", status: "0", values: {} }] }]
 * @param {*String} outerTableId 转单主表ID
 * @param {*String} bodyTableId 子表ID
 * @param {*Object} newData 转单原数据 []
 * @param {*String/Array} outerRowId 主表主键字段 如果是全部多来源需要传数组，其他页签主表主键放到数组中
 * @param {*String/Array} childId 子表主键字段 如果是全部多来源需要传数组，其他页签子表主键放到数组中
 */
export function insertTransferTableValue(outerTableId, bodyTableId, newData = [], outerRowId = 'rowId', bodyId = 'childId') {

    if (Object.prototype.toString.call(newData) !== "[object Array]") {
        return console.warn('invalid data, please check');
    }

    const transferTable = this.state.transferTable;
    let bodyData = this.state.transferTable[outerTableId][outerTableId].bodyData.data;
    // 兼容多来源数据格式
    if (!bodyData) {
        this.state.transferTable[outerTableId][outerTableId].bodyData = { data: {} };
        bodyData = this.state.transferTable[outerTableId][outerTableId].bodyData.data;
    }

    let outerData = this.state.transferTable[outerTableId][outerTableId].outerData;
    // 取已选数据进行对比操作
    let childSelData = this.state.transferTable[outerTableId].selected.selectedData.childSelData || {};
    let masterSelData = this.state.transferTable[outerTableId].selected.selectedData.masterSelData || {};
    let allChildSelData = this.state.transferTable.allSelected.selectedData.childSelData || {};
    let allMasterSelData = this.state.transferTable.allSelected.selectedData.masterSelData || {};

    // 主表拉成obj
    const outerDataObj = outerData.reduce((prevResult, current) => {
        const { values, rowId } = current,
            newOuterRowId = rowId || (values[outerRowId] || {}).value;
        prevResult[newOuterRowId] = current;
        return prevResult;
    }, {});

    console.log(newData, transferTable, bodyData, outerData, outerDataObj);

    // 插入数据
    newData.forEach(outerRow => {
        let { rowId, values, index, children } = outerRow,
            newOuterRowId = rowId || (values[outerRowId] || {}).value;
        // 父级不存在 放置父级 存在 是否要更新
        if (!outerDataObj[newOuterRowId]) {
            let newOuterRow = JSON.parse(JSON.stringify(outerRow));
            newOuterRow.rowId = newOuterRowId;
            delete newOuterRow.children;
            newOuterRow.values.key = newOuterRowId;
            newOuterRow.values.rowIndex = index || outerData.length;
            newOuterRow.values.headKey = newOuterRowId;
            // newOuterRow.values.headTs = newOuterRow.values.ts;
            outerData.push(newOuterRow);
        }

        // 放置子集
        if (children && children.length > 0) {
            // 没有就先初始化下
            if (!bodyData[newOuterRowId]) {
                bodyData[newOuterRowId] = {};
                bodyData[newOuterRowId].rows = [];
            }

            let bodyRows = bodyData[newOuterRowId].rows;

            // 主表拉成obj
            const bodyRowsObj = bodyRows.reduce((prevResult, current) => {
                const { values, childId } = current,
                    newBodyRowId = childId || (values[bodyId] || {}).value;
                prevResult[newBodyRowId] = current;
                return prevResult;
            }, {});

            console.log('bodyRowsObjbodyRowsObj', bodyRowsObj);

            children.forEach(bodyRow => {
                let { childId, values, index } = bodyRow,
                    newBodyRowId = childId || (values[bodyId] || {}).value;

                // 没有这一项  有这一项  需不需要更新
                if (!bodyRowsObj[newBodyRowId]) {
                    bodyRow.values.key = newBodyRowId;
                    bodyRow.values.rowIndex = index;
                    bodyRow.values.headKey = newOuterRowId;
                    bodyRow.values.parentRowId = newOuterRowId;
                    bodyRow.childId = newBodyRowId;
                    bodyRows.push(bodyRow);
                }

                // TODO

            })
        }
    })
    // 先更新主表 子表数据

    // 再处理已选数据

    // 先处理单来源

    // 再处理多来源

    // 在处理已选状态  合计   统计

    // 再处理排序

    // 缓存

    this.setState({ transferTable: this.state.transferTable });
}

/**
 * 设置展开的子表数据
 * @param {*} id 转单主表ID
 * @param {*Object} record 主表当前行信息
 * @param {*Array/Object} datas 子表数据
 * @param {*String} rowId 子表行 主键字段
 */
export function setChildTransferTableData(id, record, datas, rowId) {
    let key = record.key;
    datas.rows.map((val, index) => {
        if (!val.rowId && val.rowId !== 0) {
            if (!val.values[rowId]) {
                console.error('传入数据中没有找到子表Id');
                return false;
            }
            val.rowId = val.values[rowId].value;
        }
        val.values.key = val.rowId;

    });

    if (this.state.transferTable[id].selected.selectedData.masterSelData[key]) {
        this.state.transferTable[id].selected.selectedData.childSelData[key] = {};
        datas.rows.map((item, index) => {
            this.state.transferTable[id].selected.selectedData.childSelData[key][item.values.key] = {
                sel: true,
                data: item,
            };
        });
    }
    this.setState({ transferTable: this.state.transferTable });
}

/**
 * 为实现多页签联动需要调用此方法
 * @param {*String} headID 主表ID
 */
export function linkage(headID) {
    if (this.state.transferTable.allSelected) {
        let selIds = this.state.transferTable.allSelected.selIds || {};
        let allMasterCheckedId = this.state.transferTable.allSelected.masterCheckedId || [];
        let bodyIDsValues = Object.values(selIds) || [];
        let bodyIDS = flat(bodyIDsValues);
        let activeTabData = this.state.transferTable[headID];
        let selectedData = activeTabData.selected;
        let masterCheckedId = selectedData.masterCheckedId;
        let tableType = activeTabData[headID].tableType;
        let bodyData = activeTabData[headID].bodyData.data;
        let outerData = activeTabData[headID].outerData || [];
        let masterSelData = selectedData.selectedData.masterSelData || {};
        let childSelData = selectedData.selectedData.childSelData || {};
        // ---------从这里开始将总体选中数据中在本页签未选中的时候选中，将本页签中选中全部页签未选中的数据去除 -----
        if (outerData.length) {
            for (let key of Object.keys(selIds)) {
                let activeIndex = null;
                outerData.forEach((item, index) => {
                    if (item.rowId === key) {
                        activeIndex = index;
                    }
                });
                if (activeIndex !== null) {
                    let bodyIds = selIds[key];

                    if (!masterSelData.hasOwnProperty(key)) {
                        masterSelData[key] = {
                            data: outerData[activeIndex].values,
                            sel: true,
                        };
                    }
                    if (activeTabData[headID].tableType !== 'simple') {
                        let bodyList = bodyData[key].rows; // 当前页签表头的表体数据
                        // 判断当前页签每一个表体数据是否存在于allSelected
                        for (let i of bodyIds) {
                            let bodyActiveIndex = null;
                            bodyList.map((item, index) => {
                                if (item.childId === i) {
                                    bodyActiveIndex = index;
                                }
                            });
                            if (bodyActiveIndex !== null) {
                                let data = bodyList[bodyActiveIndex].values;
                                if (!childSelData[key]) {
                                    childSelData[key] = {
                                        [i]: {
                                            sel: true,
                                            data: {
                                                childId: i,
                                                values: data,
                                            },
                                        },
                                    };
                                }
                                if (childSelData[key] && !childSelData[key][i]) {
                                    childSelData[key][i] = {
                                        sel: true,
                                        data: {
                                            childId: i,
                                            values: data,
                                        },
                                    };
                                }
                            }
                        }
                        // 特殊的主子表 是尚未处理  TODO  by bbqin
                        // if (childSelData[key]&&Object.keys(childSelData[key]).length === bodyList.length) {
                        if (!masterCheckedId.includes(key)) masterCheckedId.push(key);
                        // }
                    } else {
                        if (!masterCheckedId.includes(key)) masterCheckedId.push(key);
                    }

                }
            }
            // 移除表体多余选中的数据
            if (Object.keys(childSelData).length) {
                for (let key of Object.keys(childSelData)) {
                    for (let item of Object.keys(childSelData[key])) {
                        if (!bodyIDS.includes(item)) {
                            delete childSelData[key][item];
                            this.state.transferTable[headID].selected.selectedData.selAll = false;
                            this.state.transferTable[headID].selected.masterCheckedId = this.state.transferTable[headID].selected.masterCheckedId.filter(item => item !== key);
                            if (!Object.keys(childSelData[key]).length) {
                                delete childSelData[key];
                            }
                        }
                    }
                }
            }
            // 移除多余表头的数据
            for (let key of Object.keys(masterSelData)) {
                if (!selIds.hasOwnProperty(key) && tableType !== 'simple') {
                    delete masterSelData[key];
                    if (childSelData.hasOwnProperty(key)) {
                        delete childSelData[key];
                    }
                    this.state.transferTable[headID].selected.selectedData.selAll = false;
                    this.state.transferTable[headID].selected.masterCheckedId = this.state.transferTable[headID].selected.masterCheckedId.filter(item => item !== key);
                } else if (!allMasterCheckedId.includes(key) && tableType === 'simple') {
                    delete masterSelData[key];
                    this.state.transferTable[headID].selected.selectedData.selAll = false;
                    this.state.transferTable[headID].selected.masterCheckedId = this.state.transferTable[headID].selected.masterCheckedId.filter(item => item !== key);
                }
            }

            let { isCheckedOutData, isCheckedBodyData, isAllOutData, isAllBodyData } = iscludeCurrentData(this.state.transferTable[headID], headID);
            // 这里的判断有问题
            if (isCheckedOutData && isCheckedBodyData && isAllOutData && isAllBodyData) {
                selectedData.selectedData.selAll = true;
            }

            selectedData.bodyAmount = 0;
            for (let item of Object.values(selectedData.selectedData.childSelData)) {
                // 有个数据没有子数据  但是应该也算一行数据
                selectedData.bodyAmount += (Object.keys(item).length || 1);
            }
            selectedData.masterAmount = Object.keys(selectedData.selectedData.masterSelData).length;
            // ---------从这里结束将总体选中数据中在本页签未选中的时候选中 -----
        }
    }
}

/**
 * 切换视图
 * 备注：转单内部使用
 */
export function changeViewType() {
    let { transferTable } = this.state;
    for (let key of Object.keys(transferTable)) {
        if (key !== 'allSelected') {
            transferTable[key][key].showFullTable = !transferTable[key][key].showFullTable;
        } else {
            transferTable[key].showFullTable = !transferTable[key].showFullTable;
        }
    }
    this.setState({ transferTable: this.state.transferTable });
}

/**
 * 获取状态
 * @param {状态名称} name 
 * @param {表格id} tableId 
 */
export function getCurrentTransferTableStatus(name, tableId, dataSource) {
    let { transferTable } = this.state;
    if (dataSource) {
        let data = this.ViewModel.getData(dataSource);
        transferTable = data && data.transferTable ? data.transferTable : transferTable;
    }
    if (name === 'activeTab') {
        return transferTable.allSelected.activeTab;
    }
    if (name === 'showFullTable') {
        return tableId && transferTable[tableId] && transferTable[tableId][tableId].showFullTable;
    }
    return null;
}

/**
 * 下游卡片切换的时候需要专门处理滚动条，当选中的卡片不在当前视口（被隐藏了），则需要将当前卡片显示出来 
 * 备注：转单内部使用
 */
function changeScroll() {
    let windowHeight = document.clientHeight || document.body.clientHeight;
    let listSelector = document.querySelector('.transfer-list-main-wrapper');
    let activeSelector = document.querySelector('.active-transfer-list-item');
    if (listSelector && activeSelector) {
        let listTop = listSelector.getBoundingClientRect().top;
        let top = activeSelector.getBoundingClientRect().top;
        let offsetTop = activeSelector.offsetTop;
        let bottom = activeSelector.getBoundingClientRect().bottom;
        let height = bottom - top;
        if ((offsetTop + height) > (windowHeight - listTop - 48)) {
            let nowHeight = listSelector.scrollTop;
            if (typeof listSelector.scrollTo === 'function') {
                listSelector.scrollTo(0, bottom - windowHeight + nowHeight + 60);
            }

        } else {
            if (typeof listSelector.scrollTo === 'function') {
                listSelector.scrollTo(0, 0);
            }
        }
    }

}

/**
 * 获取已选列表显示状态
 * @param {*String} id 上游转单主表ID
 */
export function getSelectedListDisplay(id) {
    if (id === 'all') { //全部多来源已选列表
        return this.state.transferTable.allSelected && this.state.transferTable.allSelected.selectedShow;
    } else if (this.state.transferTable[id] && this.state.transferTable[id].selected) {
        return this.state.transferTable[id].selected.selectedShow;
    }
}

/**
 * 有全部页签的多来源设置已选列表的数据
 * @param {*String} headId 主表模板id
 * @param {*} bodyId 子表模板id
 * @param {*Array/Object} data 后台返回渲染转单表格的数据
 * @param {*String} rowId 主表主键
 * @param {*String} childId 子表主键
 */
export function setMultiSelectedValue(headId, bodyId, data, rowId, childId) {
    let { masterSelData, childSelData } = this.state.transferTable.allSelected.selectedData;
    data.map(item => {
        let headVal = item.head[headId].rows[0].values;
        let headIdVal;
        if (Array.isArray(rowId)) {
            rowId.map(id => {
                if (headVal.hasOwnProperty(id)) {
                    headIdVal = headVal[id].value;
                    headVal.key = headIdVal;
                }
            });
        }
        masterSelData[headIdVal] = {
            data: JSON.parse(JSON.stringify(headVal)),
            sel: true,
        };
        item.body[bodyId].rows.map(bodys => {
            let bodyIdVal;
            if (Array.isArray(childId)) {
                childId.map(bid => {
                    if (bodys.values.hasOwnProperty(bid)) {
                        bodyIdVal = bodys.values[bid] && bodys.values[bid].value;
                        bodys.childId = bodyIdVal;
                        bodys.values.key = bodyIdVal;
                    }
                });
            }
            if (!childSelData.hasOwnProperty([headIdVal])) {
                childSelData[headIdVal] = {
                    [bodyIdVal]: {
                        data: JSON.parse(JSON.stringify(bodys)),
                        sel: true,
                    },
                };
            } else {
                childSelData[headIdVal][bodyIdVal] = {
                    data: JSON.parse(JSON.stringify(bodys)),
                    sel: true,
                };
            }
        });
    });
    this.setState({ transferTable: this.state.transferTable });
}

/**
 * 业务组判断上游转单数据缓存是否存在
 * @param {*String} dataSource 转单缓存数据的id
 */
export function hasCache(dataSource) {
    let bool = false;
    let data = ViewModel.getData(dataSource);
    if (data) {
        if (data.transferTable) {
            bool = !!Object.keys(data.transferTable).length;
        }

    }
    return bool;
}

/**
 * 根据行索引设置行数据
 * 根据record.parentRowId来判断更新head还是body。
 * @param {*String} HeadTableAreaId 转单主表ID
 * @param {*Object} record 描述要设置的表格行的一个对象
 * @param {*Number} rowIndex 定位表格行的位置的索引
 */
export function setRowDataByIndex(HeadTableAreaId, record, rowIndex) {
    let transferTable = this.state.transferTable;
    if (HeadTableAreaId && rowIndex != null && record) {
        if (record.parentRowId) {
            let bodyKey = record.key;
            let headKey = record.parentRowId;
            transferTable[HeadTableAreaId][HeadTableAreaId].bodyData.data[record.parentRowId].rows[rowIndex].values = record;
            let selectedChildData = transferTable[HeadTableAreaId].selected.selectedData.childSelData;
            let allSelectedChildData = transferTable.allSelected.selectedData.childSelData;
            if (allSelectedChildData[headKey] && allSelectedChildData[headKey][bodyKey] && allSelectedChildData[headKey][bodyKey].data && allSelectedChildData[headKey][bodyKey].data.values) {
                allSelectedChildData[headKey][bodyKey].data.values = record;
            } else {
                if (allSelectedChildData[headKey] && allSelectedChildData[headKey][bodyKey]) allSelectedChildData[headKey][bodyKey].data = record;
            }
            if (selectedChildData[headKey] && selectedChildData[headKey][bodyKey] && selectedChildData[headKey][bodyKey].data && selectedChildData[headKey][bodyKey].data.values) {
                selectedChildData[headKey][bodyKey].data.values = record;
            } else {
                if (selectedChildData[headKey] && selectedChildData[headKey][bodyKey]) selectedChildData[headKey][bodyKey].data = record;
            }
        } else {
            let headKey = record.headKey || record.key;
            transferTable[HeadTableAreaId][HeadTableAreaId].outerData[rowIndex].values = record;
            let selectedMasterData = transferTable[HeadTableAreaId].selected.selectedData.masterSelData;
            let allSelectedMasterData = transferTable.allSelected.selectedData.masterSelData;
            if (selectedMasterData[headKey] && selectedMasterData[headKey].data.values) {
                selectedMasterData[headKey].data.values = record;
            } else {
                if (selectedMasterData[headKey]) selectedMasterData[headKey].data = record;
            }
            if (allSelectedMasterData[headKey] && allSelectedMasterData[headKey].data.values) {
                allSelectedMasterData[headKey].data.values = record;
            } else {
                if (allSelectedMasterData[headKey]) allSelectedMasterData[headKey].data = record;
            }
        }
        this.setState({ transferTable });
    } else {
        console.error('setRowDataByIndex方法缺失参数');
    }

}

/*
 * 根据pk设置选中的行
 * @param {*String} headTableId  转单主表ID
 * @param {*Array} pks  子表主键集合
 */
export function setTheCheckRows(headTableId, pks) {
    let transferTable = this.state.transferTable,
        allSelectedData = transferTable.allSelected,
        selectedData = transferTable[headTableId].selected,
        data = transferTable[headTableId][headTableId],
        outerData = data.outerData,
        bodyData = data.bodyData.data,
        tableType = data.tableType,
        outerDataLength = outerData.length,
        allSelectedChildData = allSelectedData.selectedData.childSelData,
        selectedChildData = selectedData.selectedData.childSelData,
        selectedMasterData = selectedData.selectedData.masterSelData,
        allSelectedMasterData = allSelectedData.selectedData.masterSelData;

    if (tableType === 'nest' || tableType === 'full') {
        let masterLen = 0;

        for (let [key, value] of Object.entries(bodyData)) {
            let length = value.rows.length;
            let bodyLen = 0;
            for (let item of value.rows) {
                if (pks.includes(item.childId)) {
                    if (!selectedChildData[key]) selectedChildData[key] = {};
                    if (!allSelectedChildData[key]) allSelectedChildData[key] = {};
                    if (!allSelectedData.selIds[key]) allSelectedData.selIds[key] = [];
                    if (!allSelectedData.selIds[key].includes(item.childId)) allSelectedData.selIds[key].push(item.childId);
                    let masterData = outerData.filter(item => item.rowId === key)[0];
                    selectedChildData[key][item.childId] = {
                        data: {
                            childId: item.childId,
                            values: item.values,
                        },
                        sel: true,
                    };
                    allSelectedChildData[key][item.childId] = {
                        data: {
                            childId: item.childId,
                            values: item.values,
                        },
                        sel: true,
                    };
                    selectedData.bodyAmount += 1;
                    allSelectedData.bodyAmount += 1;
                    bodyLen += 1;
                    if (!selectedMasterData[key]) {
                        selectedData.masterAmount += 1;
                        masterLen += 1;
                        selectedMasterData[key] = {
                            sel: true,
                            data: { ...masterData.values },
                        };
                    }
                    if (!allSelectedMasterData[key]) {
                        allSelectedData.masterAmount += 1;
                        allSelectedMasterData[key] = {
                            sel: true,
                            data: { values: masterData.values },
                        };
                    }
                }
            }
            if (length === bodyLen && !selectedData.masterCheckedId.includes(key)) selectedData.masterCheckedId.push(key);
            if (length === bodyLen && !allSelectedData.masterCheckedId.includes(key)) allSelectedData.masterCheckedId.push(key);
        }
        if (outerDataLength === masterLen) {
            selectedData.selectedData.selAll = true;
        }
    } else {
        let masterLen = 0;
        for (let item of outerData) {
            let key = item.rowId;
            if (pks.includes(key)) {
                if (!selectedChildData[key]) selectedChildData[key] = {};
                if (!allSelectedChildData[key]) selectedChildData[key] = {};
                if (!allSelectedData.selIds[key]) allSelectedData.selIds[key] = [];
                if (!selectedMasterData[key]) {
                    selectedData.masterAmount += 1;
                    masterLen += 1;
                    selectedMasterData[key] = {
                        sel: true,
                        data: { ...item.values },
                    };
                }
                if (!allSelectedMasterData[key]) {
                    allSelectedData.masterAmount += 1;
                    allSelectedMasterData[key] = {
                        sel: true,
                        data: { values: item.values },
                    };
                }
                if (!selectedData.masterCheckedId.includes(key)) selectedData.masterCheckedId.push(key);
                if (!allSelectedData.masterCheckedId.includes(key)) allSelectedData.masterCheckedId.push(key);
            }
        }
        if (masterLen === outerDataLength.length) selectedData.selectedData.selAll = true;
    }
    let selAll = outerData.every(val => allSelectedData.masterCheckedId.includes(val.rowId));
    allSelectedData.selectedData.selAll = selAll;
    this.setState({ transferTable });
}

/*根据pk取消选中行的选中状态
 * @param {*String} headTableId 创建转单配置的headTableId，转单主表ID
 * @param {*Array} pks 传入要取消选中的主表ID
 */
export function cancelRowsChecked(headTableId, pks) {
    let transferTable = this.state.transferTable,
        allSelectedData = transferTable.allSelected,
        selectedData = transferTable[headTableId].selected,
        allSelectedChildData = allSelectedData.selectedData.childSelData,
        allSelectedMasterData = allSelectedData.selectedData.masterSelData,
        selectedChildData = selectedData.selectedData.childSelData,
        selectedMasterData = selectedData.selectedData.masterSelData;
    if (pks.length) {
        // selAll 是存在  selectData中的
        selectedData.selectedData.selAll = false;
        allSelectedData.selectedData.selAll = false;
        selectedData.masterAmount = selectedData.masterAmount - pks.length;
        selectedData.masterCheckedId = selectedData.masterCheckedId.filter(id => !pks.includes(id));
        allSelectedData.masterCheckedId = selectedData.masterCheckedId.filter(id => !pks.includes(id));
        allSelectedData.masterAmount = allSelectedData.masterAmount - pks.length;
        for (let attr in selectedChildData) { //删除selected里存的已选子表的数据
            if (pks.includes(attr)) {
                let childAmount = Object.keys(selectedChildData[attr]).length;//被取消选中的某条主表对应的子表数量
                selectedData.bodyAmount = selectedData.bodyAmount - childAmount;
                allSelectedData.bodyAmount = allSelectedData.bodyAmount - childAmount;
                for (let key in allSelectedData.selIds) {
                    console.log(allSelectedData.selIds);
                    if (pks.includes(key)) {
                        if (allSelectedData.selIds) delete allSelectedData.selIds[key];
                    }
                }
                if (allSelectedChildData[attr]) delete allSelectedChildData[attr];
                delete selectedChildData[attr];
            }
        }
        for (let attr in selectedMasterData) { //删除selected里存的已选主表的数据
            if (pks.includes(attr)) {
                if (allSelectedMasterData[attr]) delete allSelectedMasterData[attr];
                delete selectedMasterData[attr];
            }
        }
    }
    this.setState({ transferTable });
}

/*
 * 根据headKey或者parentRowId设置表头数据
 *
 * @param HeadTableAreaId： String 转单主表ID
 * @param record：Object 新的表头行数据
 * @param headKey/parentRowId：String 需要更新的表头行唯一标识
 * */
export function updateHeadRowByKey(HeadTableAreaId, record, headKey) {
    let transferTable = this.state.transferTable;
    if (HeadTableAreaId && headKey != null && record) {
        let outerData = transferTable[HeadTableAreaId][HeadTableAreaId].outerData;
        let masterSelData = this.state.transferTable[HeadTableAreaId]['selected']['selectedData']['masterSelData'];
        let allSelected = transferTable['allSelected'];
        this.state.transferTable[HeadTableAreaId][HeadTableAreaId].outerData = outerData.map(item => {
            if (item.rowId === headKey) {
                item.values = { ...item.values, ...record };
                return item;
            } else {
                return item;
            }
        });
        if (masterSelData[headKey]) {
            this.state.transferTable[HeadTableAreaId]['selected']['selectedData']['masterSelData'][headKey].data = {
                ...masterSelData[headKey].data,
                ...record,
            };
        }
        if (allSelected) {
            let masterSelData = allSelected['selectedData']['masterSelData'];
            if (masterSelData[headKey]) {
                this.state.transferTable['allSelected']['selectedData']['masterSelData'][headKey].data = {
                    ...masterSelData[headKey].data,
                    ...record,
                };
            }
        }
        this.setState({ transferTable: this.state.transferTable });
    } else {
        console.error('setRowDataByIndex方法缺失参数');
    }

}

/*
 * 获取转单缓存数据
 * @param dataSource
 * */
export function getTransferTableCache(dataSource) {
    let data = ViewModel.getData(dataSource);

    return data ? data.transferTable : null;
}

/*
 * 根据pk删除单据数据
 * @param pk：String 下游单据保存后存在缓存中的pk值
 * */
export function deleteItems(pk) {
    let transferTable = this.state.transferTable;
    if (Array.isArray(pk)) {
        for (let values of Object.keys(transferTable)) {
            if (values !== 'allSelected') {
                for (let key of Object.keys(transferTable[values])) {
                    if (key !== 'selected' && key !== 'transformForm') {
                        let value = transferTable[values][key];
                        // value 可能未sortObj 会导致问题
                        let bodyData = value.bodyData && Object.keys(value.bodyData).length ? value.bodyData.data : null;
                        let outerData = value.outerData && value.outerData.length ? value.outerData : null;
                        if (outerData) {
                            transferTable[values][key].outerData = outerData.filter(item => {
                                return !pk.includes(item.rowId);
                            });
                        }
                        if (bodyData) {
                            for (let i of pk) {
                                if (bodyData.hasOwnProperty(i)) {
                                    delete bodyData[i];
                                } else {
                                    for (let ke of Object.keys(bodyData)) {

                                        bodyData[ke].rows = bodyData[ke].rows.filter(item => {
                                            return i !== item.childId;
                                        });
                                        if (!bodyData[ke].rows.length) {
                                            delete bodyData[ke];
                                            transferTable[values][key].outerData = outerData.filter(item => {
                                                return item.rowId !== ke;
                                            });
                                            // delete bodyData[ke].rows
                                        }
                                    }
                                }
                            }
                        }
                    } else if (key === 'selected') {
                        let value = transferTable[values][key];
                        let masterCheckedId = value.masterCheckedId;
                        let selectedData = value.selectedData;
                        let childSelData = selectedData.childSelData;
                        let masterSelData = selectedData.masterSelData;
                        // let bodyAmount = selectedData.bodyAmount;
                        // let masterAmount = selectedData.masterAmount;
                        masterCheckedId = masterCheckedId.filter(item => {
                            return !pk.includes(item);
                        });
                        for (let ke of Object.keys(masterSelData)) {
                            if (pk.includes(ke)) {
                                // selectedData.masterAmount -= 1
                                delete masterSelData[ke];
                            }
                        }
                        for (let ke of Object.keys(childSelData)) {
                            if (pk.includes(ke)) {
                                delete childSelData[ke];
                            } else {
                                for (let item of Object.keys(childSelData[ke])) {
                                    if (pk.includes(item)) {
                                        // selectedData.bodyAmount -= 1
                                        delete childSelData[ke][item];
                                        if (!Object.keys(childSelData[ke]).length) {
                                            delete childSelData[ke];
                                            if (masterSelData[ke]) {
                                                delete masterSelData[ke];
                                            }
                                            transferTable[values][key].masterCheckedId = masterCheckedId.filter(item => {
                                                return item !== ke;
                                            });
                                        }
                                    }
                                }
                            }
                        }
                        transferTable[values][key].masterAmount = masterCheckedId.length;
                        let a = 0;
                        for (let item of masterCheckedId) {
                            if (childSelData) {
                                if (childSelData[item]) {
                                    a += Object.keys(childSelData[item]).length;
                                }
                            }

                        }
                        transferTable[values][key].bodyAmount = a;
                    }
                }
            } else {
                let selIds = transferTable[values].selIds;
                let bodyAmount = transferTable[values].bodyAmount;
                let masterAmount = transferTable[values].masterAmount;
                for (let value of Object.keys(selIds)) {
                    let len = selIds[value].length;
                    selIds[value] = selIds[value].filter(item => {
                        return !pk.includes(item);
                    });
                    if (!selIds[value].length) {
                        delete selIds[value];
                        bodyAmount -= len;
                        masterAmount -= 1;
                    }
                }
            }
        }
    }
    this.setState({ transferTable });
}
//----------------------------------转单左侧列表相关API---------------------------//

/*
*   设置下游单据卡片数据
*   @id: String 下游单据卡片ID
*   @ data： Array/Object 转单卡片列表数据
* */
export function setTransferListValue(id, data, callback) {
    let transformForm = this.myState.transferList[id].transformForm;
    let transferListComponent = this.myState.transferListComponent;

    let lists = [],
        completeArr = [];
    transformForm.listData.data = data && data;
    if (data.length) transformForm.show = true;
    data && data.map(item => {
        lists.push({
            complete: false, //是否已处理
            data: item,
            browseData: {
                head: {},
                body: {},
                grandSons: {},
            },
        });
        completeArr.push(false);
    });

    transformForm.list = lists;
    transformForm.completeArr = completeArr;
    transformForm.activeIndex = 0;

    transferListComponent && transferListComponent.setState({ transformForm }, () => {
        let status = !transformForm.list[0].complete ? 'add' : transformForm.list[0].formStatus;
        this.onTransferItemSelected &&
            this.onTransferItemSelected(transformForm.list[0].data, transformForm.list[0].complete, transformForm.activeIndex, status);
        if (this.transferListCardHeadCode) {
            this.form.resetItemWidth(this.transferListCardHeadCode);
        }
    });
}

/*
*   获取下游单据卡片数据
*   @id: String 下游单据卡片ID
* */
export function getTransferListValue(id) {
    return this.myState.transferList[id];
}

/*
*   更新下游单据卡片数据
*   @id: String 下游单据卡片ID
*   @ data： Array/Object 要更新的转单卡片列表数据
* */
export function updateTransferListValue(id, data) {
    let transformForm = data.transformForm;
    let transferListComponent = this.myState.transferListComponent;
    this.myState.transferList[id] = data;
    transferListComponent && transferListComponent.setState({ transferList: this.myState.transferList }, () => {
        let status = !transformForm.list[0].complete ? 'add' : transformForm.list[0].formStatus;
        this.onTransferItemSelected &&
            this.onTransferItemSelected(transformForm.list[0].data, transformForm.list[0].complete, transformForm.activeIndex, status);
        if (this.transferListCardHeadCode) {
            this.form.resetItemWidth(this.transferListCardHeadCode);
        }
    });
}

/*
*   获取转单卡片显示状态 （当卡片数量只有一个的时候隐藏，当卡片数量大于等于二的时候显示）
*   @id: String 下游单据卡片ID
* */
export function getTransformFormDisplay(id) {
    if (this.myState.transferList[id] && this.myState.transferList[id].transformForm) {
        return this.myState.transferList[id] && this.myState.transferList[id].transformForm && this.myState.transferList[id].transformForm.show;
    }
}

/*
*   获取转单列表数量
*   @id: String 下游单据卡片ID
* */
export function getTransformFormAmount(id) {
    return this.myState.transferList[id] && this.myState.transferList[id].transformForm.list.length;
}

//获取单来源转单已选的主子表id（存放在ViewModel里的transferIds）
export function getTransferTableSelectedId() {
    let result = ViewModel.getData('transferIds');
    return result;
}
//由于可能有操作没有  通过转单下游按钮 所以可能需要手动存取一次数据（存放在ViewModel里的transferIds）
export function setTransferTableSelectedId(headTableId, { isMultiChild, cacheItem, generateKeys, bodyPkIds, headPkIds }) {
    //总的转单的数据
    let transferTable = this.state.transferTable[headTableId];
    let selIds = [];
    let selId = [];
    if (!isMultiChild) { // 转单不是多来源的子
        //获取已选列表主子表id
        let masterData = transferTable.selected.selectedData.masterSelData;
        let childSelData = transferTable.selected.selectedData.childSelData;
        if (masterData) {
            for (let key of Object.keys(masterData)) {
                let bodys = [];
                let head = {};
                if (childSelData) {
                    let childData = transferTable.selected.selectedData.childSelData[key];
                    if (childData) {
                        for (let id of Object.keys(childData)) {
                            let values = childData[id].data.values || childData[id].data;
                            let itemsJson = {};
                            if (cacheItem) {
                                cacheItem.forEach(item => {
                                    let itemValue = values[item];
                                    if (itemValue) { //选中的子表数据里有要缓存的字段，
                                        itemsJson[item] = itemValue;
                                    } else { //选中的子表数据里有要缓存的字段就从主表数据里找
                                        let masterSelData = transferTable.selected.selectedData.masterSelData[key];
                                        let masterSelDataVal = masterSelData.data.values || masterSelData.data;
                                        let masterItemValue = masterSelDataVal[item];
                                        masterItemValue && (itemsJson[item] = masterItemValue);
                                    }
                                });
                            }
                            if (generateKeys && generateKeys.body && generateKeys.body.length > 0) {
                                let bodyKeyVal = {};
                                for (let val of generateKeys.body) {
                                    bodyKeyVal[val] = values[val] && values[val].value;
                                }
                                bodys.push({
                                    bodyKeyVal,
                                    ...itemsJson,
                                });
                            } else {
                                bodys.push({
                                    pk: id,
                                    ts: values.ts && values.ts.value,
                                    ...itemsJson,
                                });
                            }
                        }
                    }
                }
                if (generateKeys && generateKeys.head && generateKeys.head.length > 0) {
                    for (let val of generateKeys.head) {
                        head[val] = masterData[key].data[val] && masterData[key].data[val].value;
                    }
                } else {
                    head = {
                        pk: key,
                        ts: masterData[key].data.headTs ? masterData[key].data.headTs.value : masterData[key].ts,
                    };
                }
                let itemsJson = {};
                if (cacheItem) {
                    cacheItem.forEach(item => {
                        let itemValue = masterData[key].data[item];
                        itemValue && (itemsJson[item] = itemValue);
                    });
                }
                selIds.push({
                    head,
                    bodys,
                    ...itemsJson,
                });
            }
        }

        ViewModel.setData('transferIds', selIds);
    } else { // 转单是多来源的子
        let allTranferTableData = this.state.transferTable;
        let selfMasterData = transferTable.selected.selectedData.masterSelData;
        let selfChildData = transferTable.selected.selectedData.childSelData;

        for (let ID of Object.keys(allTranferTableData)) {
            if (ID === 'allSelected') {
                let selected = allTranferTableData.allSelected;
                let masterData = JSON.parse(JSON.stringify(selected.selectedData.masterSelData));
                let childData = JSON.parse(JSON.stringify(selected.selectedData.childSelData));
                let bodys = [];
                let selfBodys = [];
                for (let key of Object.keys(masterData)) {
                    if (childData[key]) {
                        for (let id of Object.keys(childData[key])) {
                            let values = childData[key][id].data.values || childData[key][id].data;
                            bodyPkIds &&
                                bodyPkIds.map(pk => {
                                    let cData = childData[key][id].data.values || childData[key][id].data;
                                    if (cData.hasOwnProperty(pk)) {
                                        bodys.push({
                                            [pk]: id,
                                            pk: id,
                                            ts: values.ts && values.ts.value,
                                        });
                                    }
                                });
                        }
                    }
                    if (selfChildData[key]) {
                        for (let id of Object.keys(selfChildData[key])) {
                            let values = selfChildData[key][id].data.values || selfChildData[key][id].data;
                            bodyPkIds &&
                                bodyPkIds.map(pk => {
                                    let cData = selfChildData[key][id].data.values || selfChildData[key][id].data;
                                    if (cData.hasOwnProperty(pk)) {
                                        selfBodys.push({
                                            [pk]: id,
                                            pk: id,
                                            ts: values.ts && values.ts.value,
                                        });
                                    }
                                });
                        }
                    }
                    headPkIds &&
                        headPkIds.map(pk => {
                            if (masterData[key].data.hasOwnProperty(pk)) {
                                selIds.push({
                                    head: {
                                        [pk]: key,
                                        pk: key,
                                        ts: masterData[key].data.ts.value,
                                    },
                                    bodys,
                                });
                            }

                            if (selfMasterData[key] && selfMasterData[key].data.hasOwnProperty(pk)) {
                                selId.push({
                                    head: {
                                        [pk]: key,
                                        pk: key,
                                        ts: selfMasterData[key].data.ts.value,
                                    },
                                    bodys: selfBodys,
                                });
                            }
                        });
                    bodys = [];
                }
            }
        }
        ViewModel.setData('multiTransferIds', selIds);
        ViewModel.setData('transferIds', selId);
    }
}

/*
*   获取多来源所有选中的数据的主子表主键对应的集合
*   @dataSource: String 缓存ID
* */
export function getTransferTableAllSelectedId(dataSource) {
    let dataCache = null;
    let transferTable = null;
    if (dataSource) {
        dataCache = ViewModel.getData(dataSource);
        transferTable = dataCache ? dataCache.transferTable : null;
    }
    let { allSelected, ...transfers } = this.state.transferTable;
    if (transferTable) {
        transfers = transferTable;
    }
    let ids = {};
    for (let items of Object.keys(transfers)) {
        if (items !== 'allSelected') {
            let selectedData = transfers[items].selected.selectedData;
            let childSelData = selectedData.childSelData;
            let masterSelData = selectedData.masterSelData;
            ids[items] = {
                head: Object.keys(masterSelData),
                body: {},
            };
            for (let item of Object.keys(childSelData)) {
                ids[items].body[item] = Object.keys(childSelData[item]);
            }
        }
    }
    return ids;
}

//获取多来源已选的主子表id（存放在ViewModel里的multiTransferIds）
export function getTransferTableMultiSelectedId() {
    let result = ViewModel.getData('multiTransferIds');
    return result;
}

/*
*   获取下游转单卡片列表当前选中的卡片处理状态
*   @id: String 下游转单卡片列表ID
*   @index: Number/String 非必须 要查看卡片的index
* */
export function getTransformFormCompleteStatus(id, index) {
    let actIndex = index || this.myState.transferList[id].transformForm.activeIndex;
    if (this.myState.transferList &&
        this.myState.transferList[id] &&
        this.myState.transferList[id].transformForm.list &&
        this.myState.transferList[id].transformForm &&
        this.myState.transferList[id].transformForm.list &&
        this.myState.transferList[id].transformForm.list[actIndex]
    ) {
        return this.myState.transferList[id].transformForm.list[actIndex].complete;

    } else {
        return false;
    }
}

/* 
*   返回下游转单卡片列表待选列表
*   @id: String 下游转单卡片列表ID
* */
export function returnTransferTableList(id) {
    let { transferList } = this.myState;
    let transferListComponent = this.myState.transferListComponent;

    if (transferList[id].selected) transferList[id].selected.selectedShow = false;
    transferList[id].transformForm.show = false;
    transferListComponent && transferListComponent.setState({ transferList }, () => {
    });
}

/*
*  获取转单列表是否存在未处理过的卡片
*   @headTableId: String 转单列表id
* */
export function getTransformFormStatus(Id, index) {
    if (typeof Id === 'undefined') {
        return false;
    }
    let transferList = this.myState.transferList[Id];
    let { activeIndex, list } = transferList.transformForm;
    let status = true;
    for (let item of list) {
        if (item.complete === false) {
            status = false;
        }
    }
    return status;
}

/* 
*   改变转单列表卡片处理状态
*   @status 转单状态 true:状态变为已转单 false:从转单列表移除该条数据
*   @headTableId: String  转单列表id
*   @childId: String  子表主键字段名
*   @index: String  当前操作数据的index
*   @onChange: String  操作成功后的回调函数
* */
export function setTransformFormStatus(headTableId, params) {
    let { status, index, onChange, isNext = true, isTriggerSelected = true } = params;
    let { headcode, bodycode } = this.myState.transferListAreas;
    if (typeof status === 'undefined' || typeof headTableId === 'undefined') {
        return false;
    }
    let transferList = this.myState.transferList[headTableId];
    let { activeIndex, list, completeArr } = transferList.transformForm;
    let transformIndex = index || activeIndex;//不传index默认为当前操作的index
    if (bodycode) updateLastActiveFormData.call(this, headTableId, headcode, bodycode, status);

    if (status) { //状态变为已转单
        if (list[transformIndex]) {
            list[transformIndex].complete = true;
            completeArr[transformIndex] = true;
            transferList.transformForm.currentData = list[transformIndex].data;//当前操作的数据
        }
    } else { //取消
        if (list.length > 0) {
            transferList.transformForm.currentData = list.splice(transformIndex, 1)[0].data;//取消的那条数据
            completeArr.splice(transformIndex, 1);
        }
    }

    if (list.length > 0) {
        this.transferTable.changeNextTransformForm(headTableId, status, onChange, isNext, headcode, list.length, isTriggerSelected);
    } else {
        //当前只有一条数据则返回列表
        this.transferTable.returnTransferTableList(headTableId, isNext);
    }
}

/**
 * 更新孙表的数据
 * @param {*} transferListId 
 * @param {*} data 
 * @param {*} status 
 * @param {*} index 
 */
export function updateActiveGrandsonData(transferListId, grandsonsData, status, index) {
    let transformForm = this.myState.transferList[transferListId].transformForm;
    let activeIndex = transformForm.activeIndex;
    let formStatus = status === true ? 'browse' : 'edit';
    let data = transformForm.list[index || activeIndex].data;
    if (data) {
        data.grandSons = JSON.parse(JSON.stringify(grandsonsData));
        if (formStatus === 'browse') {
            transformForm.list[activeIndex].browseData.grandSons = JSON.parse(JSON.stringify(grandsonsData));
        }
    }
}

/*
*   切换卡片的时候更新上次卡片的数据
*   @transferListId: String  转单列表id
*   @headcode: String  表头Form区域编码
*   @bodycode: String  表体表格区域编码
*   @status: Boolean  要处理的卡片的状态
* */
export function updateLastActiveFormData(transferListId, headcode, bodycode, status, onUpdateLastActiveFormData) {
    let transformForm = this.myState.transferList[transferListId].transformForm;
    let activeIndex = transformForm.activeIndex;
    let data = transformForm.list[activeIndex].data;
    let formStatus = status === true ? 'browse' : 'edit';
    let headData = this.form.getAllFormValue(headcode); // 获取form区域数据用于更新左侧head数据
    if (data && data.head && data.head[headcode]) {
        data.head[headcode].rows = headData.rows;
        transformForm.list[activeIndex].formStatus = formStatus;
        if (formStatus === 'browse') {
            transformForm.list[activeIndex].browseData.head = {
                [headcode]: {
                    // rows: JSON.parse(JSON.stringify(headData.rows)) 
                    rows: headData.rows, // 为了万行 暂时不拷贝
                },
            };
        }
    }
    if (typeof bodycode === 'string') {
        if (!transformForm.list[activeIndex].browseData.body) transformForm.list[activeIndex].browseData.body = {};
        let bodyData = (this.cardTable || this.props.cardTable).getAllRows(bodycode);// 获取table区域数据用于更新左侧body数据
        if (data && data.body && data.body[bodycode]) {
            data.body[bodycode].rows = bodyData;
            if (formStatus === 'browse') {
                transformForm.list[activeIndex].browseData.body[bodycode] = {
                    // rows: JSON.parse(JSON.stringify(bodyData))
                    rows: bodyData, // 为了万行 暂时不拷贝
                };
            }
        }
    } else if (Array.isArray(bodycode)) {
        // 报错问题的兼容
        if (data && data.body && !transformForm.list[activeIndex].browseData.body) transformForm.list[activeIndex].browseData.body = {};
        // 改了四次才改全
        if (data && data.bodys && !transformForm.list[activeIndex].browseData.bodys) transformForm.list[activeIndex].browseData.bodys = {};
        // 郭祉祺  丛可  
        // 后端数据的模型是bodys 我们一开始的设计方案是body 
        // 后来夏慧给我提要兼容 一主一子 一主多子
        // 然后导致以前的节点报错 所以这里先做下兼容 后面需要拉着所有转单的适配业务侧主题讨论下 
        for (let item of bodycode) {
            let bodyData = (this.cardTable || this.props.cardTable).getAllRows(item) || [];
            if (data && data.bodys && data.bodys[item]) {
                data.bodys[item].rows = bodyData;
                if (formStatus === 'browse') {
                    transformForm.list[activeIndex].browseData.bodys[item] = {
                        // rows: JSON.parse(JSON.stringify(bodyData))
                        rows: bodyData, // 为了万行 暂时不拷贝
                    };
                }
            }
            // 令人恶心的兼容
            if (data && data.body && data.body[item] && data.body[item].rows) {
                data.body[item].rows = bodyData;
                if (formStatus === 'browse') {
                    transformForm.list[activeIndex].browseData.body[item] = {
                        // rows: JSON.parse(JSON.stringify(bodyData))
                        rows: bodyData, // 为了万行 暂时不拷贝
                    };
                }
            }
        }
    }

    if (typeof onUpdateLastActiveFormData == 'function') {
        onUpdateLastActiveFormData(transformForm, headcode, bodycode);
    }
}

/*
*   取消的时候需要获取上次保存的表单表格区域的数据
*   @transferListId: String  转单列表id
*   @activeIndex: Number/String  上次保存状态的卡片数据
* */
export function getTheTransferListDataSavedLastTime(transferListId, activeIndex) {
    let transformForm = this.myState.transferList[transferListId].transformForm;
    let index = transformForm.activeIndex || activeIndex;
    let browseData = transformForm.list[index].browseData;
    return browseData;
}

/*
*   改变转单列表某个卡片的处理状态
*   @status 转单状态 true:状态变为已转单 false:从转单列表移除该条数据
*   @headTableId 主表id
*   @params Object {
*      status: Boolean, // 需要改变的卡片的状态
*      index: Number/String, // 需要改变的卡片的下标
*   }
* */
export function updateTransformFormStatus(headTableId, params) {
    let { status, index } = params;
    if (typeof status === 'undefined' || typeof headTableId === 'undefined') {
        return false;
    }
    let transferList = this.myState.transferList[headTableId];
    let { activeIndex, list, completeArr } = transferList.transformForm;
    let transformIndex = index || activeIndex;//不传index默认为当前操作的index
    if (status) { //状态变为已转单
        if (list[transformIndex]) {
            list[transformIndex].complete = true;
            list[transformIndex].formStatus = 'browse';
            completeArr[transformIndex] = true;
        }
    } else { //取消
        if (list.length > 0) {
            list[transformIndex].complete = false;
            list[transformIndex].formStatus = 'edit';
            completeArr[transformIndex] = false;
        }
    }
}

/* 
*   批量改变转单列表卡片状态
*   @headTableId：String 转单列表id
*   @status: Boolean 转单状态 true:状态变为已转单 false:从转单列表移除该条数据
*   @selId： Array 选中数据的id
*   @success 操作成功后的回调函数
* */
export function setMultiTransformFormStatus(headTableId, params) {
    let { status, selId, success } = params;
    if (typeof status === 'undefined' || typeof headTableId === 'undefined' || typeof selId === 'undefined') {
        return false;
    }
    let transferTable = this.state.transferTable[headTableId];
    let table = transferTable[headTableId];
    let { transformForm } = transferTable;
    let { selectedData, masterCheckedId } = transferTable.selected;
    let { activeIndex, list, completeArr } = transformForm;
    let oBodyData = table.bodyData.data;
    //批量保存
    if (Array.isArray(selId)) {
        selId.forEach(id => {
            if (status) {
                //保存后删除待选/已选列表对应数据
                for (let key of Object.keys(oBodyData)) {
                    oBodyData[key].rows.map((item, i, array) => {
                        if (item.rowId === id) {
                            array.splice(i, 1);
                        }
                    });
                    //子表无数据时删除主表
                    if (oBodyData[key].rows.length === 0) {
                        table.outerData.map((val, i, array) => {
                            if (val.rowId === key) {
                                array.splice(i, 1);
                            }
                        });
                    }
                }
            } else {
                //取消时删掉对应已选主表id
                for (let key of Object.keys(selectedData.childSelData)) {
                    let curHeadId = Object.keys(selectedData.childSelData[key]).includes(id) ? key : null;
                    if (masterCheckedId.indexOf(key) !== -1 && curHeadId === key) {
                        masterCheckedId.splice(masterCheckedId.indexOf(key), 1);
                    }
                }
            }

            //取消勾选状态
            for (let key of Object.keys(selectedData.childSelData)) {
                delete selectedData.childSelData[key][id];
                if (JSON.stringify(selectedData.childSelData[key]) === '{}') {
                    delete selectedData.childSelData[key];
                    //子表没有勾选数据时 取消主表勾选状态
                    delete selectedData.masterSelData[key];
                }
            }

        });
    }
    if (typeof success === 'function') {
        success();
    }
}

/* 
*   切换到（isNext控制是否切换到下一条）下一条转单钩子函数 在setTransformFormStatus方法内调用
*   @headTableId：String 转单列表ID
*   @status: Boolean 转单状态 true:状态变为已转单 false:从转单列表移除该条数据
*   @isNext: Boolean 是否要切换到下一条
*   @callback: Function 切换后的回调函数
*   @headcode 表头ID 
*   @flag 当前项
 */
export function changeNextTransformForm(headTableId, status, callback, isNext, headcode, flag, isTriggerSelected) {
    let transferList = this.myState.transferList[headTableId];
    let transferListComponent = this.myState.transferListComponent;

    let { transformForm } = transferList;
    let { activeIndex, completeArr } = transformForm;
    let currentIndex = activeIndex;
    let nextNotCompleteIndex = completeArr.indexOf(false, activeIndex);//下一个待处理转单
    let nextActiveIndex = nextNotCompleteIndex < 0 ? activeIndex : nextNotCompleteIndex;
    if (nextNotCompleteIndex < 0) {
        nextNotCompleteIndex = completeArr.indexOf(false);
        nextActiveIndex = completeArr.indexOf(completeArr[completeArr.findIndex((v, index) => index === activeIndex + 1)], activeIndex);
    }
    //没有待处理单据
    if (completeArr.every(item => item)) {
        //保存时保留在当前数据
        if (status) {
            nextActiveIndex = activeIndex;
        } else { //取消时没有待处理单据跳到第一条
            nextActiveIndex = 0;
        }
    } else {
        //有待处理数据时
        nextActiveIndex = nextNotCompleteIndex;
    }
    //如果找不到数组项 index设为数组的长度
    if (!transformForm.list[currentIndex]) {
        currentIndex = transformForm.list.length - 1;
    }
    nextActiveIndex = !isNext ? activeIndex : nextActiveIndex;
    transformForm.activeIndex = nextActiveIndex;
    transferListComponent && transferListComponent.setState({ transferList: this.myState.transferList },
        () => {

            let curData = transformForm.currentData && transformForm.currentData;
            let nextData = transformForm.list[nextActiveIndex] && transformForm.list[nextActiveIndex].data;
            let nextStatus = transformForm.list[nextActiveIndex] && transformForm.list[nextActiveIndex].complete;
            if (callback) {
                callback(curData, nextData, activeIndex);
            }
            let len = this.myState.transferList[headTableId].transformForm.activeIndex >= transformForm.list.length ? activeIndex : this.myState.transferList[headTableId].transformForm.activeIndex;
            changeScroll();
            let formStatus = !transformForm.list[nextActiveIndex].complete ? 'add' : transformForm.list[nextActiveIndex].formStatus;
            isTriggerSelected && this.onTransferItemSelected && this.onTransferItemSelected(
                nextData,
                nextStatus,
                len,
                formStatus,
            );
            // 转单表单重新计算列宽 add by wanglongx
            flag == 1 && this.form.resetItemWidth && this.form.resetItemWidth(headcode);
        },
    );
}

/* 
*   根据index设置转单列表左侧卡片数据
*   @id： String 列表Id  data:要更新的数据
*   @data： Object 要更新的数据
*   @index： String/Number 要更新数据的index
* */
export function setTransferListValueByIndex(id, data, index, isEdit) {
    let transferListComponent = this.myState.transferListComponent;

    this.myState.transferList[id].transformForm.list[index].data = data;
    transferListComponent && transferListComponent.setState({ transferList: this.myState.transferList });
}

/**
 * 更新transferList
 * @param {*} id 
 */
export function updateTransferList(id) {
    let transferListComponent = this.myState.transferListComponent;
    if (!id) {
        transferListComponent && transferListComponent.setState({ transferList: this.myState.transferList });
        console.log('自助更新');
    } else {
        let transformForm = this.myState.transferList[id].transformForm;
        //  TODO
    }
}

/*
*   根据index更新转单列表左侧卡片数据
*   @id： String 列表Id  data:要更新的数据
*   @data： Object 要更新的数据
*   @index： String/Number 要更新数据的index
* */
export function updateTransferListValueByIndex(id, data, index, isEdit, isNext = false) {
    let transformForm = this.myState.transferList[id].transformForm;
    let transferListComponent = this.myState.transferListComponent;

    let head = this.myState.transferList[id].transformForm.list[index].data.head;
    // let body = JSON.parse(JSON.stringify(this.myState.transferList[id].transformForm.list[index].data.body));
    let body = this.myState.transferList[id].transformForm.list[index].data.body; // 为了万行 暂不拷贝
    let newHead = data.head;
    let newBody = data.body;
    for (let items of Object.keys(newHead)) {
        if (newHead[items]) {
            if (head.hasOwnProperty(newHead[items].areacode)) {
                for (let item of head[items].rows) {
                    for (let ite of newHead[items].rows) {
                        item.values = Object.assign(item.values, ite.values);
                    }
                }
            }
        }
    }
    for (let items of Object.keys(newBody)) {
        if (newBody[items]) {
            if (body.hasOwnProperty(newBody[items].areacode)) {
                for (let item of body[items].rows) {
                    let len = newBody[items].rows.length;
                    for (let i = 0; i < len; i++) {
                        let ite = newBody[items].rows[i];
                        if (item.rowid === ite.rowid) {
                            newBody[items].rows[i].values = Object.assign(item.values, ite.values);
                        }
                    }
                }
                this.myState.transferList[id].transformForm.list[index].data.body[items].rows = newBody[items].rows;
            }
        }
    }
    transferListComponent && transferListComponent.setState({ transferList: this.myState.transferList }, () => {
        let list = this.myState.transferList[id].transformForm.list;
        let len = list.reduce((total, item) => {
            return total += item.complete ? 1 : 0;
        }, 0);
        if (this.myState.transferList[id].transformForm.list.length === 1) {
            this.onTransferItemSelected && this.onTransferItemSelected(this.myState.transferList[id].transformForm.list[0].data, this.myState.transferList[id].transformForm.list[0].complete, this.myState.transferList[id].transformForm.activeIndex, isEdit || 'browse');
        } else if (len === list.length) {
            this.onTransferItemSelected && this.onTransferItemSelected(this.myState.transferList[id].transformForm.list[transformForm.activeIndex].data, this.myState.transferList[id].transformForm.list[transformForm.activeIndex].complete, this.myState.transferList[id].transformForm.activeIndex, isEdit || 'browse');
        }

    });
    return this.myState.transferList[id].transformForm.list[index].data;
}

/*
* 清除选择数据事件*
* onClearAll： Function  全部消除后的回调函数
* */
export function clearAllSelectedData(onClearAll) {
    let { transferTable } = this.state;
    transferTable.allSelected.selectedData.masterSelData = {};
    transferTable.allSelected.selectedData.childSelData = {};
    transferTable.allSelected.selIds = {};
    transferTable.allSelected.masterCheckedId = [];//清空已选主表id
    transferTable.allSelected.masterAmount = 0;
    transferTable.allSelected.bodyAmount = 0;
    for (let key of Object.keys(transferTable)) {
        if (key !== 'allSelected') {
            transferTable[key].selected.masterCheckedId = [];//清空已选主表id
            transferTable[key].selected.selectedData.selAll = false;//取消全选
            transferTable[key].selected.selectedData.masterSelData = {};
            transferTable[key].selected.selectedData.childSelData = {};
            transferTable[key].selected.masterAmount = 0;
            transferTable[key].selected.bodyAmount = 0;
        }
    }
    this.setState({ transferTable }, () => {
        onClearAll && onClearAll();
    });
}

//----------------------------------下游转单相关API---------------------------//
/*
*   下游单据保存操作后，将保存过的卡片PK存在缓存中方便返回上游的时候去除
*   @pk 要删除的单据pk  tableType：单据表类型
*/
export function setSavedTransferTableDataPk(pk, DedataSource) {
    let dataSource = DedataSource || this.transferTableDataSource;
    let key = ViewModel.getData(
        `${dataSource}_PK`,
    ) || [];
    let dataType = typeof pk;
    if (dataType === 'string') {
        if (!key.includes(pk)) {
            key.push(pk);
            ViewModel.setData(
                `${dataSource}_PK`,
                key);
        }
    } else {
        for (let i of pk) {
            if (!key.includes(i)) {
                key.push(i);

            }
        }
        ViewModel.setData(
            `${dataSource}_PK`,
            key);
    }
}

/**
 * 刷新多来源没有全部页签的转单--提供给供应链的刘奇使用 节点：补货安排
 * 转单上下游不是在一个单页的时候，下游保存单据操作后，上游要移除那条数据，需要内部刷新转单，做到不显示移除的那条数据
 */
export function refreshTransferTable(type) {
    if (type === 'multi') {
        this.setState({ multiDelete: true });
    }
    if (type === 'single') {
        this.setState({ singleDelete: true });
    }
}

/**
* 转单上下游是多页
* 调转的时候做上游卸载的同操作 --提供给供应链的刘奇使用 节点：补货安排
 * @param {*} dataSource 转单缓存数据的key string
 */
export function whenTransferTableUnmount(dataSource, isMultiChild) {
    if (dataSource) {
        // let data = {
        //     transferTable: JSON.parse(JSON.stringify(this.state.transferTable)),
        // };
        let data = { transferTable: this.state.transferTable };
        // console.log(data);
        // 设置缓存数据
        this.ViewModel.setData(dataSource, data);
        if (!isMultiChild) {
            this.ViewModel.setData(
                `${dataSource}_PK`,
                []);
        }
    }
}
/*
 * 业务组删除缓存
 * dataSource： String 缓存ID
 * */
export function deleteCache(dataSource) {
    ViewModel.setData(dataSource, null);
    // ViewModel.setData(---没有看到转单代码哪里有用到，不是转单内部用的
    //     `${dataSource}_clearStatus`
    //     , true)
}

/**
 * 在转单下游卡片如果没有使用createTransferList方法 则调用此方法缓存 执行保存过得单据pk
 * @param dataSource
 * @param pk
 */
export function savePk(dataSource, pk) {
    let key = ViewModel.getData(
        `${dataSource}_PK`,
    ) || [];
    let dataType = typeof pk;
    if (dataType === 'string') {
        if (!key.includes(pk)) {
            key.push(pk);
            ViewModel.setData(
                `${dataSource}_PK`,
                key);
        }
    } else {
        for (let i of pk) {
            if (!key.includes(i)) {
                key.push(i);

            }
        }
        ViewModel.setData(
            `${dataSource}_PK`,
            key);
    }
}

/*
 * 获取下游转单执行保存过得单据pk
 * @param dataSource： String 缓存ID
 * */
export function getPkCache(dataSource) {
    return ViewModel.getData(
        `${dataSource}_PK`,
    );
}

// 千分位处理
/*
* num： String/Number 需要进行千分位处理的数值
* */
export function numberDispose(num) {
    let str = String(num);
    if (str === null || str === undefined) return '';
    let arr = str.split('.');
    let len = 0;
    if (arr.length > 1) len = arr[1].length;
    return formatAcuracy(str, len);
}

/*
 * 精度处理
 * value： String/Number 需要进行精度处理的数值
 * scale: String/Number 精度
 * */
export function formatDot(value, scale) {
    let dotSplit,
        val;
    if (value === true || value === false) {
        return value;
    }
    if (value === 'null') {
        return '';
    }
    if (!value) {
        return '';
    }
    scale = String(scale);
    if (!scale || scale === '-1') {
        return value;
    }

    val = (value || 0).toString();
    dotSplit = val.split('.');
    if (scale === '0') {
        return dotSplit[0];
    }
    if (dotSplit.length === 2) {
        if (dotSplit[1].length > scale) {
            dotSplit[1] = dotSplit[1].slice(0, Number(scale));
            return dotSplit.join('.');
        } else if (dotSplit[1].length === Number(scale)) {
            return value;
        } else {
            dotSplit[1] = dotSplit[1].padEnd(Number(scale), '0');
            return dotSplit.join('.');
        }
    } else {
        if (scale !== '0') {
            let start = value + '.';
            return start + ''.padEnd(Number(scale), '0');
        } else {
            return value;
        }
    }
}

/*
 * 获取value1和value2两个值精度较长的精度
 * value1： String/Number
 * value2: String/Number
 * */
export function getLongScale(value1, value2) {
    let arr1,
        arr2,
        len1,
        len2;
    arr1 = String(value1).split('.');
    arr2 = String(value2).split('.');
    len1 = arr1.length;
    len2 = arr2.length;
    if (len2 === 1 && len1 === 1) {
        return String(-1);
    } else if (len2 === 1 && len1 === 2) {
        return String(arr1[1].length);
    } else if (len1 === 1 && len2 === 2) {
        return String(arr2[1].length);
    } else if (len1 === 2 && len2 === 2) {
        let le1 = arr1[1].length;
        let le2 = arr2[1].length;
        if (le1 > le2) {
            return String(arr1[1].length);
        } else if (le1 <= le2) {
            return String(arr2[1].length);
        }
    }
}

export function getMaxLengthData(data, maxLength) {
    if (!data && data !== '0' && data !== 0) {
        return '';
    } else if (data === '0' || data === 0) {
        return data;
    }
    let result = String(data);

    if (typeof maxLength === 'number') {
        if (maxLength === -1) {
            return result;
        }
        if (result.length > maxLength) {
            return result.substr(0, maxLength) + '...';
        } else {
            return result;
        }
    } else if (typeof maxLength === 'string') {
        let len = Number.isNaN(Number(maxLength)) ? -1 : Number(maxLength);
        if (len > 0) {

            if (result.length > len) {
                return result.substr(0, len) + '...';
            } else {
                return result;
            }
        } else {
            return result;
        }

    } else {
        return result;
    }
}

