import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip as NCTooltip } from '@platform/base'
export default class EllipsisToTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible || false
        };
    }

    componentWillReceiveProps() {
        let selfDom = ReactDOM.findDOMNode(this);
        let wrapperDom = selfDom && (selfDom.parentNode || selfDom.parentElement || selfDom);
        if ((wrapperDom && wrapperDom.clientWidth < wrapperDom.scrollWidth) || this.props.noEllipsis) {
            this.setState({
                visible: true
            });
        } else {
            this.setState({
                visible: false
            });
        }
    }

    render() {
        let { children, ...other } = this.props;

        return (
            this.state.visible ? (
                <NCTooltip
                    {...other}
                    placement="left"
                    delay={1}
                >
                    {children}
                </NCTooltip>
            ) : children);
    }
}