import React, { Component } from 'react';
import { moment } from '@platform/api';
import { getMultiLang, DongbaToLocalTime, withViewModel, formatDatetime, formatNumber } from '@platform/api'
import { getScrollTop } from './methods';

@withViewModel

class CreateTranferList extends Component {
    constructor(props) {
        super(props);
        this.ViewModel = this.props.ViewModel;
        this.expandArrId = this.props.events && this.props.events.expandArr;
        this.state = {
            switchViewState: 'evenUp',
            transferList: null,
            transformForm: null,
            top: 0,
            json: {},
            LangData: null,
            height: 0,
        };
        this.props._this.myState.transferListComponent = this;
    }

    componentWillMount() {
        let callback = (json, bool, LangData) => {
            let { _this, dataSource, componentInitFinished } = this.props;
            this.setState({
                json,
                LangData,
            });
            if (dataSource) {
                let data = this.ViewModel.getData(dataSource);
                // let data = { transferTable: {} };
                // console.log("获取缓存pk数据", dataPk);
                if (data && data.transferTable) {
                    _this.setState({
                        transferTable: data.transferTable,
                    }, () => {
                        //执行数据初始化周期函数
                        if (typeof componentInitFinished == 'function') {
                            componentInitFinished();
                        }
                    });
                }
            }
        };
        getMultiLang({ moduleId: 'page-transferTable', callback })
    }

    componentDidMount() {
        let selector = document.querySelector('.transfer-list-main-wrapper');
        let $left = document.querySelector('.transfer-list-left');
        let $right = $left && $left.nextElementSibling;
        let width = selector ? (selector.offsetWidth - 2) : 0;
        $right && ($right.style.marginLeft = width + 'px');
        
        this.onresizeEvent();
        window.onresize = () => {
            this.onresizeEvent();
        };
    }

    componentWillReceiveProps(nextProps) {
        // 更新高度
        this.setState({
            resize: new Date().getTime()
        }, () => {
            // console.log("渲染次数", nextProps);
            this.onresizeEvent();
        });
    }

    componentDidUpdate() {
        let selector = document.querySelector('.transfer-list-main-wrapper');
        let $left = document.querySelector('.transfer-list-left');
        let $right = $left && $left.nextElementSibling;
        let width = selector ? (selector.offsetWidth - 2) : 0;
        $right && ($right.style.marginLeft = width + 'px');
    }

    /*
    * 下游转单卡片自适应
    * */
    onresizeEvent = () => {
        let selector = document.querySelector('.transfer-list-main-wrapper');
        if (selector) {
            this.setState({
                top: selector.offsetTop,
                height: getScrollTop() - selector.offsetTop,
            });
        }

    }

    /*
    * 卡片渲染
    *
    * */
    getTransferListDom = () => {
        let {
            _this,
            dataAreaCode,
            handleTransformListClick,
            transformForm, //下游转单的数据集合
            transferListId,
            // transformListMeta,
        } = this.props;
        if (!_this.state.meta) {
            return false;
        }
        if (!_this.state.meta[transferListId] || !_this.myState.transferList[transferListId]) {
            return false;
        }
        //转单模板信息
        let transformListMeta = _this.state.meta[transferListId].items;

        let { height, json, LangData } = this.state;
        let flag = transformForm.show && transformForm.list && transformForm.list.length > 1;
        // position: 'fixed', top: '42px', left: '0px', bottom: '16px'
        return (
            <div
                className={`transfer-list-main-wrapper ${flag ? 'show-tranferList' : 'hide-tranferList'} `}
                style={{ height: height - 16, overflow: 'hidden' }}
            >
                {
                    flag ? (
                        <div
                            className="transformtable-main"
                            style={{ height: height - 8, overflow: 'auto' }}
                        >
                            <div className="transform-list">
                                <ul style={{ paddingBottom: '48px' }}>
                                    {transformForm.list && transformForm.list.map((item, index) => {
                                        return (
                                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                            <li
                                                className={`${index === transformForm.activeIndex ? 'item active-transfer-list-item' : 'item'} ${item.complete && 'complete'}`}
                                                onClick={() => {
                                                    handleTransformListClick.call(this, item.data, index);
                                                    _this.myState.stopSetScroll = false;
                                                }}
                                            >

                                                <span className={`status${item.complete ? ' complete' : ''}`} />
                                                <div className="content">
                                                    {
                                                        transformListMeta.map((val, index) => {
                                                            let itemVal = item.data.head[dataAreaCode].rows[0].values[val.attrcode] || {
                                                                value: '',
                                                                display: ''
                                                            };
                                                            // 处理一下数据为空的情况
                                                            itemVal.display = itemVal.display || '';
                                                            itemVal.value = itemVal.value || '';
                                                            if (val.itemtype !== 'datepicker' && val.itemtype !== 'datetimepicker') {
                                                                let finalVal = itemVal && (itemVal.display || itemVal.value || '') || '';
                                                                if (val.itemtype === 'number') { finalVal = formatNumber(finalVal) }
                                                                return (
                                                                    val.visible && (
                                                                        <div className="item-inner">
                                                                            {`${val.label}：`}
                                                                            <span style={{ paddingLeft: 8 }}>
                                                                                {finalVal}
                                                                            </span>
                                                                        </div>
                                                                    ));
                                                            } else {

                                                                let format = val.itemtype === 'datepicker' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss';
                                                                let dateFlag = val.itemtype === 'datepicker' ? 'date' : 'datetime';
                                                                return (
                                                                    val.visible && (
                                                                        <div className="item-inner">
                                                                            {`${val.label}：`}
                                                                            <span
                                                                                style={{ paddingLeft: 8 }}
                                                                            >{`${(itemVal && itemVal.display ? formatDatetime(DongbaToLocalTime(moment(itemVal.display)).format(format), dateFlag) : formatDatetime(DongbaToLocalTime(moment(itemVal.value)).format(format), dateFlag)) || ''}`}</span>
                                                                        </div>
                                                                    )
                                                                );
                                                            }
                                                        })
                                                    }
                                                </div>
                                                {item.complete && <span className="saved-mark">{json["page-transferTable-0016"] || "已处理"}</span>}
                                            </li>
                                        );
                                    })}
                                </ul>
                                {transformForm.list[0] ? (
                                    <div
                                        className="total-area"
                                        style={{ bottom: '7px' }}
                                    >{json['page-transferTable-0014'] || '共计-1'}：<span
                                        className="active"
                                    >{LangData ? LangData.get('page-transferTable-0012', { n: transformForm.list.length }) : transformForm.list.length + '条'}</span>
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                    ) : ''
                }
            </div>
        )
    }

    render() {
        let {
            transformForm, //下游转单的数据集合
        } = this.props;

        let flag = transformForm.show && transformForm.list && transformForm.list.length > 1;

        return <span className={`transfer-list-left ${flag ? 'show-transfer-list-only' : 'hide-transfer-list-only'} `}>{this.getTransferListDom()}</span>
    }
}

export default CreateTranferList;
