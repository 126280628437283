/*
 * Created by wangshhj on 2018/1/16.
 */
import { createTransferTable, createTransferList } from "./create";
import { createMultiTransferTable } from "./multi";
import {
    getTransferTableValue,
    setTransferTableValue,
    insertTransferTableValue,
    setChildTransferTableData,
    getTransferTableSelectedValue,
    setTransferListValue,
    getSelectedListDisplay,
    getTransformFormDisplay,
    setTransformFormStatus,
    getTransformFormStatus,
    changeNextTransformForm,
    returnTransferTableList,
    getTransformFormAmount,
    setMultiTransformFormStatus,
    changeViewType,
    getTransferTableSelectedId,
    getTransferTableAllSelectedId,
    getTransferTableMultiSelectedId,
    getTransformFormCompleteStatus,
    setTransferListValueByIndex,
    setMultiSelectedValue,
    setSavedTransferTableDataPk,
    clearAllSelectedData,
    hasCache,
    deleteCache,
    setRowDataByIndex,
    savePk,
    getPkCache,
    getTransferTableCache,
    deleteItems,
    getTransferListValue,
    updateTransferListValue,
    updateTransferList,
    updateTransferTableValue,
    updateTransformFormStatus,
    updateActiveGrandsonData,
    updateTransferListValueByIndex,
    getTheTransferListDataSavedLastTime,
    updateHeadRowByKey,
    setTheCheckRows, // 设置选中行
    cancelRowsChecked,
    refreshTransferTable,
    whenTransferTableUnmount,
    getCurrentTransferTableStatus,
    setTransferTableSelectedId,
    updateDataByRefresh,
} from './methods';
export default {
    createTransferTable,
    createTransferList,
    createMultiTransferTable,
    getTransferTableValue,
    setTransferTableValue,
    insertTransferTableValue,
    setChildTransferTableData,
    getTransferTableSelectedValue,
    setTransferListValue,
    getSelectedListDisplay,
    getTransformFormDisplay,
    setTransformFormStatus,
    getTransformFormStatus,
    changeNextTransformForm,
    returnTransferTableList,
    getTransformFormAmount,
    setMultiTransformFormStatus,
    changeViewType,
    getTransferTableSelectedId,
    getTransferTableAllSelectedId,
    getTransferTableMultiSelectedId,
    getTransformFormCompleteStatus,
    setTransferListValueByIndex,
    setMultiSelectedValue,
    setSavedTransferTableDataPk,
    clearAllSelectedData,
    hasCache,
    deleteCache,
    setRowDataByIndex,
    savePk,
    getPkCache,
    getTransferListValue,
    updateTransferListValue,
    updateTransferList,
    getTransferTableCache,
    deleteItems,
    updateTransferTableValue,
    updateTransferListValueByIndex,
    updateTransformFormStatus,
    updateActiveGrandsonData,
    getTheTransferListDataSavedLastTime,
    setTheCheckRows,
    updateHeadRowByKey,
    cancelRowsChecked,
    refreshTransferTable,
    whenTransferTableUnmount,
    getCurrentTransferTableStatus,
    setTransferTableSelectedId,
    updateDataByRefresh,
};