import React, { Component } from 'react';
import { withViewModel, getSysFieldid, getMultiLang, sum, changeTime, getLangCode, isTimeType, myBrowser, formatDatetime, formatNumber } from '@platform/api'
import { Table as NCTable, Tabs as NCTabs, Button, BackBtn as NCBackBtn, ToggleViewBtn as NCToggleViewBtn, Tooltip as NCTooltip } from '@platform/base'
import EllipsisToTooltip from './ellipsisToTooltip';
import {
    getLongScale,
    numberDispose,
    getMultiChildChangeWidth,
    resizeEvent,
    getScrollTop,
    updateColumnAfterSetting,
    whenTransferTableUnmount,
    getcolsSettingParam,
} from './methods';
import classnames from 'classnames';
import { lib } from '@platform/table-core';
const { total, hoverContent } = lib;

const ComplexTable = hoverContent(total(NCTable.SortTable));

// 已选列表 来一个唯一标识 用作js样式计算  // 为啥要计算高度  ！！！！！！！！
let uniqueTagCls = 'unique-tag' + (new Date().getTime());

const NCTabPane = NCTabs.TabPane;
const defaultColor = ['#111111', '#555555', '#111', '#555'];

const tableHeaderHeight = 28;

@withViewModel

class CreateMultiTransferTable extends Component {
    constructor(props) {
        super(props);
        (this.ViewModel = this.props.ViewModel), (this.expandArrId = this.props.events && this.props.events.expandArr);
        this.state = {
            switchViewState: 'evenUp',
            selectedTop: 0,
            multiTransfertableHeight: 0, // 多来源转单高度容器
            selectedHeight: 0,
            top: 0,
            height: 0,
            json: {},
            LangData: null,
            transferTableHeight: 0,
            selectedListBoxHeight: 0,

            listHeight: 0, //
            selectedListHeight: 0,
            fixedWidth: 0, //多来源有全部页签共用的底部灰条的宽度

        };
        this.activeTab = '';
        this.multiTransfertableMain = React.createRef();
        this.multiTransfertableSelectedList = React.createRef();
        // ---------
        this.setTimeIndex = null;
        this.transferTableClient = null;
        this.isTransferTableSetHeight = false;
        this.isSelectedListSetHeight = false;
        this.transferTableHeight = null;
        this.modalBodyClient = null;
        this.modalBodyHeight = 0;

        // ---------
        this.transfertableMainIsModalSChild = false;
        this.total = null;
        this.doing = true; // 用来阻止事件的
        this.setListHeightIsDone = false; // 用来阻止事件的
        this.setSelectListHeightIsDone = false; // 用来阻止事件的
        this.setModalHeightIsDone = false; // 用来阻止事件的

        /***获取当前浏览器类型*/
        this.myBrowser = myBrowser();
    }

    componentWillMount() {
        let { showAll } = this.props;
        if (showAll) {
            let callback = (json, bool, LangData) => {
                this.setState({ json, LangData }, () => {
                    this.dealDataFromTransferListBack();
                });
            };
            getMultiLang({ moduleId: 'page-transferTable', callback });
        } else {
            this.dealDataFromTransferListBack();
        }

    }

    componentDidMount() {
        setTimeout(() => {
            this.onresizeEvents({ isIntl: true });
        }, 200);
        window.onresize = () => {
            this.onresizeEvents({ windowResize: true });
        };
        // 设置一个接收装置 接收父级page所传递的信息
        let _this = this.props._this;
        // 查询区
        _this.infoExchangeController.subscribe('heightChange', (name, { area }) => {
            if (area === 'searchArea') {
                console.log('侦测到查询区变化，进行高度毁灭打击！');
                setTimeout(() => {
                    this.onresizeEvents({ windowResize: true });
                }, 20);
            } else {
                console.log(area);
            }
        });
        // 弹窗resize
        _this.infoExchangeController.subscribe('modelResizeEnd', (name, { indexInfo, className, id }) => {
            console.log('侦测到弹窗resize变化，随风起舞吧！');
            setTimeout(() => {
                this.onresizeEvents({ windowResize: true });
            }, 20);
        });
        // console.log(_this);
    }

    componentDidUpdate() {
        let { _this } = this.props;
        if (_this.state.multiDelete) {//供应链刘奇的转单上下游是多页，下游保存单据后，上游需要更新删除保存后的数据
            this.dealDataFromTransferListBack(false);
            _this.state.multiDelete = false;
        }
    }

    componentWillUnmount() {
        window.onresize = null;
        let { _this, dataSource } = this.props;
        whenTransferTableUnmount.call(_this, dataSource);
        // 可能需要清理 这里清理了 别人就不能用了  可以考虑 topic模式
        _this.infoExchangeController && _this.infoExchangeController.unsubscribe('heightChange');
        _this.infoExchangeController && _this.infoExchangeController.unsubscribe('modelResizeEnd');
    }


    /**
     * 转单下游回到上游以后，检测保存过的数据，然后删除
     */
    dealDataFromTransferListBack = (isWillMount = true) => {
        let { _this, dataSource, componentInitFinished, tabActiveIndex, onTabClick } = this.props;
        if (onTabClick && typeof onTabClick === 'function' && tabActiveIndex) {
            onTabClick(tabActiveIndex);
            _this.setState({
                transferTable: data.transferTable
            });
        }
        if (dataSource) {
            let data = this.ViewModel.getData(dataSource);
            if (data && data.transferTable && Object.keys(data.transferTable).length) {
                if (onTabClick && typeof onTabClick === 'function') {
                    if (data.transferTable.allSelected && data.transferTable.allSelected.activeTab) {
                        this.activeTab = tabActiveIndex || data.transferTable.allSelected.activeTab;
                        onTabClick(tabActiveIndex || data.transferTable.allSelected.activeTab);
                    }
                }
                let pk = this.ViewModel.getData(`${dataSource}_PK`) || []; // 下有转单保存的pk， 需要在缓存中删除
                let ID = this.ViewModel.getData(`${dataSource}_ID`) || {}; // 主表子表每条数据的的key， 删除缓存中保存过的单据要用
                let transferTableRelation = data.transferTableRelation; // 单据关系 子表key对应得主表key

                this.processingData(data.transferTable, pk, transferTableRelation, ID);
                _this.setState(
                    {
                        transferTable: data.transferTable
                    },
                    () => {
                        // 清除一下
                        // this.ViewModel.setData(dataSource, null);
                        //执行数据初始化周期函数
                        if (isWillMount && typeof componentInitFinished == 'function') {
                            componentInitFinished();
                        }
                    }
                );
            }
        }

    }

    /*
    * 设置多来源转单所在模态框的高
    *
    * */
    setModalTransferTableHeight = () => {
        let { transferTable, showAll } = this.props;
        let selectedShow = showAll && transferTable.allSelected.selectedShow;
        let modalBodySelector = document.querySelector('.u-modal-body');
        let modalBodyNowClient = modalBodySelector ? modalBodySelector.getBoundingClientRect() : null;
        let transferTableTop = this.multiTransfertableMain && this.multiTransfertableMain.current && this.multiTransfertableMain.current.getBoundingClientRect && this.multiTransfertableMain.current.getBoundingClientRect().top;
        let transferTableHeight = 0;
        // 判断是设置全部列表的高度还是设置已选列表的高度
        if (selectedShow) {
            if (!this.setSelectListHeightIsDone) {
                transferTableHeight = modalBodyNowClient.bottom - transferTableTop; // 15 是modal的paddingBottom

                this.setState({
                    transferTableHeight,
                    selectedListHeight: transferTableHeight,
                }, () => {
                    this.setSelectListHeightIsDone = true;
                    this.showTransferTableTimer = null;
                });
            }
        } else {
            if (!this.setListHeightIsDone) {
                transferTableHeight = modalBodyNowClient.bottom - transferTableTop; // 15 是modal的paddingBottom 
                this.setState({
                    transferTableHeight,
                    listHeight: transferTableHeight,
                }, () => {
                    this.setListHeightIsDone = true;
                    this.showTransferTableTimer = null;
                });
            }
        }

    }

    // 判断转单是不是放在modal中的
    transferTableIsInModal = () => {
        let modalSelector = document.querySelector('.u-modal-body');
        let transfertableNowCurrent = this.multiTransfertableMain.current,
            result = [],
            father = transfertableNowCurrent.parentNode;
        while (father && father.tagName != 'BODY') {
            result.push(father);
            father = father.parentNode;
        }
        this.doing = false;
        this.transfertableMainIsModalSChild = result.includes(modalSelector);
    }

    /*
    * 设置转单组件自适应宽高
    * isIntl: Boolean 页面是否初始化 （didMount的时候会设置为true）
    * windowResize：Boolean 窗口是否在变化宽高
    * */
    onresizeEvents = ({ isIntl, windowResize }) => {
        let { showAll, transferTable } = this.props;
        let selectedShow = transferTable.allSelected.selectedShow && showAll;
        let windowHeight = getScrollTop();
        let transferTableTop = this.multiTransfertableMain && this.multiTransfertableMain.current && this.multiTransfertableMain.current.getBoundingClientRect && this.multiTransfertableMain.current.getBoundingClientRect().top;
        resizeEvent(isIntl, windowResize, selectedShow, transferTableTop, windowHeight, this);
    }

    // 自定义合计
    customAggregate = () => {
        let { totalKey, totalTitle, getTheLongestPrecision, transferTable } = this.props;
        let allSelected = transferTable['allSelected'].selectedData;
        let masterSelData = allSelected.masterSelData;
        let childSelData = allSelected.childSelData;
        let data = [];
        let values = totalKey.reduce((total, now) => {
            total[now.join('_')] = 0;
            return total;
        }, {});
        for (let [name, item] of Object.entries(masterSelData)) {
            let masterValue = item.data;
            for (let item of totalKey) {
                let outKey = item.join('_');

                for (let key of item) {
                    if (masterValue.hasOwnProperty(key)) {
                        values[outKey] = getTheLongestPrecision(String(masterValue[key].value), String(values[outKey]));
                        values[outKey] = numberDispose(values[outKey]);
                    }
                }
            }
            if (childSelData.hasOwnProperty(name)) {
                for (let [na, it] of Object.entries(childSelData[name])) {
                    let childValue = it.data.values ? it.data.values : it.data;
                    for (let item of totalKey) {
                        let outKey = item.join('_');
                        for (let key of item) {
                            if (childValue.hasOwnProperty(key)) {
                                values[outKey] = getTheLongestPrecision(String(childValue[key].value), String(values[outKey]));
                                values[outKey] = numberDispose(values[outKey]);

                            }
                        }
                    }
                }
            }
        }

        totalKey.map((item, index) => {
            let key = item.join('_');
            data.push({
                title: totalTitle[index],
                value: values[key],
                key
            });
        });
        return data.length ? (
            <div style={{ display: 'inline-block' }}>
                {data.map(item => {
                    return (
                        <span
                            style={{ display: 'inline-block', marginRight: '20px' }}
                            key={item.key}
                        >
                            {`${item.title}: ${item.value}`}
                        </span>
                    );
                })}
            </div>
        ) : '';
    }

    //关闭已选列表
    closeSelectedList = () => {
        let { _this, showAllList } = this.props;
        _this.state.transferTable.allSelected.selectedShow = false;
        _this.setState({
            transferTable: _this.state.transferTable
        }, () => {
            this.onresizeEvents({ isIntl: true, windowResize: false });
            showAllList && showAllList();
        });
    };

    //查看已选列表
    viewSelectedList = () => {
        let { _this, showAll, transferTable, allHeadId, getAllSelectedData, onSelectedBtnClick } = this.props;

        if (!showAll) {
            getAllSelectedData();//获取多来源转单已选数据
        } else {
            let transferTable = JSON.parse(JSON.stringify(_this.state.transferTable));
            let allSelected = JSON.parse(JSON.stringify(_this.state.transferTable.allSelected));
            for (let items of Object.keys(transferTable)) {
                if (items !== 'allSelected') {
                    let selected = transferTable[items].selected;
                    let childS = selected.selectedData.childSelData;
                    let masterS = selected.selectedData.masterSelData;
                    for (let item of Object.keys(masterS)) {
                        if (Object.keys(masterS).length) {
                            if (!allSelected.selectedData.masterSelData.hasOwnProperty(item)) {
                                allSelected.masterCheckedId.push(item);
                                allSelected.selIds[item] = [];
                                allSelected.selectedData.masterSelData[item] = masterS[item];
                            }
                        }
                    }
                    for (let item of Object.keys(childS)) {
                        if (Object.keys(childS).length) {
                            if (!allSelected.selectedData.childSelData.hasOwnProperty(item)) {
                                allSelected.selectedData.childSelData[item] = childS[item];
                                for (let ite of Object.keys(childS[item])) {
                                    allSelected.selIds[item].push(ite);
                                }
                            } else {
                                for (let ite of Object.keys(childS[item])) {
                                    if (!allSelected.selectedData.childSelData[item].hasOwnProperty(ite)) {
                                        allSelected.selectedData.childSelData[item][ite] = childS[item][ite];
                                        allSelected.selIds[item].push(ite);
                                    }
                                }
                            }
                        }
                    }
                    allSelected.masterAmount = allSelected.masterCheckedId.length;
                    for (let item of Object.keys(allSelected.selIds)) {
                        // 有个数据没有子数据  但是应该也算一行数据
                        allSelected.bodyAmount += (allSelected.selIds[item].length || 1);
                    }
                }
            }
            _this.state.transferTable.allSelected = allSelected;
        }
        if (!_this.state.meta[allHeadId]) {
            console.error('没有加载全部页签模板');
            return false;
        }
        transferTable.allSelected.selectedShow = !transferTable.allSelected.selectedShow; //已选列表弹窗显示状态
        _this.setState({
            transferTable: _this.state.transferTable
        },
            () => {
                this.onresizeEvents({ isIntl: false, windowResize: false });
                if (typeof onSelectedBtnClick === 'function') {
                    onSelectedBtnClick();
                }
            }
        );
    };

    /*
    * 处理合计数据
    * selected: Object 计算合计选中的数据
    * tableType：String 判断主子表结构、主子拉平结构或者单表结构 （nest/full/simple）
    * column：Array 合计行的模板数据
    * showFullTable：Boolean 判断是表示是否为拉平显示
    * */
    processingTotalData = (selected, tableType, column, showFullTable) => {
        let { getTheLongestPrecision, getScaleData } = this.props;
        let outerData = {};
        let totalKey = [];
        let totalScale = {};

        for (let item of column) {
            outerData[item['attrcode']] = { value: '', display: '' };
            if (item.istotal && item.itemtype === 'number') {
                totalKey.push(item.attrcode);
                totalScale[item.attrcode] = item.scale;
            }
        }
        if (tableType === 'master') {
            for (let items of Object.keys(selected.masterSelData)) {
                let values = selected.masterSelData[items].data;

                for (let i of totalKey) {
                    if (values[i] && typeof values[i] === 'object') {
                        if (values[i] && typeof values[i] === 'object') {
                            if (!outerData[i].value) {
                                outerData[i].scale = values[i].scale;
                                outerData[i].value = values[i].value;
                            } else {
                                outerData[i].scale = getLongScale(values[i].value, outerData[i].value);
                                outerData[i].value = getTheLongestPrecision(values[i].value, outerData[i].value);
                            }
                        }

                    }
                }
            }
        } else if (showFullTable && tableType === 'full') {
            let childSelData = selected.childSelData;
            let masterSelData = selected.masterSelData;
            if (childSelData) {
                for (let items of Object.keys(childSelData)) {
                    for (let item of Object.keys(childSelData[items])) {
                        for (let i of totalKey) {
                            if (
                                childSelData[items][item].data.values
                                    ? childSelData[items][item].data.values.hasOwnProperty(i)
                                    : childSelData[items][item].data.hasOwnProperty(i)
                            ) {
                                let values = childSelData[items][item].data.values || childSelData[items][item].data;
                                if (values[i] && typeof values[i] === 'object') {
                                    if (values[i] && typeof values[i] === 'object') {
                                        if (!outerData[i].value) {
                                            outerData[i].scale = values[i].scale;
                                            outerData[i].value = values[i].value;
                                        } else {
                                            outerData[i].scale = getLongScale(values[i].value, outerData[i].value);
                                            outerData[i].value = getTheLongestPrecision(values[i].value, outerData[i].value);
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
            for (let items of Object.keys(masterSelData)) {
                let values = masterSelData[items].data.values || masterSelData[items].data;

                for (let i of totalKey) {
                    if (values.hasOwnProperty(i)) {
                        if (values[i] && typeof values[i] === 'object') {
                            let scale = values[i].scale ? values[i].scale : totalScale[i];
                            let value = values[i] ? values[i].value : 0;
                            if (!outerData[i].value) {
                                outerData[i].scale = scale;
                                outerData[i].value = getScaleData(value, scale);
                            } else {
                                let totalData = sum(Number.parseFloat(value), Number.parseFloat(outerData[i].value.replace(/,/g, '')));
                                outerData[i].scale = scale > outerData[i].scale ? scale : outerData[i].scale;
                                outerData[i].value = getScaleData(String(totalData), outerData[i].scale);
                            }
                        }
                    }
                }
            }
        }

        return [outerData];
    };

    /*
    * 设置已选模板
    * column：Array 模板数据
    * */
    createSelectColumns = (column, showIndex) => {
        let result = [];
        let { headTableId } = this.props;
        result.push({
            label: this.state.json['page-transferTable-0007'] || '序号-5',
            attrcode: 'numberindex',
            itemtype: 'customer',
            dataIndex: 'numberindex',
            fixed: 'left',
            visible: true,
            width: 80,
            className: 'col-customer-numberindex',
            render: (text, record2, index) => {
                return <span title={this.state.json['page-transferTable-0010'] || '合计-2'}>{this.state.json['page-transferTable-0010'] || '合计-2'}</span>;
            }
        });
        column.map((item) => {
            let width = item.width;
            if (!width) {
                width = 120;
            }
            let render = null;
            if (item.itemtype !== 'customer' && item.visible && !item.render) {
                render = (text, record, index) => {
                    if (record[item.attrcode]) {
                        let display = record[item.attrcode].display;
                        let value = record[item.attrcode].value;
                        let dom = '';
                        if (display || display === 0) {
                            dom = display;
                        } else {
                            dom = value;
                        }
                        if (isTimeType(item.itemtype)) {
                            if (item.itemtype == 'datetimepicker') {
                                dom = changeTime(value, 'YYYY-MM-DD HH:mm:ss');
                                dom = formatDatetime(dom, 'datetime');
                            } else if (item.itemtype == 'timepicker') {
                                dom = value;
                                dom = formatDatetime(dom, 'time');
                            } else {
                                dom = changeTime(value);
                                dom = formatDatetime(dom, 'date');
                            }
                        }

                        // 多语处理
                        if (item.itemtype === "residtxt") {
                            let langCode = getLangCode();
                            let loginLang = (item.languageMeta || []).filter(i => i.languageCode == langCode);
                            if (loginLang.length > 0) {
                                loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                                if (
                                    record[item.attrcode + loginLang[0].index] &&
                                    record[item.attrcode + loginLang[0].index].value
                                ) {
                                    dom = record[item.attrcode + loginLang[0].index].value;
                                }
                            }
                        }

                        if (item.itemtype === 'number') {
                            dom = formatNumber(dom);
                        }
                        return (
                            <span className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : ''}`}>
                                <EllipsisToTooltip overlay={dom}>
                                    <span className={classnames({
                                        'single-line-and-ellipsis': this.state.transferTable['allSelected']['isEllipsis'],
                                        'three-line-and-ellipsis': !this.state.transferTable['allSelected']['isEllipsis'],
                                        'mult-line-and-ellipsis': !this.state.transferTable['allSelected']['isEllipsis'] && this.state.transferTable['allSelected']['bodyExpandAllInRow'],
                                    })}>{dom}</span>
                                </EllipsisToTooltip>
                            </span>
                        );

                    }
                };
            } else {
                if (item.itemtype === 'customer') {
                    render = item.render;
                } else {
                    render = (text, record, index) => {
                        return (
                            <EllipsisToTooltip
                                className="tooltip-word-color"
                                overlay={item.render.call(
                                    null,
                                    text,
                                    record,
                                    index
                                )}
                                noEllipsis
                            >
                                <div className={classnames(
                                    'col-customer-render-unit',
                                    {
                                        'single-line-and-ellipsis': this.state.transferTable['allSelected']['isEllipsis'],
                                        'three-line-and-ellipsis': !this.state.transferTable['allSelected']['isEllipsis'],
                                        'mult-line-and-ellipsis': !this.state.transferTable['allSelected']['isEllipsis'] && this.state.transferTable['allSelected']['bodyExpandAllInRow'],
                                    })
                                }>
                                    {item.render.call(null, text, record, index)}
                                </div>
                            </EllipsisToTooltip>
                        );
                    };
                }
            }
            if (item.visible && item.attrcode !== 'checkbox' && item.attrcode !== 'numberindex') {
                result.push({
                    ...item,
                    render,
                    width,
                    key: item.attrcode,
                    title: <span
                        className={`${item.itemtype === 'number' ? 'number-td-span-wrapper' : ''}`}
                        style={{ color: defaultColor.includes(item.color) ? '' : item.color }}
                    >{item.label}
                    </span>
                });
            }
        });
        // if (result[1] && !showIndex) {

        // }
        if (result.length) {
            result[result.length - 1].render = () => {
                <span />;
            };
        }
        return result;
    };

    /*
   * 页面初始化的时候去除下游保存过的数据
   * transferTable：Object 转单数据 （this.state.transferTable）
   * pk：Array 下游保存过的所有单据主键
   * headTableId：String 转单ID
   * */
    processingData = (transferTable, pk, relation, ID) => {

        let { params } = this.props;
        if (pk.length) {
            for (let items of Object.keys(transferTable)) {
                let headTableId = '';
                let onCheckedChange = null;
                for (let headId of params) {
                    if (items === headId.headTableId) {
                        headTableId = headId.headTableId;
                        onCheckedChange = headId.onCheckedChange;
                        break;
                    }
                }
                if (items !== 'allSelected') {
                    if (transferTable.hasOwnProperty(headTableId)) {
                        let data = Object.keys(transferTable[items][headTableId]['bodyData']).length
                            ? transferTable[items][headTableId]['bodyData']['data']
                            : {};
                        let outerData = transferTable[items][headTableId]['outerData'];
                        let selected = transferTable[items]['selected']['selectedData'];
                        let masterCheckedId = transferTable[items]['selected'] && transferTable[items]['selected'].masterCheckedId;
                        let childSelect = selected && selected.childSelData;
                        let masterSelect = selected && selected.masterSelData;

                        // 上游选择了多子数据
                        // 如果下游只保存了一个数据 这里就有问题了 
                        // transferTable[items][headTableId]['outerData'] = transferTable[items][headTableId]['outerData'].filter(it => {
                        //     return !pk.includes(it.rowId) && !masterCheckedId.includes(it.rowId)
                        // });

                        transferTable[items][headTableId]['outerData'] = outerData.length ? outerData.filter(item => {
                            if (pk.includes(item.rowId)) {
                                return false;
                            }
                            if (data && Object.keys(data).length && data[item.rowId] && data[item.rowId].rows) {
                                // 这里需要先记录下 初始数据  不然会导致没有子级的数据返回时被删除
                                let originLength = data[item.rowId].rows.length;
                                data[item.rowId].rows = data[item.rowId].rows.filter(citem => {
                                    return !pk.includes(citem.childId);
                                });
                                // 如果子集都没有了 父级也不需要了？？？  这里注意 一定要一开始有子集  然后才能移除父级
                                if (originLength && data[item.rowId].rows.length === 0) {
                                    return false;
                                }
                            }
                            return true;
                        }) : [];
                        // 还有一个全选的状态也需要处理下  TODO
                        if (pk && pk.length > 0) {
                            selected && (selected.selAll = false);
                        }

                        if (Object.keys(childSelect).length) {
                            for (let items of Object.keys(childSelect)) {
                                let onChecked = {
                                    body: [],
                                    head: {}
                                };
                                let headIndex = Object.keys(data).indexOf(items);
                                for (let val of Object.keys(childSelect[items])) {
                                    if (pk.includes(val)) {
                                        delete childSelect[items][val];
                                    } else {
                                        onChecked.body.push(
                                            childSelect[items][val].data.values ?
                                                childSelect[items][val].data.values :
                                                childSelect[items][val].data
                                        );
                                    }
                                }
                                if (!Object.keys(childSelect[items]).length) {
                                    masterCheckedId = masterCheckedId.filter(masterItems => {
                                        return masterItems !== items;
                                    });
                                    delete childSelect[items];
                                    delete masterSelect[items];
                                }
                                if (masterSelect.hasOwnProperty(items)) {
                                    onChecked.head = masterSelect[items].data;
                                }
                                if (Object.keys(onChecked.head).length && typeof onCheckedChange === 'function') {
                                    onCheckedChange(true, onChecked.head, headIndex, onChecked.body);
                                }
                            }
                            for (let val of Object.keys(data)) {
                                data[val].rows = data[val].rows.filter(it => {
                                    return !pk.includes(it.childId);
                                });

                            }
                        } else if (!Object.keys(childSelect).length && Object.keys(masterSelect).length) {
                            let arr = [];
                            for (let items of Object.keys(masterSelect)) {
                                if (pk.includes(items)) {
                                    arr.push(items);
                                    masterCheckedId = masterCheckedId.filter((i) => {
                                        return i !== items;
                                    });
                                } else {
                                    if (typeof onCheckedChange === 'function') {
                                        onCheckedChange(true, masterSelect[items].data, Object.keys(masterSelect).indexOf(items), []);
                                    }

                                }
                            }
                            for (let item of arr) {
                                delete masterSelect[item];
                            }

                            let outD = transferTable[items][headTableId].outerData;
                            transferTable[items][headTableId].outerData = outD.filter((item, index) => {
                                return !arr.includes(item.rowId);
                            });
                        }
                        // 赋值哟
                        transferTable[items]['selected'] && (transferTable[items]['selected'].masterCheckedId = masterCheckedId);
                        transferTable[items]['selected'].bodyAmount = Object.keys(selected.childSelData).reduce((total, item) => {
                            // 有个数据没有子数据  但是应该也算一行数据
                            return total += (Object.keys(selected.childSelData[item]).length || 1);
                        }, 0);
                        transferTable[items]['selected'].masterAmount = Object.keys(selected.masterSelData).length;
                        if (data) {
                            for (let item of Object.keys(data)) {
                                let rows = [];
                                let itemRows = data[item]['rows'] || data[item];
                                for (let i = 0; i < itemRows.length; i++) {
                                    if (!pk.includes(itemRows[i]['childId'])) {
                                        rows.push(itemRows[i]);
                                    }
                                }
                                data[item].rows = rows;
                            }
                        }
                    }
                } else {
                    let selIds = transferTable.allSelected ? transferTable.allSelected.selIds : null;
                    let selectedData = transferTable.allSelected.selectedData;
                    let childSelData = selectedData.childSelData;
                    let masterSelData = selectedData.masterSelData;
                    let masterCheckedId = transferTable.allSelected.masterCheckedId || [];
                    let bodyAmount = 0;
                    if (selIds) {

                        for (let item of Object.keys(selIds)) {
                            transferTable.allSelected.selIds[item] = selIds[item].filter(i => {
                                return !pk.includes(i);
                            });
                            if (!selIds[item].length) {
                                masterCheckedId = masterCheckedId.filter((i) => {
                                    return i !== item;
                                });
                                delete selIds[item];
                            }
                        }
                        for (let item of Object.keys(childSelData)) {
                            for (let i of Object.keys(childSelData[item])) {
                                if (pk.includes(i)) {
                                    delete childSelData[item][i];
                                }
                            }
                            if (!Object.keys(childSelData[item]).length) {
                                masterCheckedId = masterCheckedId.filter(masterItems => {
                                    return masterItems !== item;
                                });
                                delete masterSelData[item];
                                delete childSelData[item];
                            }
                        }
                        for (let item of Object.keys(selIds)) {
                            if (selIds[item]) {
                                // 有个数据没有子数据  但是应该也算一行数据
                                bodyAmount += (selIds[item].length || 1);
                            }
                        }
                        transferTable.allSelected.masterCheckedId = masterCheckedId;
                        transferTable.allSelected.masterAmount = Object.keys(selIds).length;
                        transferTable.allSelected.bodyAmount = bodyAmount;
                    }
                }
            }
            // 后续可以优化下
            // 由于刘奇那边有个统计  所以这里触发一下onCheckedChange  NCCLOUD-158999
        } else if (this.props.onCheckedChange) {
            for (let items of Object.keys(transferTable)) {
                let onCheckedChange = null,
                    headTableId = '';
                for (let headId of params) {
                    if (items === headId.headTableId) {
                        headTableId = headId.headTableId;
                        onCheckedChange = headId.onCheckedChange;
                        break;
                    }
                }
                if (items !== 'allSelected' && transferTable.hasOwnProperty(headTableId)) {
                    let dataKeys = Object.keys(Object.keys(transferTable[items][headTableId]['bodyData']).length ? transferTable[items][headTableId]['bodyData']['data'] : {}),
                        selected = transferTable[items]['selected']['selectedData'],
                        childSelect = selected && selected.childSelData,
                        childSelectKeys = Object.keys(childSelect),
                        masterSelect = selected && selected.masterSelData,
                        masterSelectKeys = Object.keys(masterSelect);
                    if (childSelectKeys.length) {
                        for (let citems of childSelectKeys) {
                            let onChecked = {
                                body: [],
                                head: masterSelect[citems] ? masterSelect[citems].data : {}
                            };
                            let childSelectItem = childSelect[citems],
                                childSelectItemsKeys = Object.keys(childSelectItem);
                            for (let item of childSelectItemsKeys) {
                                onChecked.body.push(childSelectItem[item].data.values ? childSelectItem[item].data.values : childSelectItem[item].data);
                            }
                            if (Object.keys(onChecked.head).length && typeof onCheckedChange === 'function') {
                                let headIndex = dataKeys.indexOf(citems);
                                onCheckedChange(true, JSON.parse(JSON.stringify(onChecked.head)), headIndex, JSON.parse(JSON.stringify(onChecked.body)));
                            }
                        }
                    } else if (masterSelectKeys.length) {
                        for (let mitems of masterSelectKeys) {
                            if (!pk.includes(mitems)) {
                                if (typeof onCheckedChange === 'function') {
                                    onCheckedChange(true, JSON.parse(JSON.stringify(masterSelect[mitems].data)), masterSelectKeys.indexOf(mitems), []);
                                }
                            }
                        }
                    }
                }
            }
        } else {
            console.log('么得数据！么得PK！');
        }
    };

    /*
    *  控制行展开图标是否显示，当子表模板找不到并且子表数据为空数组时，设置展开图标隐藏
        headTableId:String 转单主表ID
        record:将要渲染的这行主表的数据
        index：将要渲染的这行主表的索引
        函数返回false显示展开icon，反之不显示
    */
    haveExpandIcon = (headTableId, isSelected, record, index) => {
        let { bodyColumn, currentTable, transferTable } = this.props,
            haveBodyData;


        // console.log(bodyColumn);
        if (isSelected) {
            let childSelData = transferTable.allSelected.selectedData.childSelData,
                expand = childSelData ? (childSelData[record.key] || {}) : {},
                childSelDatalen = Object.keys(expand) && Object.keys(expand).length;
            return !childSelDatalen;
        } else {
            if (transferTable &&
                transferTable[headTableId] &&
                transferTable[headTableId].outerData
            ) {
                // 为了解决滑动加载index不正确问题  不能使用index了
                // let rowId = transferTable[headTableId].outerData[index].rowId;
                let rowId = record.key || record.headKey;
                if (currentTable &&
                    currentTable.bodyData &&
                    currentTable.bodyData.data &&
                    currentTable.bodyData.data[rowId]
                ) {
                    if (currentTable.bodyData.data[rowId].rows.length > 0) {
                        haveBodyData = true; // TODO 无用代码 待清理
                    }
                }
            }

            return true;
        }
    }

    render() {
        let {
            _this,
            clearAllSelectedData,
            createNewCol, //处理模板 在模板数据上加checkbox、序号或者操作列 的方法
            cutParams,
            isHeadTotal, //主表是否合计
            isFullTotal, //拉平表是否合计
            params,
            expandedRowRender,
            fullColumns,
            getMasterData,
            headPkIds,
            bodyPkIds,
            getSelectedFullData,
            handleChangeView,
            showChangeViewBtn,
            handleGenerateClick,
            handleTabClick,
            showAll,
            showSeletedBtn,
            showGenerateBtn,
            isDrag,
            selectArea,
            selectedHeaderRender,
            selectedAmount,
            setSelectedColumns,
            transferBtnText,
            transferTable,
            totalKey,
            totalTitle,
            containerSelector,
            sortType,
            onExpandedChange,
            meta,
            allHeadId,
            allFullTableId,
            cancelCustomRightMenu,
            isShowBodyAmount,
            autoHeight,
            headTableId,
        } = this.props;
        let {
            json,
            LangData,
            listHeight, // 整个单据的高度 42为底部灰条高度 5预留滚动条的位置， 28表格头部的高度
            selectedListHeight,
            fixedWidth,
        } = this.state;
        //外层容器宽度
        let containerWidth = document.querySelector(containerSelector) && document.querySelector(containerSelector).clientWidth;
        let fixedCss = this.transfertableMainIsModalSChild ? {
            position: 'absolute',
            bottom: 0,
            width: 'auto', left: '0', right: '0'
        } : { width: 'auto', left: '15px', right: '15px' };

        let allListCSS = (!showAll || (showAll && !transferTable.allSelected.selectedShow)) ? {} : {
            height: 0,
            width: 0,
            opacity: 0
        };
        let allListCSScls = (!showAll || (showAll && !transferTable.allSelected.selectedShow)) ? 'showAllListCssCls' : '';
        // 已选列表转单css
        let showSelectedListCSS = (showAll && transferTable.allSelected.selectedShow) ? 'showSelectedList' : '';
        let selectedColumn = createNewCol(undefined, this.state.json);
        let selectedFullColumn = createNewCol(fullColumns, this.state.json, 'full');
        let selectNestTotalColumns = this.createSelectColumns(setSelectedColumns(createNewCol(undefined, this.state.json), this.state.json), true, this.state.json);
        let selectFullTotalColumns = this.createSelectColumns(setSelectedColumns(selectedFullColumn, this.state.json), true, this.state.json);
        selectedListHeight = autoHeight ? selectedListHeight : 400;
        listHeight = autoHeight ? listHeight : 400;
        let transferTableMainHeight = (showAll && transferTable.allSelected.selectedShow) ? selectedListHeight : listHeight;
        // 浏览器修正 滚动条多8px
        let browserDiff = this.myBrowser === 'Firefox' || this.myBrowser === 'IE' ? 8 : 0;
        // 有合计行的表格scroll高度
        let selectedTotalTableScrollYHeight = selectedListHeight - tableHeaderHeight - 45 - 42 - 54 - (browserDiff * 2), // 54 是已选列表界面头部按钮区域的高度
            // 已选列表无合计行表格的scroll高度
            selectedNoTotalTableScrollYHeight = selectedListHeight - tableHeaderHeight - 42 - 54 - browserDiff;

        const getCurrentFieldId = () => {
            if (Array.isArray(params)) {
                let activeIndex = this.activeTab || 0;
                return params[activeIndex].fieldId;
            }
        }

        return (
            <div
                className={`multitransfer-container multi-transfertable-main transfertable-main-${this.myBrowser || 'chrome'}`}
                ref={this.multiTransfertableMain}
                style={{
                    // 这个高度 在页面resize的时候 不能重新计算
                    height: `${transferTableMainHeight ? transferTableMainHeight + 'px' : 'auto'}`,
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <div style={allListCSS} className={allListCSScls}>
                    <NCTabs
                        activeKey={String(_this.state.transferTable.allSelected.activeTab)}
                        onChange={(key) => {
                            this.activeTab = key;
                            handleTabClick(key);
                            // 这里加这个方法是可以解决切换页签不计算的问题  但是也会导致多渲染一次  bbqin
                            this.onresizeEvents({ windowResize: true });
                        }}
                        animated={false}
                    >
                        {/* 全部列表*/}
                        {params &&
                            params.map((item, index) => {
                                let { tabName, searchArea, onTabClick, containerSelector, ...transferParams } = item;
                                transferParams.showSeletedArea = !showAll; //有全部页签的多来源不显示单个已选列表
                                transferParams.showSeletedBtn = showAll; //普通多来源不显示查看已选按钮
                                transferParams.showGenerateBtn = showGenerateBtn; //普通多来源不显示查看已选按钮

                                return cutParams.includes(index) ? (
                                    ''
                                ) : (
                                        <NCTabPane tab={item.tabName} key={index}>
                                            <div className="multi-transfer-container">
                                                {/* 查询区域 */}
                                                <div className={`${searchArea ? 'transfer-search-container' : 'transfer-search-container-space'}`}>
                                                    {searchArea && searchArea()}
                                                </div>
                                                {/* 转单区域 */}
                                                <div>
                                                    {_this.transferTable.createTransferTable({
                                                        ...transferParams,
                                                        isMultiChild: true,
                                                        headPkIds,
                                                        bodyPkIds,
                                                        containerSelector,
                                                        showChangeViewBtn,
                                                        getMultiChildChangeWidth: getMultiChildChangeWidth.bind(this),
                                                        onExpandedChange,
                                                        meta
                                                    })}
                                                </div>
                                            </div>
                                        </NCTabPane>
                                    );
                            })}
                    </NCTabs>

                </div>
                <div className={`${showSelectedListCSS} selected-list-wrapper`}>
                    { //有全部页签-----已选列表
                    }
                    <div className="selected-list">
                        <div className="header-area">
                            <span className="selected-icon-back-btn">
                                <NCBackBtn
                                    onClick={this.closeSelectedList}
                                    className="back-transfer-list"
                                />
                            </span>
                            <span className="header-title">{json['page-transferTable-0002'] || '已选列表'}</span>
                            <div className="header-right">
                                <div className="header-right-inner">
                                    <span className="header-right-change-view-area">
                                        {showChangeViewBtn === true && (
                                            <NCToggleViewBtn
                                                tooltips={[json['page-transferTable-0023'], json['page-transferTable-0022']]}
                                                className="selected-toggle-view"
                                                expand={!!transferTable.allSelected.showFullTable}
                                                onClick={handleChangeView.bind(this)}
                                            />
                                        )}
                                    </span>
                                    <span className="clear-selected-item-view-btn">
                                        <Button
                                            className="item"
                                            onClick={clearAllSelectedData.bind(this)}
                                            fieldid="clearAll"
                                        >
                                            {
                                                json['page-transferTable-0013'] || '清空'
                                            }
                                        </Button>
                                    </span>
                                    <div className="header-right-custom-area">{selectedHeaderRender && selectedHeaderRender()}</div>
                                </div>

                            </div>
                        </div>
                        {showAll ? (
                            <div
                                className="content-area"
                                ref={this.multiTransfertableSelectedList}
                            >
                                <div fieldid={getSysFieldid('selected-list_table')} className={`${uniqueTagCls}`}>
                                    {!transferTable.allSelected.showFullTable ? //主子/单表
                                        (isHeadTotal && selectedColumn ? (
                                            <ComplexTable
                                                className="insertTable multi-transfer-selected-wrapper multi-transfertable-total-selected-wrapper"
                                                // createNewCol 处理所有的列使其符合规范  createNewCol() 默认生成非固定列的主体部分
                                                columns={setSelectedColumns(selectedColumn, this.state.json)}
                                                sort={sortType}
                                                data={getMasterData(transferTable.allSelected.selectedData, params)}
                                                expandedRowRender={(record, index) => expandedRowRender(record, index, this.state.json, uniqueTagCls)}
                                                haveExpandIcon={this.haveExpandIcon.bind(this, allHeadId, true) || undefined}
                                                rowKey={(record) => record.key}
                                                scroll={{ x: true, y: selectedTotalTableScrollYHeight }}
                                                isTotal={true}
                                                // lazyload={false}
                                                isDrag={isDrag}
                                                bodyStyle={{ height: selectedTotalTableScrollYHeight }}
                                                totalData={this.processingTotalData(
                                                    transferTable.allSelected.selectedData,
                                                    'master',
                                                    selectedColumn,
                                                    false
                                                )}
                                                totalColumns={selectNestTotalColumns}//合计行表格的模板数据
                                                updateColumnHandle={updateColumnAfterSetting.bind(this)}
                                                tableType='transfer'
                                                foldingRowChange={isEllipsis => {
                                                    _this.state.transferTable['allSelected']['isEllipsis'] = isEllipsis
                                                    _this.setState({ transferTable: _this.state.transferTable })
                                                }}
                                                bodyDisplayInRow={_this.state.transferTable['allSelected']['isEllipsis']}
                                                bodyExpandAllInRow={_this.state.transferTable['allSelected']['bodyExpandAllInRow']}
                                                cancelCustomRightMenu={cancelCustomRightMenu}
                                                colsSettingParam={getcolsSettingParam.call(_this, meta, allHeadId)}
                                            />
                                        )
                                            : (
                                                <ComplexTable
                                                    className="insertTable multi-transfer-selected-wrapper"
                                                    columns={setSelectedColumns(selectedColumn, this.state.json)}
                                                    sort={sortType}
                                                    data={getMasterData(transferTable.allSelected.selectedData, params)}
                                                    expandedRowRender={(record, index) => expandedRowRender(record, index, this.state.json, uniqueTagCls)}
                                                    haveExpandIcon={this.haveExpandIcon.bind(this, allHeadId, true) || undefined}
                                                    rowKey={(record) => record.key}
                                                    scroll={{ x: true, y: selectedNoTotalTableScrollYHeight }}
                                                    // lazyload={false}
                                                    isDrag={isDrag}
                                                    bodyStyle={{ height: selectedNoTotalTableScrollYHeight }}
                                                    updateColumnHandle={updateColumnAfterSetting.bind(this)}
                                                    tableType='transfer'
                                                    //折行回调
                                                    foldingRowChange={isEllipsis => {
                                                        _this.state.transferTable['allSelected']['isEllipsis'] = isEllipsis
                                                        _this.setState({ transferTable: _this.state.transferTable })

                                                    }}
                                                    bodyDisplayInRow={_this.state.transferTable['allSelected']['isEllipsis']}
                                                    bodyExpandAllInRow={_this.state.transferTable['allSelected']['bodyExpandAllInRow']}
                                                    colsSettingParam={getcolsSettingParam.call(_this, meta, allHeadId)}
                                                    cancelCustomRightMenu={cancelCustomRightMenu}
                                                />
                                            )
                                        ) : //拉平
                                        (isFullTotal && selectedFullColumn ? (
                                            <ComplexTable
                                                className="insertTable total-table-wrapper multi-transfer-selected-wrapper"
                                                lazyload={true}
                                                isDrag={isDrag}
                                                columns={setSelectedColumns(selectedFullColumn, this.state.json)}
                                                sort={sortType}
                                                data={getSelectedFullData(transferTable.allSelected.selectedData, params)}
                                                scroll={{ x: true, y: selectedTotalTableScrollYHeight }}
                                                isTotal={true}
                                                totalData={this.processingTotalData(
                                                    transferTable.allSelected.selectedData,
                                                    'full',
                                                    setSelectedColumns(selectedFullColumn, this.state.json),
                                                    true
                                                )}
                                                totalColumns={selectFullTotalColumns}
                                                bodyStyle={{ height: selectedTotalTableScrollYHeight }}
                                                updateColumnHandle={updateColumnAfterSetting.bind(this)}
                                                tableType='transfer'
                                                //折行回调
                                                foldingRowChange={isEllipsis => {
                                                    _this.state.transferTable['allSelected']['isEllipsis'] = isEllipsis
                                                    _this.setState({ transferTable: _this.state.transferTable })
                                                }}
                                                bodyDisplayInRow={_this.state.transferTable['allSelected']['isEllipsis']}
                                                bodyExpandAllInRow={_this.state.transferTable['allSelected']['bodyExpandAllInRow']}
                                                colsSettingParam={getcolsSettingParam.call(_this, meta, allFullTableId)}
                                                cancelCustomRightMenu={cancelCustomRightMenu}
                                            />
                                        )
                                            : (
                                                <ComplexTable
                                                    className="insertTable multi-transfer-selected-wrapper"
                                                    lazyload={true}
                                                    isDrag={isDrag}
                                                    columns={setSelectedColumns(createNewCol(fullColumns, this.state.json, 'full'), this.state.json)}
                                                    sort={sortType}
                                                    data={getSelectedFullData(transferTable.allSelected.selectedData, params)}
                                                    scroll={{ x: true, y: selectedNoTotalTableScrollYHeight }}
                                                    bodyStyle={{ height: selectedNoTotalTableScrollYHeight }}
                                                    updateColumnHandle={updateColumnAfterSetting.bind(this)}
                                                    tableType='transfer'
                                                    //折行回调
                                                    foldingRowChange={isEllipsis => {
                                                        _this.state.transferTable['allSelected']['isEllipsis'] = isEllipsis
                                                        _this.setState({ transferTable: _this.state.transferTable })
                                                    }}
                                                    bodyDisplayInRow={_this.state.transferTable['allSelected']['isEllipsis']}
                                                    bodyExpandAllInRow={_this.state.transferTable['allSelected']['bodyExpandAllInRow']}
                                                    colsSettingParam={getcolsSettingParam.call(_this, meta, allFullTableId)}
                                                    cancelCustomRightMenu={cancelCustomRightMenu}
                                                />
                                            ))
                                    }
                                </div>
                            </div>
                        ) : ''}
                    </div>

                </div>

                {// 有全部页签----底部固定灰条
                    showAll ? (
                        <div className="fixed-bottom-outer" style={fixedCss} fieldid={getSysFieldid('bottom-area')}>
                            <div className="fixed-bottom-container bottom-area-container">
                                {showSeletedBtn && (
                                    <div className="area-left">
                                        {_this.state.transferTable.allSelected.selectedShow ? (
                                            <Button className="middle-btn" onClick={this.closeSelectedList} fieldid="alllist">
                                                {this.state.json['page-transferTable-0003'] || '全部列表-2'} {/*国际化处理 全部列表*/}
                                            </Button>
                                        ) : (
                                                <Button
                                                    className="middle-btn"
                                                    fieldid="selected"
                                                    onClick={this.viewSelectedList}
                                                // disabled={selectedAmount.bodyAmount == 0}
                                                >
                                                    {this.state.json['page-transferTable-0004'] || '查看已选-2'} {/*国际化处理 全部列表*/}
                                                </Button>
                                            )}
                                    </div>
                                )}

                                <div className="area-center">
                                    <span>
                                        {/* <span>
                                            {LangData && LangData.get('page-transferTable-0015', { n: selectedAmount.masterAmount }) || `合计: ${selectedAmount.masterAmount}条`}
                                        </span> */}
                                        <span>
                                            {this.state.json['page-transferTable-0010']}
                                            :
                                            <span>{selectedAmount.masterAmount}</span>
                                            {this.state.json['page-transferTable-0017']}
                                        </span>
                                        {isShowBodyAmount && (
                                            <span
                                                style={{ marginLeft: 8 }}
                                            >
                                                {this.state.json['page-transferTable-0018']}
                                                :
                                                <span>{selectedAmount.bodyAmount}</span>
                                                {this.state.json['page-transferTable-0019']}
                                            </span>
                                        )}
                                    </span>
                                </div>
                                {selectArea ? (
                                    <div className="area-custom">
                                        {selectArea && typeof selectArea == 'function' && selectArea()}
                                    </div>
                                ) : (
                                        ''
                                    )}
                                {totalKey.length && totalTitle.length ? (
                                    <span>
                                        {this.customAggregate()}
                                    </span>
                                ) : ''}
                                {cutParams.map((item, index) => {
                                    let hideHtml = {};
                                    if (index != transferTable.allSelected.activeTab) {
                                        hideHtml = { display: 'none' };
                                    }
                                    return (
                                        item.selectArea && (
                                            <div className="area-custom" style={hideHtml}>
                                                {typeof item.selectArea == 'function' && item.selectArea()}
                                            </div>
                                        )
                                    );
                                })}

                                {showGenerateBtn && (
                                    <div className="area-right">
                                        <Button
                                            //fieldid="generate"
                                            fieldid={getCurrentFieldId() || "generate"}
                                            className="main-button large-btn"
                                            onClick={handleGenerateClick.bind(this)}
                                            disabled={isShowBodyAmount ? selectedAmount.bodyAmount === 0 && selectedAmount.masterAmount === 0 : selectedAmount.masterAmount === 0}
                                        >
                                            {transferBtnText}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                            ''
                        )}
            </div>
        );
    }
}
CreateMultiTransferTable.defaultProps = {
    isTotal: true
};

export default CreateMultiTransferTable;
